/*eslint max-lines-per-function: ["error", 165]*/
import React, { ReactNode } from 'react';
import { Alert, Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import {
  CreateUpdatePurchaseInvoiceEstimateItemDto,
  GetEstimateItemDto,
  GetPurchaseInvoiceEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useTranslation } from '../../../common/hooks/useTranslation';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { usePokCore } from '../../../common/hooks/usePokCore';
import momencik from '../../../common/momencik';
import { useNavigation } from '../../../common/navigation';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';
import CurrencyControl from '../../../app/components/CurrencyControl';

import {
  getNotAssignedAmount,
  SumData,
} from './purchaseInvoicePositionsGridUtils';
import { PurchaseInvoiceEstimateItemPositionsGridSum } from './PurchaseInvoiceEstimateItemPositionsGridSum';
import { PurchaseInvoiceEstimateItemPositionsGridRow } from './PurchaseInvoiceEstimateItemPositionsGridRow';
import { PurchaseNetTotalForm } from './PurchaseNetTotalForm';

interface PurchaseInvoiceEstimateItemPositionsGridProps {
  extendedPurchaseInvoice?: GetPurchaseInvoiceExtendedDto;
  purchaseInvoiceEstimateItems: CreateUpdatePurchaseInvoiceEstimateItemDto[];
  availableEstimateItems: GetEstimateItemDto[];
  readOnly: boolean;
  handleRefresh: () => void;
  handleAmountChange: (
    estimateItemId: string,
    item: CreateUpdatePurchaseInvoiceEstimateItemDto | undefined,
    amount: string | null | undefined,
  ) => void;
  warnings: string[];
  sumData: SumData;
  handleFillAllAmounts: () => void;
}

export interface TableColumn {
  header: ReactNode;
  isHidden?: boolean;
  renderCell: (
    estimateItem: GetEstimateItemDto,
    purchaseInvoiceEstimateItem: CreateUpdatePurchaseInvoiceEstimateItemDto,
    savedItem?: GetPurchaseInvoiceEstimateItemDto,
  ) => string | ReactNode | null;
  onlyTV?: boolean;
}

const TableHead = ({ columns }: { columns: TableColumn[] }) => (
  <thead>
    <tr>
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map((col, index) => (
          <th key={`${col.header}-${index}`}>{col.header}</th>
        ))}
    </tr>
  </thead>
);

export const PurchaseInvoiceEstimateItemPositionsGrid: React.FC<
  PurchaseInvoiceEstimateItemPositionsGridProps
> = props => {
  const { t, tk } = useTranslation('menu');
  const pok = usePokCore();
  const nav = useNavigation();

  const columns: TableColumn[] = [
    {
      header: t(tk.menu.estimateItemPosition),
      renderCell: (estimateItem, purchaseInvoiceEstimateItem, savedItem) => (
        <>
          {estimateItem.position.name}
          {savedItem ? (
            <LastEditorInfo
              className="last-editor-inline"
              id={savedItem.id}
              method={pok.purchaseInvoices.getLastEstimateItemEditor}
            />
          ) : null}
        </>
      ),
    },
    {
      header: 'Miesiąc',
      renderCell: estimateItem =>
        momencik(estimateItem.estimateByMonth.date, 'YYYY-MM'),
    },
    {
      header: t(tk.menu.project),
      renderCell: estimateItem => (
        <>
          {estimateItem.estimateByMonth.project.name}
          {' ('}
          <Button
            variant="link"
            className="p-0 m-0"
            onClick={() =>
              nav.projectDetails(
                estimateItem.estimateByMonth.project.id,
                true,
                'estimates',
              )
            }
          >
            {estimateItem.estimateByMonth.project.number}
          </Button>
          {')'}
        </>
      ),
    },
    {
      header: 'Numer zlecenia',
      renderCell: estimateItem => estimateItem.orderNumber,
      isHidden: !props.extendedPurchaseInvoice?.tv,
    },
    {
      header: 'Budżet',
      renderCell: estimateItem =>
        estimateItem.estimateByMonth.project.purchaser?.budget?.name,
    },
    {
      header: 'Netto zakup total',
      renderCell: estimateItem => (
        <PurchaseNetTotalForm
          estimateItem={estimateItem}
          purchaseInvoiceId={props.extendedPurchaseInvoice?.id}
          handleRefresh={props.handleRefresh}
          disabled={props.readOnly}
        />
      ),
    },
    {
      header: 'Nierozliczona kwota',
      renderCell: estimateItem =>
        numberFormatter(getNotAssignedAmount(estimateItem), true),
    },
    {
      header: (
        <OverlayTrigger
          overlay={
            <Tooltip>
              Wypełnij wszystkie widoczne pola kwotą nierozliczoną
            </Tooltip>
          }
        >
          <Button
            onClick={props.handleFillAllAmounts}
            variant="outline-secondary"
            disabled={props.readOnly}
          >
            <Icon.ChevronDoubleRight size={10} />
          </Button>
        </OverlayTrigger>
      ),
      renderCell: (estimateItem, purchaseInvoiceEstimateItem, savedItem) => (
        <OverlayTrigger
          overlay={<Tooltip>Wypełnij kwotą nierozliczoną</Tooltip>}
        >
          <Button
            onClick={() =>
              props.handleAmountChange(
                estimateItem.id,
                purchaseInvoiceEstimateItem,
                mathUtils
                  .round(
                    mathUtils.add(
                      getNotAssignedAmount(estimateItem),
                      savedItem?.amount || '0',
                    ),
                  )
                  .toString(),
              )
            }
            variant="outline-secondary"
            disabled={props.readOnly}
          >
            <Icon.ChevronRight size={10} />
          </Button>
        </OverlayTrigger>
      ),
    },
    {
      header: 'Kwota',
      renderCell: (estimateItem, purchaseInvoiceEstimateItem) => (
        <CurrencyControl
          value={purchaseInvoiceEstimateItem?.amount}
          decimalScale={2}
          suffix=" zł"
          disabled={props.readOnly}
          onChange={value =>
            props.handleAmountChange(
              estimateItem.id,
              purchaseInvoiceEstimateItem,
              value,
            )
          }
          allowNegative
        />
      ),
    },
  ];

  return (
    <>
      <Table striped>
        <TableHead columns={columns} />
        <tbody>
          {props.availableEstimateItems.map(estimateItem => (
            <PurchaseInvoiceEstimateItemPositionsGridRow
              {...props}
              key={estimateItem.id}
              estimateItem={estimateItem}
              columns={columns}
            />
          ))}
          <PurchaseInvoiceEstimateItemPositionsGridSum {...props} />
        </tbody>
      </Table>
      {!!props.warnings.length && (
        <Alert variant="danger">{props.warnings}</Alert>
      )}
    </>
  );
};
