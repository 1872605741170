import React from 'react';

import {
  GridColumn,
  GridRecord,
} from '../../../common/components/Grid/GridDataTypes';
import { Grid } from '../../../common/components/Grid/Grid';

interface PurchaseInvoicesGridProps {
  data: GridRecord[];
}

export const PurchaseInvoicesGrid: React.FC<
  PurchaseInvoicesGridProps
> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'invoice.systemNumber' },
    { header: 'Numer', property: 'invoice.number' },
    {
      header: 'Data wystawienia',
      property: 'invoice.issueDate',
    },
    {
      header: 'Data sprzedaży',
      property: 'invoice.sellDate',
    },
    { header: 'Kwota faktury', property: 'invoice.netAmount' },
    { header: 'Kwota rozliczona', property: 'invoice.amount' },
    { header: 'Kontrahent', property: 'client.name' },
    { header: 'Ostatnia modyfikacja', property: 'employee.name' },
    { header: 'Załącznik', property: 'preview', noSorting: false },
  ];
  return (
    <Grid
      data={props.data}
      columns={columns}
      showFilter={false}
      initialOrderColumn="invoice.systemNumber"
      initialOrderDirection="DESC"
      pageSize={20}
      emptyGridMessage="Brak przypisanych faktur zakupu"
    />
  );
};
