import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { JobTypeEnumText } from '../../../common/pokCore/validation/schemas';
import { GetJobConfigDto } from '../../../common/pokCore/autogenerated/pokApiClient';

interface JobConfigSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const jobConfigForGrid = (jobConfig: GetJobConfigDto) => {
  return {
    key: jobConfig.id,
    values: {
      'jobConfig.systemNumber': jobConfig.systemNumber,
      type_pl: JobTypeEnumText[jobConfig.type],
      'team.name': jobConfig.jobConfigTeams
        ?.map(({ team }) => team?.name)
        .join(', '),
      'company.shortname': jobConfig.company?.shortname,
      'jobConfig.emails': jobConfig.emails.join(', '),
      'jobConfig.crone': jobConfig.crone,
    },
    isInactive: !jobConfig.active,
  };
};

export const JobConfigSearch: React.FC<JobConfigSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'Nr sys.', property: 'jobConfig.systemNumber' },
    { header: 'Typ', property: 'type_pl' },
    { header: 'Spółka', property: 'company.shortname' },
    { header: 'Zespoły', property: 'team.name' },
    { header: 'Adresy e-mail', property: 'jobConfig.emails' },
    { header: 'Częstotliwość', property: 'jobConfig.crone' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="jobConfig.systemNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak konfiguracji maili w systemie."
      onRowClick={props.onRowClick}
      withHistory={true}
      refresh={props.refresh}
      withFilterInColumns={true}
    />
  );
};
