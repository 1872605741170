import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { usePokCore } from '../../../common/hooks/usePokCore';
import { EmployeeSearch } from '../../components/Employees/EmployeeSearch';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { TabsHistory } from '../../../app/components/TabsHistory';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

export const EmployeesView: React.FC = () => {
  const pok = usePokCore();
  const notifications = useNotifications();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(false);

  const getFromOneTool = async () => {
    setWaiting(true);
    pok.employees
      .addToEmployeesFromOneTool()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  const deleteEmployeeRoles = async () => {
    setWaiting(true);
    pok.employeesRoles
      .deleteEmployeeRoles()
      .then(() => {
        setRefresh(!refresh);
        setWaiting(false);
        notifications.saveCompleted();
      })
      .catch(errorResponse => {
        setWaiting(false);
        notifications.caughtError(errorResponse);
      });
  };

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title className="pb-3">Pracownicy</Card.Title>
        <Button variant="outline-primary" onClick={getFromOneTool}>
          Synchronizuj z OneTool
        </Button>
        {waiting && <Waiting />}
        {!waiting && (
          <TabsHistory id="employees" activeKey="all" className="my-3">
            <Tab eventKey="all" title="Wszyscy" className="m-3">
              <EmployeeSearch
                getData={pok.employees.getAllByPortion}
                getCount={pok.employees.getAllCount}
                refresh={refresh}
                withHistory={true}
              />
            </Tab>
            <Tab eventKey="notActive" title="Nieaktywni" className="m-3">
              <EmployeeSearch
                getData={pok.employees.getNotActiveByPortion}
                getCount={pok.employees.getNotActiveCount}
                refresh={refresh}
                withHistory={true}
              />
              <ConfirmationButton
                variant="outline-primary"
                onOK={deleteEmployeeRoles}
                confirmation={`Czy na pewno chcesz usunąć wszystkie uprawnienia wszystkich nieaktywnych użytkowników?`}
              >
                Usuń uprawnienia
              </ConfirmationButton>
            </Tab>
          </TabsHistory>
        )}
      </Card.Body>
    </Card>
  );
};
