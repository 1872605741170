import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { SpecialInvoicingForm } from '../../components/SpecialInvoicing/SpecialInvoicingForm';
import { SpecialInvoicingControls } from '../../components/SpecialInvoicing/SpecialInvoicingControls';
import useSpecialInvoicingData from '../../components/SpecialInvoicing/useSpecialInvoicingData';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import { validate } from '../../../common/pokCore/contexts/SpecialInvoicingContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import { Waiting } from '../../../common/components/Waiting/Waiting';
import { SpecialInvoicingStatus } from '../../../common/pokCore/validation/schemas';
import useIsAuthorized from '../../components/SpecialInvoicing/useIsAuthorized';
import { BadCompany } from '../../../common/components/NotAuthorized/BadCompany';
import { SubjectMemos } from '../../components/Memos/SubjectMemos';

export const SpecialInvoicingEditorView: React.FC = () => {
  const {
    id,
    convertedSpecialInvoicing,
    specialInvoicing,
    projects,
    setProjects,
    propertyChange,
    readOnly,
    isLoading,
    handleRefresh,
  } = useSpecialInvoicingData();
  const isInactive = !specialInvoicing?.active && id !== 'utworz';
  const isAuthorized = useIsAuthorized(specialInvoicing);
  const nav = useNavigation();
  const pok = usePokCore();
  const notifications = useNotifications();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingDeactivate, setIsLoadingDeactivate] = useState(false);

  const handleSave = async () => {
    setSaveErrors([]);
    setShowSaveErrors(false);
    const status = await validate(convertedSpecialInvoicing);
    if (!status.valid) {
      setShowSaveErrors(true);
      setSaveErrors(status.errors);
      return;
    }

    setIsLoadingSave(true);
    try {
      if (id !== 'utworz') {
        await pok.specialInvoicing.update(id, convertedSpecialInvoicing);
        void sendEmail(id, true);
        handleRefresh();
      } else {
        const result = await pok.specialInvoicing.create(
          convertedSpecialInvoicing,
        );
        void sendEmail(result.id);
        nav.specialInvoicingEditor(result.id);
      }
      notifications.saveCompleted();
    } catch (err) {
      setShowSaveErrors(true);
      setSaveErrors(await responseErrors(err as ResponseError));
    } finally {
      setIsLoadingSave(false);
    }
  };

  const sendEmail = async (id: string, correction?: boolean) => {
    pok.emails.sendSpecialInvoiceEmail(id, correction).catch(err => {
      notifications.caughtError(err);
    });
  };

  const handleDeactivate = async () => {
    setShowSaveErrors(false);
    setSaveErrors([]);
    setIsLoadingDeactivate(true);
    pok.specialInvoicing
      .deactivate(id)
      .then(() => {
        handleRefresh();
        notifications.saveCompleted();
      })
      .catch(async err => {
        setShowSaveErrors(true);
        setSaveErrors(await responseErrors(err as ResponseError));
      });
  };

  if (isLoading) {
    return <Waiting />;
  }

  if (!isAuthorized) {
    return <BadCompany companyId={specialInvoicing?.company?.id} />;
  }

  return (
    <Card className="main-card">
      <Card.Body className="m-3">
        <Card.Title>
          {isInactive && <b>ARCHIWUM </b>}
          <span style={isInactive ? { textDecoration: 'line-through' } : {}}>
            {id !== 'utworz'
              ? `${
                  readOnly ? 'Szczegóły' : 'Edycja'
                } wniosku o niestandardowe fakturowanie (nr sys. ${specialInvoicing?.systemNumber}) `
              : 'Utwórz wniosek o niestandardowe fakturowanie '}
          </span>
          {specialInvoicing && (
            <SubjectMemos
              subject={specialInvoicing}
              employeeId={specialInvoicing.creator?.id}
            />
          )}
        </Card.Title>
        <SpecialInvoicingForm
          convertedSpecialInvoicing={convertedSpecialInvoicing}
          specialInvoicing={specialInvoicing}
          projects={projects}
          setProjects={setProjects}
          readOnly={readOnly}
          propertyChange={propertyChange}
        />
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="m-3"
        />
        <SpecialInvoicingControls
          readOnly={readOnly}
          id={id}
          handleSave={handleSave}
          handleDeactivate={
            specialInvoicing?.status === SpecialInvoicingStatus.New
              ? handleDeactivate
              : undefined
          }
          isLoadingSave={isLoadingSave}
          isLoadingDeactivate={isLoadingDeactivate}
        />
      </Card.Body>
    </Card>
  );
};
