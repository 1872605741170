import React, { useEffect, useMemo, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';

import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  GetProjectDto,
  GetPurchaseInvoiceWithAttachmentIdDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { PurchaseInvoicesGrid } from '../Estimates/PurchaseInvoicesGrid';
import { DownloadAttachment } from '../../../common/components/File/DownloadAttachment';
import numberFormatter from '../../../common/numberFormatter';
import momencik from '../../../common/momencik';
import { GridRecord } from '../../../common/components/Grid/GridDataTypes';
import FormSkeleton from '../../../app/components/FormSkeleton';
import FormRow from '../../../app/components/FormRow';
import ProfileSelector from '../Selects/ProfileSelector';
import { Option } from '../Selects/Selector';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

import { downloadZIP } from './downloadZIPPurchaseInvoices';

interface ProjectInvoicesTabProps {
  projectId: string;
  project: GetProjectDto;
  downloadDocuments: boolean;
  projectNumber: string;
  projectName: string;
  companyId: string;
  teamIds: string[];
  budgetId: string;
}

export const ProjectInvoicesTab: React.FC<ProjectInvoicesTabProps> = props => {
  const [purchaseInvoices, setPurchaseInvoices] = useState<
    GetPurchaseInvoiceWithAttachmentIdDto[] | undefined
  >(undefined);

  const pok = usePokCore();
  const notifications = useNotifications();
  const [month, setMonth] = useState<Date | undefined>();
  const [profileId, setProfileId] = useState<string | undefined>();
  const [warnings, setWarnings] = useState<string[]>([]);

  const setDate = (date: Date | undefined) => {
    if (date) {
      //ten kalendarz inaczej ustawia godzinę niż nasz główny kalendarz
      //dlatego trzeba skorygować godzinę
      date.setMinutes(-date.getTimezoneOffset());
      return date;
    }
    return undefined;
  };

  useMemo(() => {
    pok.purchaseInvoices
      .getByProject(props.projectId, month, profileId)
      .then(result => {
        if (result) {
          setPurchaseInvoices(result);
        }
      })
      .catch(error => {
        notifications.caughtError(error);
      });
  }, [notifications, pok.purchaseInvoices, props.projectId, month, profileId]);

  const generateData = () => {
    const data = purchaseInvoices?.map(invoice => {
      return {
        key: invoice.id,
        values: {
          'invoice.number': invoice.number,
          'invoice.issueDate': momencik(invoice.issueDate),
          'invoice.sellDate': momencik(invoice.sellDate),
          'invoice.systemNumber': invoice.systemNumber,
          'invoice.amount': numberFormatter(invoice.amount, true),
          'invoice.netAmount': numberFormatter(invoice.netAmount, true),
          'client.name': invoice.client?.name,
          'employee.name': `${invoice.owner?.name} (${momencik(
            invoice.ts,
            'L HH:mm:ss',
          )})`,
          preview: invoice.attachmentId ? (
            <DownloadAttachment id={invoice.attachmentId} icon={Icon.FilePdf} />
          ) : (
            <></>
          ),
        },
      };
    });
    return data as GridRecord[];
  };

  useEffect(() => {
    if (purchaseInvoices) {
      pok.projects
        .getWarningsWithFVZ(
          props.projectId,
          purchaseInvoices.map(o => o.id),
          month,
          profileId,
        )
        .then(war => {
          setWarnings(war || []);
        })
        .catch(response => notifications.caughtError(response));
    } else {
      setWarnings([]);
    }
  }, [
    notifications,
    pok.projects,
    props.projectId,
    month,
    profileId,
    purchaseInvoices,
  ]);

  const handleClick = async () => {
    await downloadZIP(
      props.projectName,
      month
        ? `${props.projectNumber}_${momencik(month, 'YYYY-DD')}`
        : `${props.projectNumber}`,
      purchaseInvoices?.map(invoice => invoice.id) || [],
      pok,
      notifications,
    );
  };

  return (
    <>
      {purchaseInvoices ? (
        <>
          <Form className="d-grid gap-4 mb-3">
            <FormRow controlId="month" label="Miesiąc mediaplanu">
              <DatePicker
                onChange={value => setMonth(setDate(value as Date | undefined))}
                defaultView="year"
                minDetail="year"
                maxDetail="year"
                format="MM.yyyy"
                value={month}
              />
            </FormRow>
            <FormRow controlId="profile" label="Profil">
              <ProfileSelector
                provider={pok.profiles.getAllByCompanyIdsTeamIdsAuth}
                companyIds={[props.companyId || '']}
                teamIds={props.teamIds}
                budgetId={props.budgetId}
                onChange={options => {
                  setProfileId(options ? (options as Option).value : '');
                }}
                clearable={true}
              />
            </FormRow>
          </Form>
          <PurchaseInvoicesGrid data={generateData()} />
          {props.downloadDocuments &&
            purchaseInvoices &&
            purchaseInvoices.length > 0 && (
              <ConfirmationButton
                variant="outline-primary"
                onOK={handleClick}
                confirmation={`Czy pobrać faktury FVZ dołączone do kampanii${warnings.length > 0 ? ' pomimo następujących ostrzeżeń' : ''}?`}
                warnings={warnings}
                modalSize={warnings?.length > 0 ? 'xl' : 'lg'}
              >
                Pobierz dokumenty
              </ConfirmationButton>
            )}
        </>
      ) : (
        <FormSkeleton />
      )}
    </>
  );
};
