/* tslint:disable */
/* eslint-disable */
/**
 * POK
 * Opis api POK
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AttachmentFileContent,
  CloneProjectDto,
  CreateCorrectionSalesInvoiceDto,
  CreateExportToSymphonyDataDto,
  CreatePaeImportDto,
  CreatePrivateMemoDto,
  CreatePublicMemoDto,
  CreateUpdateApplicationDto,
  CreateUpdateApplicationUnblockDto,
  CreateUpdateAttachmentDto,
  CreateUpdateBankAccountDto,
  CreateUpdateBlockadeMonthDto,
  CreateUpdateBrandDto,
  CreateUpdateBudgetDto,
  CreateUpdateClientDto,
  CreateUpdateCompanyDto,
  CreateUpdateDictionaryDto,
  CreateUpdateEmailConfigDto,
  CreateUpdateEmployeeRoleDto,
  CreateUpdateEmployeeRolesDto,
  CreateUpdateEstimateByMonthDto,
  CreateUpdateEstimateItemDto,
  CreateUpdateExcelConfigDto,
  CreateUpdateExcelConfigHeaderDto,
  CreateUpdateExcelConfigPositionDto,
  CreateUpdateExcelDto,
  CreateUpdateInvoiceEstimateItemsDto,
  CreateUpdateJobConfigDto,
  CreateUpdateOrderDto,
  CreateUpdateOrderExecutionDto,
  CreateUpdateOrderExecutionPositionDto,
  CreateUpdatePackageInvoicesXMLDto,
  CreateUpdatePositionDto,
  CreateUpdateProfileCategoryDto,
  CreateUpdateProfileDto,
  CreateUpdateProjectApplicationDto,
  CreateUpdateProjectWithTeamDto,
  CreateUpdatePurchaseInvoiceDto,
  CreateUpdatePurchaseInvoiceEstimateItemsDto,
  CreateUpdatePurchaseInvoiceTeamsAndProjectsDto,
  CreateUpdatePurchaserDto,
  CreateUpdateRoleDto,
  CreateUpdateSalesInvoiceDto,
  CreateUpdateSalesInvoicePositionsDto,
  CreateUpdateSpecialInvoicingDto,
  CreateUpdateSymphonyClientDto,
  CreateUpdateTeamDto,
  DecisionApplicationUnblockDto,
  DecisionSpecialInvoicingDto,
  ExchangeDto,
  FilterYearData,
  GetApplicationDto,
  GetApplicationUnblockDto,
  GetAttachmentDto,
  GetBankAccountDto,
  GetBlockadeMonthDto,
  GetBrandDto,
  GetBudgetDto,
  GetCalendarDto,
  GetClientDto,
  GetClientExtendedDto,
  GetClientExtendedWithCompanyIdDto,
  GetClientWithInvoiceNumbers,
  GetClientWithSymphonyCodeDto,
  GetCompanyDto,
  GetCompanyWithoutLogoDto,
  GetDelayedProjectDto,
  GetDictionaryDto,
  GetDictionaryTypeDto,
  GetDocumentationDto,
  GetEmailConfigDto,
  GetEmailDto,
  GetEmployeeDto,
  GetEmployeeRoleDto,
  GetEmployeeWithRolesDto,
  GetEstimateByMonthDto,
  GetEstimateByMonthWithItemsDto,
  GetEstimateItemDocumentControlDto,
  GetEstimateItemDto,
  GetEstimateItemLockDto,
  GetEstimateItemPurchaseDeficienciesDto,
  GetExcelAndPokDataDto,
  GetExcelConfigDto,
  GetExcelConfigHeaderDto,
  GetExcelConfigPositionDto,
  GetExcelDataDto,
  GetExcelRowDto,
  GetExtendedProfileDto,
  GetExtendedSalesInvoiceDto,
  GetInvoiceEstimateItemDto,
  GetJobConfigDto,
  GetLastEditorDto,
  GetMemoDto,
  GetNumberingDto,
  GetOrderDto,
  GetOrderExecutionDto,
  GetOrderExecutionPositionDto,
  GetPackageInvoicesXMLDto,
  GetPaeImportDto,
  GetPositionDto,
  GetPositionExtendedDto,
  GetProfileCategoryDto,
  GetProfileDto,
  GetProjectDto,
  GetProjectExtendedDto,
  GetProjectForFinanceDto,
  GetProjectWithPurchaseInvoicesDto,
  GetPurchaseInvoiceDto,
  GetPurchaseInvoiceEstimateItemDto,
  GetPurchaseInvoiceExtendedDto,
  GetPurchaseInvoiceForItem,
  GetPurchaseInvoiceWithAttachmentIdDto,
  GetPurchaserDto,
  GetResultsDto,
  GetRoleDto,
  GetSalesInvoiceDto,
  GetSalesInvoiceForItem,
  GetSalesInvoicePositionDto,
  GetSalesInvoiceWithAmountDto,
  GetSpecialInvoicingDto,
  GetSubjectThreadStatsDto,
  GetSymphonyClientDto,
  GetTeamDto,
  GetThreadDto,
  GetThreadsStatsDto,
  GetVersionInfoDto,
  GusClientDto,
  LoginDto,
  LoginWithGoogleDto,
  ProjectApplicationApproveDto,
  ProjectApplicationRejectDto,
  RejectOrderDto,
  ReportType,
  TeamInCompanyInTokenDto,
  TokenDto,
  UpdateAdminApplicationDto,
  UpdatePurchaseNetTotalDto,
} from '../models';
import {
    AttachmentFileContentFromJSON,
    AttachmentFileContentToJSON,
    CloneProjectDtoFromJSON,
    CloneProjectDtoToJSON,
    CreateCorrectionSalesInvoiceDtoFromJSON,
    CreateCorrectionSalesInvoiceDtoToJSON,
    CreateExportToSymphonyDataDtoFromJSON,
    CreateExportToSymphonyDataDtoToJSON,
    CreatePaeImportDtoFromJSON,
    CreatePaeImportDtoToJSON,
    CreatePrivateMemoDtoFromJSON,
    CreatePrivateMemoDtoToJSON,
    CreatePublicMemoDtoFromJSON,
    CreatePublicMemoDtoToJSON,
    CreateUpdateApplicationDtoFromJSON,
    CreateUpdateApplicationDtoToJSON,
    CreateUpdateApplicationUnblockDtoFromJSON,
    CreateUpdateApplicationUnblockDtoToJSON,
    CreateUpdateAttachmentDtoFromJSON,
    CreateUpdateAttachmentDtoToJSON,
    CreateUpdateBankAccountDtoFromJSON,
    CreateUpdateBankAccountDtoToJSON,
    CreateUpdateBlockadeMonthDtoFromJSON,
    CreateUpdateBlockadeMonthDtoToJSON,
    CreateUpdateBrandDtoFromJSON,
    CreateUpdateBrandDtoToJSON,
    CreateUpdateBudgetDtoFromJSON,
    CreateUpdateBudgetDtoToJSON,
    CreateUpdateClientDtoFromJSON,
    CreateUpdateClientDtoToJSON,
    CreateUpdateCompanyDtoFromJSON,
    CreateUpdateCompanyDtoToJSON,
    CreateUpdateDictionaryDtoFromJSON,
    CreateUpdateDictionaryDtoToJSON,
    CreateUpdateEmailConfigDtoFromJSON,
    CreateUpdateEmailConfigDtoToJSON,
    CreateUpdateEmployeeRoleDtoFromJSON,
    CreateUpdateEmployeeRoleDtoToJSON,
    CreateUpdateEmployeeRolesDtoFromJSON,
    CreateUpdateEmployeeRolesDtoToJSON,
    CreateUpdateEstimateByMonthDtoFromJSON,
    CreateUpdateEstimateByMonthDtoToJSON,
    CreateUpdateEstimateItemDtoFromJSON,
    CreateUpdateEstimateItemDtoToJSON,
    CreateUpdateExcelConfigDtoFromJSON,
    CreateUpdateExcelConfigDtoToJSON,
    CreateUpdateExcelConfigHeaderDtoFromJSON,
    CreateUpdateExcelConfigHeaderDtoToJSON,
    CreateUpdateExcelConfigPositionDtoFromJSON,
    CreateUpdateExcelConfigPositionDtoToJSON,
    CreateUpdateExcelDtoFromJSON,
    CreateUpdateExcelDtoToJSON,
    CreateUpdateInvoiceEstimateItemsDtoFromJSON,
    CreateUpdateInvoiceEstimateItemsDtoToJSON,
    CreateUpdateJobConfigDtoFromJSON,
    CreateUpdateJobConfigDtoToJSON,
    CreateUpdateOrderDtoFromJSON,
    CreateUpdateOrderDtoToJSON,
    CreateUpdateOrderExecutionDtoFromJSON,
    CreateUpdateOrderExecutionDtoToJSON,
    CreateUpdateOrderExecutionPositionDtoFromJSON,
    CreateUpdateOrderExecutionPositionDtoToJSON,
    CreateUpdatePackageInvoicesXMLDtoFromJSON,
    CreateUpdatePackageInvoicesXMLDtoToJSON,
    CreateUpdatePositionDtoFromJSON,
    CreateUpdatePositionDtoToJSON,
    CreateUpdateProfileCategoryDtoFromJSON,
    CreateUpdateProfileCategoryDtoToJSON,
    CreateUpdateProfileDtoFromJSON,
    CreateUpdateProfileDtoToJSON,
    CreateUpdateProjectApplicationDtoFromJSON,
    CreateUpdateProjectApplicationDtoToJSON,
    CreateUpdateProjectWithTeamDtoFromJSON,
    CreateUpdateProjectWithTeamDtoToJSON,
    CreateUpdatePurchaseInvoiceDtoFromJSON,
    CreateUpdatePurchaseInvoiceDtoToJSON,
    CreateUpdatePurchaseInvoiceEstimateItemsDtoFromJSON,
    CreateUpdatePurchaseInvoiceEstimateItemsDtoToJSON,
    CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoFromJSON,
    CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoToJSON,
    CreateUpdatePurchaserDtoFromJSON,
    CreateUpdatePurchaserDtoToJSON,
    CreateUpdateRoleDtoFromJSON,
    CreateUpdateRoleDtoToJSON,
    CreateUpdateSalesInvoiceDtoFromJSON,
    CreateUpdateSalesInvoiceDtoToJSON,
    CreateUpdateSalesInvoicePositionsDtoFromJSON,
    CreateUpdateSalesInvoicePositionsDtoToJSON,
    CreateUpdateSpecialInvoicingDtoFromJSON,
    CreateUpdateSpecialInvoicingDtoToJSON,
    CreateUpdateSymphonyClientDtoFromJSON,
    CreateUpdateSymphonyClientDtoToJSON,
    CreateUpdateTeamDtoFromJSON,
    CreateUpdateTeamDtoToJSON,
    DecisionApplicationUnblockDtoFromJSON,
    DecisionApplicationUnblockDtoToJSON,
    DecisionSpecialInvoicingDtoFromJSON,
    DecisionSpecialInvoicingDtoToJSON,
    ExchangeDtoFromJSON,
    ExchangeDtoToJSON,
    FilterYearDataFromJSON,
    FilterYearDataToJSON,
    GetApplicationDtoFromJSON,
    GetApplicationDtoToJSON,
    GetApplicationUnblockDtoFromJSON,
    GetApplicationUnblockDtoToJSON,
    GetAttachmentDtoFromJSON,
    GetAttachmentDtoToJSON,
    GetBankAccountDtoFromJSON,
    GetBankAccountDtoToJSON,
    GetBlockadeMonthDtoFromJSON,
    GetBlockadeMonthDtoToJSON,
    GetBrandDtoFromJSON,
    GetBrandDtoToJSON,
    GetBudgetDtoFromJSON,
    GetBudgetDtoToJSON,
    GetCalendarDtoFromJSON,
    GetCalendarDtoToJSON,
    GetClientDtoFromJSON,
    GetClientDtoToJSON,
    GetClientExtendedDtoFromJSON,
    GetClientExtendedDtoToJSON,
    GetClientExtendedWithCompanyIdDtoFromJSON,
    GetClientExtendedWithCompanyIdDtoToJSON,
    GetClientWithInvoiceNumbersFromJSON,
    GetClientWithInvoiceNumbersToJSON,
    GetClientWithSymphonyCodeDtoFromJSON,
    GetClientWithSymphonyCodeDtoToJSON,
    GetCompanyDtoFromJSON,
    GetCompanyDtoToJSON,
    GetCompanyWithoutLogoDtoFromJSON,
    GetCompanyWithoutLogoDtoToJSON,
    GetDelayedProjectDtoFromJSON,
    GetDelayedProjectDtoToJSON,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoToJSON,
    GetDictionaryTypeDtoFromJSON,
    GetDictionaryTypeDtoToJSON,
    GetDocumentationDtoFromJSON,
    GetDocumentationDtoToJSON,
    GetEmailConfigDtoFromJSON,
    GetEmailConfigDtoToJSON,
    GetEmailDtoFromJSON,
    GetEmailDtoToJSON,
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoToJSON,
    GetEmployeeRoleDtoFromJSON,
    GetEmployeeRoleDtoToJSON,
    GetEmployeeWithRolesDtoFromJSON,
    GetEmployeeWithRolesDtoToJSON,
    GetEstimateByMonthDtoFromJSON,
    GetEstimateByMonthDtoToJSON,
    GetEstimateByMonthWithItemsDtoFromJSON,
    GetEstimateByMonthWithItemsDtoToJSON,
    GetEstimateItemDocumentControlDtoFromJSON,
    GetEstimateItemDocumentControlDtoToJSON,
    GetEstimateItemDtoFromJSON,
    GetEstimateItemDtoToJSON,
    GetEstimateItemLockDtoFromJSON,
    GetEstimateItemLockDtoToJSON,
    GetEstimateItemPurchaseDeficienciesDtoFromJSON,
    GetEstimateItemPurchaseDeficienciesDtoToJSON,
    GetExcelAndPokDataDtoFromJSON,
    GetExcelAndPokDataDtoToJSON,
    GetExcelConfigDtoFromJSON,
    GetExcelConfigDtoToJSON,
    GetExcelConfigHeaderDtoFromJSON,
    GetExcelConfigHeaderDtoToJSON,
    GetExcelConfigPositionDtoFromJSON,
    GetExcelConfigPositionDtoToJSON,
    GetExcelDataDtoFromJSON,
    GetExcelDataDtoToJSON,
    GetExcelRowDtoFromJSON,
    GetExcelRowDtoToJSON,
    GetExtendedProfileDtoFromJSON,
    GetExtendedProfileDtoToJSON,
    GetExtendedSalesInvoiceDtoFromJSON,
    GetExtendedSalesInvoiceDtoToJSON,
    GetInvoiceEstimateItemDtoFromJSON,
    GetInvoiceEstimateItemDtoToJSON,
    GetJobConfigDtoFromJSON,
    GetJobConfigDtoToJSON,
    GetLastEditorDtoFromJSON,
    GetLastEditorDtoToJSON,
    GetMemoDtoFromJSON,
    GetMemoDtoToJSON,
    GetNumberingDtoFromJSON,
    GetNumberingDtoToJSON,
    GetOrderDtoFromJSON,
    GetOrderDtoToJSON,
    GetOrderExecutionDtoFromJSON,
    GetOrderExecutionDtoToJSON,
    GetOrderExecutionPositionDtoFromJSON,
    GetOrderExecutionPositionDtoToJSON,
    GetPackageInvoicesXMLDtoFromJSON,
    GetPackageInvoicesXMLDtoToJSON,
    GetPaeImportDtoFromJSON,
    GetPaeImportDtoToJSON,
    GetPositionDtoFromJSON,
    GetPositionDtoToJSON,
    GetPositionExtendedDtoFromJSON,
    GetPositionExtendedDtoToJSON,
    GetProfileCategoryDtoFromJSON,
    GetProfileCategoryDtoToJSON,
    GetProfileDtoFromJSON,
    GetProfileDtoToJSON,
    GetProjectDtoFromJSON,
    GetProjectDtoToJSON,
    GetProjectExtendedDtoFromJSON,
    GetProjectExtendedDtoToJSON,
    GetProjectForFinanceDtoFromJSON,
    GetProjectForFinanceDtoToJSON,
    GetProjectWithPurchaseInvoicesDtoFromJSON,
    GetProjectWithPurchaseInvoicesDtoToJSON,
    GetPurchaseInvoiceDtoFromJSON,
    GetPurchaseInvoiceDtoToJSON,
    GetPurchaseInvoiceEstimateItemDtoFromJSON,
    GetPurchaseInvoiceEstimateItemDtoToJSON,
    GetPurchaseInvoiceExtendedDtoFromJSON,
    GetPurchaseInvoiceExtendedDtoToJSON,
    GetPurchaseInvoiceForItemFromJSON,
    GetPurchaseInvoiceForItemToJSON,
    GetPurchaseInvoiceWithAttachmentIdDtoFromJSON,
    GetPurchaseInvoiceWithAttachmentIdDtoToJSON,
    GetPurchaserDtoFromJSON,
    GetPurchaserDtoToJSON,
    GetResultsDtoFromJSON,
    GetResultsDtoToJSON,
    GetRoleDtoFromJSON,
    GetRoleDtoToJSON,
    GetSalesInvoiceDtoFromJSON,
    GetSalesInvoiceDtoToJSON,
    GetSalesInvoiceForItemFromJSON,
    GetSalesInvoiceForItemToJSON,
    GetSalesInvoicePositionDtoFromJSON,
    GetSalesInvoicePositionDtoToJSON,
    GetSalesInvoiceWithAmountDtoFromJSON,
    GetSalesInvoiceWithAmountDtoToJSON,
    GetSpecialInvoicingDtoFromJSON,
    GetSpecialInvoicingDtoToJSON,
    GetSubjectThreadStatsDtoFromJSON,
    GetSubjectThreadStatsDtoToJSON,
    GetSymphonyClientDtoFromJSON,
    GetSymphonyClientDtoToJSON,
    GetTeamDtoFromJSON,
    GetTeamDtoToJSON,
    GetThreadDtoFromJSON,
    GetThreadDtoToJSON,
    GetThreadsStatsDtoFromJSON,
    GetThreadsStatsDtoToJSON,
    GetVersionInfoDtoFromJSON,
    GetVersionInfoDtoToJSON,
    GusClientDtoFromJSON,
    GusClientDtoToJSON,
    LoginDtoFromJSON,
    LoginDtoToJSON,
    LoginWithGoogleDtoFromJSON,
    LoginWithGoogleDtoToJSON,
    ProjectApplicationApproveDtoFromJSON,
    ProjectApplicationApproveDtoToJSON,
    ProjectApplicationRejectDtoFromJSON,
    ProjectApplicationRejectDtoToJSON,
    RejectOrderDtoFromJSON,
    RejectOrderDtoToJSON,
    ReportTypeFromJSON,
    ReportTypeToJSON,
    TeamInCompanyInTokenDtoFromJSON,
    TeamInCompanyInTokenDtoToJSON,
    TokenDtoFromJSON,
    TokenDtoToJSON,
    UpdateAdminApplicationDtoFromJSON,
    UpdateAdminApplicationDtoToJSON,
    UpdatePurchaseNetTotalDtoFromJSON,
    UpdatePurchaseNetTotalDtoToJSON,
} from '../models';

export interface ApplicationControllerCreateRequest {
    createUpdateApplicationDto: CreateUpdateApplicationDto;
}

export interface ApplicationControllerGetRequest {
    id: string;
}

export interface ApplicationControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    status: string;
    onlyActive: boolean;
    withContextCompany?: boolean;
}

export interface ApplicationControllerGetAllByPortionByCurrentUserRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ApplicationControllerGetAllCountRequest {
    filterText: string;
    status: string;
    onlyActive: boolean;
    withContextCompany?: boolean;
}

export interface ApplicationControllerGetCountByCurrentUserRequest {
    filterText: string;
}

export interface ApplicationControllerGetLastEditorRequest {
    id: string;
}

export interface ApplicationControllerUpdateRequest {
    id: string;
    createUpdateApplicationDto: CreateUpdateApplicationDto;
}

export interface ApplicationControllerUpdateByAdminRequest {
    id: string;
    updateAdminApplicationDto: UpdateAdminApplicationDto;
}

export interface ApplicationUnblockControllerCreateRequest {
    createUpdateApplicationUnblockDto: CreateUpdateApplicationUnblockDto;
}

export interface ApplicationUnblockControllerDeactivateRequest {
    id: string;
}

export interface ApplicationUnblockControllerGetRequest {
    id: string;
}

export interface ApplicationUnblockControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    estimateByMonthId?: string;
    status?: string;
}

export interface ApplicationUnblockControllerGetAllCountRequest {
    filterText: string;
    estimateByMonthId?: string;
    status?: string;
}

export interface ApplicationUnblockControllerGetAllPendingByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ApplicationUnblockControllerGetAllPendingCountRequest {
    filterText: string;
}

export interface ApplicationUnblockControllerGetLastEditorRequest {
    id: string;
}

export interface ApplicationUnblockControllerUpdateRequest {
    id: string;
    createUpdateApplicationUnblockDto: CreateUpdateApplicationUnblockDto;
}

export interface ApplicationUnblockControllerUpdateDecisionRequest {
    id: string;
    decisionApplicationUnblockDto: DecisionApplicationUnblockDto;
}

export interface AttachmentControllerCreateRequest {
    createUpdateAttachmentDto: CreateUpdateAttachmentDto;
    onlyOneByObjectType?: boolean;
}

export interface AttachmentControllerDeleteRequest {
    id: string;
}

export interface AttachmentControllerGetAllForObjectRequest {
    objectType: string;
    objectId: string;
}

export interface AttachmentControllerGetAllForObjectAndShortnameRequest {
    objectType: string;
    objectId: string;
    shortname: string;
}

export interface AttachmentControllerGetAllForObjectInCompanyRequest {
    objectType: string;
    objectId: string;
    companyId: string;
}

export interface AttachmentControllerGetFileDownloadRequest {
    id: string;
}

export interface AttachmentControllerGetFileDownloadByObjectIdAndObjectTypeRequest {
    id: string;
    objectType: string;
}

export interface AttachmentControllerGetLastEditorRequest {
    id: string;
}

export interface AttachmentControllerGetLastForProjectByShortnameRequest {
    shortname: string;
    projectId: string;
}

export interface AttachmentControllerGetZipByAttachmentIdsRequest {
    projectNumber: string;
    attachmentsIds: Array<string>;
}

export interface AttachmentControllerUpdateRequest {
    id: string;
    createUpdateAttachmentDto: CreateUpdateAttachmentDto;
}

export interface BankAccountControllerCreateRequest {
    createUpdateBankAccountDto: CreateUpdateBankAccountDto;
}

export interface BankAccountControllerGetRequest {
    id: string;
}

export interface BankAccountControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface BankAccountControllerGetAllCountRequest {
    filterText?: string;
    filterColumns?: object;
}

export interface BankAccountControllerGetLastEditorRequest {
    id: string;
}

export interface BankAccountControllerUpdateRequest {
    id: string;
    createUpdateBankAccountDto: CreateUpdateBankAccountDto;
}

export interface BlockadeMonthControllerCheckBlockedMonthRequest {
    date: Date;
    companyId: string;
    teamIds: Array<string>;
    isSalesBlocked: boolean;
    isPurchaseBlocked: boolean;
}

export interface BlockadeMonthControllerCreateRequest {
    createUpdateBlockadeMonthDto: CreateUpdateBlockadeMonthDto;
}

export interface BlockadeMonthControllerDeactivateRequest {
    id: string;
}

export interface BlockadeMonthControllerFindByCompanyRequest {
    companyId: string;
}

export interface BlockadeMonthControllerGetRequest {
    id: string;
}

export interface BlockadeMonthControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface BlockadeMonthControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface BlockadeMonthControllerGetLastEditorRequest {
    id: string;
}

export interface BlockadeMonthControllerUpdateRequest {
    id: string;
    createUpdateBlockadeMonthDto: CreateUpdateBlockadeMonthDto;
}

export interface BrandControllerCreateRequest {
    createUpdateBrandDto: CreateUpdateBrandDto;
}

export interface BrandControllerGetRequest {
    id: string;
}

export interface BrandControllerGetAllByBudgetRequest {
    budgetId: string;
}

export interface BrandControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface BrandControllerGetAllByPurchaserIdRequest {
    purchaserId: string;
}

export interface BrandControllerGetAllCountRequest {
    filterText: string;
}

export interface BrandControllerGetLastEditorRequest {
    id: string;
}

export interface BrandControllerUpdateRequest {
    id: string;
    createUpdateBrandDto: CreateUpdateBrandDto;
}

export interface BudgetControllerCreateRequest {
    createUpdateBudgetDto: CreateUpdateBudgetDto;
}

export interface BudgetControllerGetRequest {
    id: string;
}

export interface BudgetControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface BudgetControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface BudgetControllerGetByPatternRequest {
    pattern: string;
    limit: number;
}

export interface BudgetControllerGetForDocumentControlByPatternRequest {
    pattern: string;
    limit: number;
}

export interface BudgetControllerGetLastEditorRequest {
    id: string;
}

export interface BudgetControllerUpdateRequest {
    id: string;
    createUpdateBudgetDto: CreateUpdateBudgetDto;
}

export interface CalendarControllerAddDayRequest {
    date: string;
}

export interface CalendarControllerCreateYearRequest {
    date: string;
}

export interface CalendarControllerGetAllForYearRequest {
    year: string;
}

export interface CalendarControllerRemoveDayRequest {
    date: string;
}

export interface ClientControllerCreateRequest {
    createUpdateClientDto: CreateUpdateClientDto;
}

export interface ClientControllerFormattedAddressRequest {
    id: string;
}

export interface ClientControllerGetRequest {
    id: string;
}

export interface ClientControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface ClientControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface ClientControllerGetAllWithSymphonyByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface ClientControllerGetAllWithSymphonyCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface ClientControllerGetAllWithoutSymphonyByPortionRequest {
    invoiceType: number;
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface ClientControllerGetAllWithoutSymphonyCountRequest {
    invoiceType: number;
    filterText: string;
    filterColumns?: object;
}

export interface ClientControllerGetByNipRequest {
    pattern: string;
    limit: number;
}

export interface ClientControllerGetByNipOrNameRequest {
    pattern: string;
    limit: number;
}

export interface ClientControllerGetDistinctCountriesRequest {
    pattern: string;
    limit: number;
}

export interface ClientControllerGetLastEditorRequest {
    id: string;
}

export interface ClientControllerUpdateRequest {
    id: string;
    createUpdateClientDto: CreateUpdateClientDto;
}

export interface CompanyControllerCreateRequest {
    createUpdateCompanyDto: CreateUpdateCompanyDto;
}

export interface CompanyControllerGetRequest {
    id: string;
}

export interface CompanyControllerGetAllNotAssignedAndCurrentRequest {
    currentCompanyId?: string;
}

export interface CompanyControllerGetCompanyIdByClientIdRequest {
    clientId?: string;
}

export interface CompanyControllerGetLastEditorRequest {
    id: string;
}

export interface CompanyControllerUpdateRequest {
    id: string;
    createUpdateCompanyDto: CreateUpdateCompanyDto;
}

export interface DictionaryControllerChangeToInactiveRequest {
    id: string;
}

export interface DictionaryControllerCreateRequest {
    createUpdateDictionaryDto: CreateUpdateDictionaryDto;
}

export interface DictionaryControllerGetRequest {
    id: string;
}

export interface DictionaryControllerGetByTypeRequest {
    type: string;
}

export interface DictionaryControllerGetByTypeAndShortnameRequest {
    type: string;
    symbol: string;
}

export interface DictionaryControllerGetLastEditorRequest {
    id: string;
}

export interface DictionaryControllerUpdateRequest {
    id: string;
    createUpdateDictionaryDto: CreateUpdateDictionaryDto;
}

export interface DictionaryTypeControllerGetRequest {
    id: string;
}

export interface DocumentationControllerGetForFilenameRequest {
    filename: string;
}

export interface EmailConfigControllerCreateRequest {
    createUpdateEmailConfigDto: CreateUpdateEmailConfigDto;
}

export interface EmailConfigControllerGetRequest {
    id: string;
}

export interface EmailConfigControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    withContextCompany?: any;
}

export interface EmailConfigControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface EmailConfigControllerGetLastEditorRequest {
    id: string;
}

export interface EmailConfigControllerUpdateRequest {
    id: string;
    createUpdateEmailConfigDto: CreateUpdateEmailConfigDto;
}

export interface EmailControllerSendApplicationEmailRequest {
    application: string;
}

export interface EmailControllerSendApplicationExecutionEmailRequest {
    application: string;
}

export interface EmailControllerSendEmailByTypeRequest {
    objectId: string;
    emailType: string;
}

export interface EmailControllerSendOrderEmailRequest {
    order: string;
}

export interface EmailControllerSendOrderExecutionEmailRequest {
    orderExecution: string;
}

export interface EmailControllerSendProjectEmailRequest {
    project: string;
    applicationType: string;
}

export interface EmailControllerSendProjectResponseEmailRequest {
    project: string;
    applicationType: string;
    accepted: boolean;
}

export interface EmailControllerSendPurchaseDeficienciesEmailRequest {
    tv: boolean;
    email: Array<string>;
    message: string;
    teamId?: string;
}

export interface EmailControllerSendSpecialInvoiceEmailRequest {
    id: string;
    correction?: boolean;
}

export interface EmailControllerSendUnblockEmailRequest {
    application: string;
}

export interface EmployeeControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface EmployeeControllerGetAllCountRequest {
    filterText: string;
}

export interface EmployeeControllerGetByCompanyRequest {
    id?: string;
    withTechnical?: boolean;
}

export interface EmployeeControllerGetNotActiveByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface EmployeeControllerGetNotActiveCountRequest {
    filterText: string;
}

export interface EmployeeRoleControllerCreateRequest {
    createUpdateEmployeeRoleDto: CreateUpdateEmployeeRoleDto;
}

export interface EmployeeRoleControllerCreateEmployeeWithRolesRequest {
    createUpdateEmployeeRolesDto: CreateUpdateEmployeeRolesDto;
}

export interface EmployeeRoleControllerDeleteRequest {
    id: string;
}

export interface EmployeeRoleControllerFindTeamsWithCompanyByUserRequest {
    id: string;
}

export interface EmployeeRoleControllerGetRequest {
    id: string;
}

export interface EmployeeRoleControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface EmployeeRoleControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface EmployeeRoleControllerGetLastEditorRequest {
    id: string;
}

export interface EmployeeRoleControllerGetWithTheSameRoleByRoleIdRequest {
    id: string;
}

export interface EmployeeRoleControllerGetWithTheSameUserByUserIdRequest {
    id: string;
}

export interface EmployeeRoleControllerUpdateRequest {
    id: string;
    createUpdateEmployeeRoleDto: CreateUpdateEmployeeRoleDto;
}

export interface EstimateByMonthControllerCloneRequest {
    id: string;
    date: Date;
    withAmounts: boolean;
}

export interface EstimateByMonthControllerCreateRequest {
    createUpdateEstimateByMonthDto: CreateUpdateEstimateByMonthDto;
}

export interface EstimateByMonthControllerDeactivateRequest {
    id: string;
}

export interface EstimateByMonthControllerFindByProjectRequest {
    projectId: string;
}

export interface EstimateByMonthControllerFindEstimateItemsByProjectRequest {
    projectId: string;
}

export interface EstimateByMonthControllerGetRequest {
    id: string;
}

export interface EstimateByMonthControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface EstimateByMonthControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface EstimateByMonthControllerGetLastEditorRequest {
    id: string;
}

export interface EstimateByMonthControllerToggleBlockedRequest {
    type: string;
    value: boolean;
    id: string;
}

export interface EstimateByMonthControllerUpdateStatusRequest {
    status: string;
    id: string;
}

export interface EstimateItemControllerCheckItemLockRequest {
    id: string;
}

export interface EstimateItemControllerCloneRequest {
    itemsIds: Array<string>;
    date: Date;
    withAmounts: boolean;
    projectId: string;
}

export interface EstimateItemControllerCreateRequest {
    createUpdateEstimateItemDto: CreateUpdateEstimateItemDto;
}

export interface EstimateItemControllerCreateEstimateItemFromPaeRequest {
    createPaeImportDto: CreatePaeImportDto;
}

export interface EstimateItemControllerDeactivateRequest {
    id: string;
}

export interface EstimateItemControllerFindAllByProjectAndTeamRequest {
    projectId: string;
    teamId: string;
}

export interface EstimateItemControllerFindByEstimateMonthRequest {
    estimateMonthId: string;
}

export interface EstimateItemControllerFindByProjectRequest {
    projectId: string;
}

export interface EstimateItemControllerFindByProjectAndClientRequest {
    projectId: string;
    clientId: string;
}

export interface EstimateItemControllerFindByProjectAndMonthRequest {
    projectId: string;
    date: Date;
}

export interface EstimateItemControllerFindByProjectProfileMonthRequest {
    projectId: string;
    profileId?: string;
    date?: Date;
}

export interface EstimateItemControllerFindForDocumentControlByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    budgetId?: string;
    projectId?: string;
}

export interface EstimateItemControllerFindForDocumentControlCountRequest {
    filterText: string;
    filterColumns?: object;
    budgetId?: string;
    projectId?: string;
}

export interface EstimateItemControllerFindForOrderExecutionSumRequest {
    projectId: string;
    teamIds: Array<string>;
    clientId: string;
    date: Date;
}

export interface EstimateItemControllerFindForPurchaseInvoiceByClientAndProjectsRequest {
    purchaseInvoiceId: string;
    clientId: string;
    projectIds: Array<string>;
}

export interface EstimateItemControllerFindPurchaseDeficienciesCountRequest {
    filterText: string;
    filterColumns?: object;
    tv?: boolean;
}

export interface EstimateItemControllerFindReadyToInvoiceByProjectsRequest {
    projectIds: Array<string>;
    salesInvoiceId: string;
}

export interface EstimateItemControllerGetRequest {
    id: string;
}

export interface EstimateItemControllerGetActionGroupsByCompanyAndBudgetRequest {
    pattern: string;
    companyId: string;
    budgetId: string;
    limit: number;
}

export interface EstimateItemControllerGetEstimateItemsFromPaeRequest {
    projectNumber: string;
    year: number;
    month: number;
}

export interface EstimateItemControllerGetLastEditorRequest {
    id: string;
}

export interface EstimateItemControllerGetPurchaseDeficienciesRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    tv?: boolean;
}

export interface EstimateItemControllerUpdateRequest {
    id: string;
    createUpdateEstimateItemDto: CreateUpdateEstimateItemDto;
}

export interface EstimateItemControllerUpdatePurchaseNetTotalRequest {
    id: string;
    updatePurchaseNetTotalDto: UpdatePurchaseNetTotalDto;
}

export interface ExcelConfigControllerCreateRequest {
    createUpdateExcelConfigDto: CreateUpdateExcelConfigDto;
}

export interface ExcelConfigControllerGetRequest {
    id: string;
}

export interface ExcelConfigControllerGetAllByPortionRequest {
    companyId: string;
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ExcelConfigControllerGetAllCountRequest {
    companyId: string;
    filterText: string;
}

export interface ExcelConfigControllerGetLastEditorRequest {
    id: string;
}

export interface ExcelConfigControllerSearchRequest {
    limit: number;
    filterText?: string;
}

export interface ExcelConfigControllerUpdateRequest {
    id: string;
    createUpdateExcelConfigDto: CreateUpdateExcelConfigDto;
}

export interface ExcelConfigHeaderControllerCreateRequest {
    createUpdateExcelConfigHeaderDto: CreateUpdateExcelConfigHeaderDto;
}

export interface ExcelConfigHeaderControllerGetRequest {
    id: string;
}

export interface ExcelConfigHeaderControllerGetAllByConfigRequest {
    excelConfigId: string;
}

export interface ExcelConfigHeaderControllerGetLastEditorRequest {
    id: string;
}

export interface ExcelConfigHeaderControllerUpdateRequest {
    id: string;
    createUpdateExcelConfigHeaderDto: CreateUpdateExcelConfigHeaderDto;
}

export interface ExcelConfigPositionControllerCreateRequest {
    createUpdateExcelConfigPositionDto: CreateUpdateExcelConfigPositionDto;
}

export interface ExcelConfigPositionControllerGetRequest {
    id: string;
}

export interface ExcelConfigPositionControllerGetAllByConfigRequest {
    excelConfigId: string;
}

export interface ExcelConfigPositionControllerGetLastEditorRequest {
    id: string;
}

export interface ExcelConfigPositionControllerGetSimilarPositionWithParamsRequest {
    positionId: string;
    configId: string;
}

export interface ExcelConfigPositionControllerUpdateRequest {
    id: string;
    createUpdateExcelConfigPositionDto: CreateUpdateExcelConfigPositionDto;
}

export interface ExcelControllerGetAggregatedDataRequest {
    getExcelDataDto: GetExcelDataDto;
}

export interface ExcelControllerGetDataFromExcelRequest {
    createUpdateExcelDto: CreateUpdateExcelDto;
}

export interface ExcelControllerGetExcelAndPokDataRequest {
    getExcelDataDto: GetExcelDataDto;
}

export interface ExcelControllerImportExcelDataRequest {
    getExcelAndPokDataDto: GetExcelAndPokDataDto;
}

export interface ExcelControllerRecheckDataRequest {
    aggregate: boolean;
    getExcelDataDto: GetExcelDataDto;
}

export interface ExchangeApiControllerGetExchangeRateRequest {
    isoCode: string;
    date: Date;
}

export interface GusApiControllerGetByNipRequest {
    nip: string;
}

export interface InvoiceEstimateItemControllerCreateManyRequest {
    createUpdateInvoiceEstimateItemsDto: CreateUpdateInvoiceEstimateItemsDto;
}

export interface JobConfigControllerCreateRequest {
    createUpdateJobConfigDto: CreateUpdateJobConfigDto;
}

export interface JobConfigControllerGetRequest {
    id: string;
}

export interface JobConfigControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface JobConfigControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface JobConfigControllerGetLastEditorRequest {
    id: string;
}

export interface JobConfigControllerUpdateRequest {
    id: string;
    createUpdateJobConfigDto: CreateUpdateJobConfigDto;
}

export interface LoginControllerLoginRequest {
    loginDto: LoginDto;
}

export interface LoginControllerLoginWithGoogleRequest {
    loginWithGoogleDto: LoginWithGoogleDto;
}

export interface LoginControllerRefreshTokenRequest {
    refreshToken: string;
}

export interface MemoControllerCreatePrivateMemoRequest {
    createPrivateMemoDto: CreatePrivateMemoDto;
}

export interface MemoControllerCreatePublicMemoRequest {
    createPublicMemoDto: CreatePublicMemoDto;
    employeeId?: string;
}

export interface MemoControllerGetSubjectThreadStatsRequest {
    subjectType: MemoControllerGetSubjectThreadStatsSubjectTypeEnum;
    id: string;
}

export interface MemoControllerGetThreadMemosRequest {
    id: string;
    count: number;
}

export interface MemoControllerGetThreadsForUserRequest {
    threadsCount: number;
    filter: number;
}

export interface OrderControllerAcceptRequest {
    id: string;
}

export interface OrderControllerCreateRequest {
    createUpdateOrderDto: CreateUpdateOrderDto;
}

export interface OrderControllerDeactivateRequest {
    id: string;
}

export interface OrderControllerGetRequest {
    id: string;
}

export interface OrderControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface OrderControllerGetAllByPortionCompletedRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface OrderControllerGetAllByPortionForProjectRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    projectId?: string;
    resultProjectId?: string;
}

export interface OrderControllerGetAllByPortionWaitingRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface OrderControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface OrderControllerGetAllCountCompletedRequest {
    filterText: string;
    filterColumns?: object;
}

export interface OrderControllerGetAllCountForProjectRequest {
    filterText: string;
    filterColumns?: object;
    projectId?: string;
    resultProjectId?: string;
}

export interface OrderControllerGetAllCountWaitingRequest {
    filterText: string;
    filterColumns?: object;
}

export interface OrderControllerGetLastEditorRequest {
    id: string;
}

export interface OrderControllerRejectRequest {
    id: string;
    rejectOrderDto: RejectOrderDto;
}

export interface OrderControllerUpdateRequest {
    id: string;
    createUpdateOrderDto: CreateUpdateOrderDto;
}

export interface OrderExecutionControllerAcceptRequest {
    id: string;
}

export interface OrderExecutionControllerCreateRequest {
    createUpdateOrderExecutionDto: CreateUpdateOrderExecutionDto;
}

export interface OrderExecutionControllerDeactivateRequest {
    id: string;
}

export interface OrderExecutionControllerGetRequest {
    id: string;
}

export interface OrderExecutionControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface OrderExecutionControllerGetAllByPortionCompletedRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface OrderExecutionControllerGetAllByPortionForProjectRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    projectId?: string;
    resultProjectId?: string;
}

export interface OrderExecutionControllerGetAllByPortionWaitingRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface OrderExecutionControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface OrderExecutionControllerGetAllCountCompletedRequest {
    filterText: string;
    filterColumns?: object;
}

export interface OrderExecutionControllerGetAllCountForProjectRequest {
    filterText: string;
    filterColumns?: object;
    projectId?: string;
    resultProjectId?: string;
    status?: string;
}

export interface OrderExecutionControllerGetAllCountWaitingRequest {
    filterText: string;
    filterColumns?: object;
}

export interface OrderExecutionControllerGetLastEditorRequest {
    id: string;
}

export interface OrderExecutionControllerRejectRequest {
    id: string;
    rejectOrderDto: RejectOrderDto;
}

export interface OrderExecutionControllerUpdateRequest {
    id: string;
    createUpdateOrderExecutionDto: CreateUpdateOrderExecutionDto;
}

export interface OrderExecutionPositionControllerCreateRequest {
    createUpdateOrderExecutionPositionDto: CreateUpdateOrderExecutionPositionDto;
}

export interface PackageInvoicesXMLControllerCreateRequest {
    createUpdatePackageInvoicesXMLDto: CreateUpdatePackageInvoicesXMLDto;
}

export interface PackageInvoicesXMLControllerGetRequest {
    id: string;
}

export interface PackageInvoicesXMLControllerGetAllByTypeByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    packageType: string;
}

export interface PackageInvoicesXMLControllerGetAllByTypeCountRequest {
    filterText: string;
    packageType: string;
}

export interface PackageInvoicesXMLControllerGetXMLFromPackageXMLRequest {
    packageXML: string;
}

export interface PackageInvoicesXMLControllerGetZipBySalesInvoicesIdsRequest {
    projectNumber: string;
    salesInvoicesIds: Array<string>;
}

export interface PackageInvoicesXMLControllerGetZipFromPackageXMLRequest {
    packageXML: string;
    copy: boolean;
}

export interface PdfPrinterControllerGetZipOrderExecutionsByProjectRequest {
    projectId: string;
}

export interface PdfPrinterControllerInvoiceDataPrintoutRequest {
    id: string;
}

export interface PdfPrinterControllerPrintInvoiceRequest {
    id: string;
    duplicate: boolean;
    copy: boolean;
}

export interface PdfPrinterControllerPrintOrderExecutionRequest {
    id: string;
}

export interface PositionControllerCreateRequest {
    createUpdatePositionDto: CreateUpdatePositionDto;
}

export interface PositionControllerGetRequest {
    id: string;
}

export interface PositionControllerGetAllByPortionForClientRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    clientId?: string;
}

export interface PositionControllerGetAllByProfileIdTeamIdAndNameRequest {
    profileId: string;
    teamId: string;
    pattern: string;
    limit: number;
}

export interface PositionControllerGetAllByTeamIdsCommissionAndNameRequest {
    teamIds: Array<string>;
    profileId: string;
    commission: boolean;
    budgetId: string;
    pattern: string;
    limit: number;
}

export interface PositionControllerGetAllClientsByProjectIdRequest {
    projectId: string;
    pattern: string;
    limit: number;
}

export interface PositionControllerGetAllCountForClientRequest {
    filterText: string;
    filterColumns?: object;
    clientId?: string;
}

export interface PositionControllerGetDistinctGroupNamesRequest {
    pattern: string;
    limit: number;
}

export interface PositionControllerGetLastEditorRequest {
    id: string;
}

export interface PositionControllerUpdateRequest {
    id: string;
    createUpdatePositionDto: CreateUpdatePositionDto;
}

export interface ProfileCategoryControllerCreateRequest {
    createUpdateProfileCategoryDto: CreateUpdateProfileCategoryDto;
}

export interface ProfileCategoryControllerGetRequest {
    id: string;
}

export interface ProfileCategoryControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ProfileCategoryControllerGetAllByProfileRequest {
    profile: string;
}

export interface ProfileCategoryControllerGetAllCountRequest {
    filterText?: string;
}

export interface ProfileCategoryControllerGetLastEditorRequest {
    id: string;
}

export interface ProfileCategoryControllerUpdateRequest {
    id: string;
    createUpdateProfileCategoryDto: CreateUpdateProfileCategoryDto;
}

export interface ProfileControllerCreateRequest {
    createUpdateProfileDto: CreateUpdateProfileDto;
}

export interface ProfileControllerGetRequest {
    id: string;
}

export interface ProfileControllerGetAllByCompanyIdsTeamIdsRequest {
    companyIds: Array<string>;
    teamIds?: Array<string>;
}

export interface ProfileControllerGetAllByCompanyIdsTeamIdsAuthRequest {
    companyIds: Array<string>;
    teamIds?: Array<string>;
    budgetId?: string;
}

export interface ProfileControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ProfileControllerGetAllCountRequest {
    filterText?: string;
}

export interface ProfileControllerGetLastEditorRequest {
    id: string;
}

export interface ProfileControllerUpdateRequest {
    id: string;
    createUpdateProfileDto: CreateUpdateProfileDto;
}

export interface ProjectControllerApplicationRequest {
    id: string;
    createUpdateProjectApplicationDto: CreateUpdateProjectApplicationDto;
}

export interface ProjectControllerApplicationAcceptRequest {
    id: string;
    projectApplicationApproveDto: ProjectApplicationApproveDto;
}

export interface ProjectControllerApplicationRejectRequest {
    id: string;
    projectApplicationRejectDto: ProjectApplicationRejectDto;
}

export interface ProjectControllerCloneRequest {
    cloneProjectDto: CloneProjectDto;
}

export interface ProjectControllerCreateRequest {
    createUpdateProjectWithTeamDto: CreateUpdateProjectWithTeamDto;
}

export interface ProjectControllerGetRequest {
    id: string;
}

export interface ProjectControllerGetAllApplicationByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    applicationType: string;
}

export interface ProjectControllerGetAllApplicationCountRequest {
    filterText: string;
    applicationType: string;
}

export interface ProjectControllerGetAllApplicationWaitingByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    applicationType: string;
}

export interface ProjectControllerGetAllApplicationWaitingCountRequest {
    filterText: string;
    applicationType: string;
}

export interface ProjectControllerGetAllByCompanyRequest {
    limit: number;
    filterText?: string;
}

export interface ProjectControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface ProjectControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface ProjectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    clientId: string;
    idsToExclude?: Array<string>;
}

export interface ProjectControllerGetAllForPurchaseInvoiceByClientAndCompanyCountRequest {
    filterText: string;
    clientId: string;
    idsToExclude?: Array<string>;
}

export interface ProjectControllerGetAllForPurchaseInvoiceByPurchaseInvoiceRequest {
    purchaseInvoiceId: string;
}

export interface ProjectControllerGetAllReadyToCorrectionByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ProjectControllerGetAllReadyToCorrectionCountRequest {
    filterText: string;
}

export interface ProjectControllerGetAllReadyToInvoiceByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    clientId?: string;
    budgetId?: string;
    idsToExclude?: Array<string>;
}

export interface ProjectControllerGetAllReadyToInvoiceCountRequest {
    filterText: string;
    clientId?: string;
    budgetId?: string;
    idsToExclude?: Array<string>;
}

export interface ProjectControllerGetExtendedRequest {
    id: string;
}

export interface ProjectControllerGetForFinanceByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface ProjectControllerGetForFinanceCountRequest {
    filterText: string;
    filterColumns?: object;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface ProjectControllerGetLastEditorRequest {
    id: string;
}

export interface ProjectControllerGetSumForFinanceRequest {
    filterText: string;
    filterColumns?: object;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface ProjectControllerGetWarningsWithFVZRequest {
    projectId: string;
    purchaseInvoicesIds: Array<string>;
    month?: Date;
    profileId?: string;
}

export interface ProjectControllerGetWithParentRequest {
    id: string;
}

export interface ProjectControllerInternalProjectsByPurchaserForCompanyAndTeamsRequest {
    purchaserId: string;
    teamIds: Array<string>;
}

export interface ProjectControllerIsDelayedRequest {
    date: Date;
}

export interface ProjectControllerUniqueServicesNamesRequest {
    id: string;
}

export interface ProjectControllerUpdateRequest {
    id: string;
    createUpdateProjectWithTeamDto: CreateUpdateProjectWithTeamDto;
}

export interface PurchaseInvoiceControllerApproveRequest {
    id: string;
}

export interface PurchaseInvoiceControllerCreateRequest {
    createUpdatePurchaseInvoiceDto: CreateUpdatePurchaseInvoiceDto;
}

export interface PurchaseInvoiceControllerDeactivateRequest {
    id: string;
}

export interface PurchaseInvoiceControllerFindByEstimateItemRequest {
    itemId: string;
}

export interface PurchaseInvoiceControllerFindByProjectRequest {
    projectId: string;
    month?: Date;
    profileId?: string;
}

export interface PurchaseInvoiceControllerGetRequest {
    id: string;
}

export interface PurchaseInvoiceControllerGetAllByPackageFVZByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    packageFVZId: string;
}

export interface PurchaseInvoiceControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllForMediaplanerByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllForMediaplanerCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllGeneratedByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllGeneratedSettledByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllReadyToSentByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    isRepeat?: boolean;
    type?: string;
}

export interface PurchaseInvoiceControllerGetAllTvForMediaplanerByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllTvForMediaplanerCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllUnsettledTvByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllUnsettledTvCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllUnsettledWithProjectsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllUnsettledWithProjectsCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllWithTeamsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllWithTeamsCountRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetCountByPackageFVZRequest {
    filterText: string;
    packageFVZId: string;
}

export interface PurchaseInvoiceControllerGetCountGeneratedRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetCountGeneratedSettledRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetCountReadyToSentRequest {
    filterText: string;
    filterColumns?: object;
    isRepeat?: boolean;
    type?: string;
}

export interface PurchaseInvoiceControllerGetCountWithoutTeamsAndProjectsRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetEstimateItemsRequest {
    id: string;
}

export interface PurchaseInvoiceControllerGetLastEditorRequest {
    id: string;
}

export interface PurchaseInvoiceControllerGetLastEstimateItemEditorRequest {
    id: string;
}

export interface PurchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoicesRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoicesRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoicesRequest {
    filterText: string;
    filterColumns?: object;
}

export interface PurchaseInvoiceControllerGetZipByPurchaseIdsRequest {
    projectNumber: string;
    purchaseInvoiceIds: Array<string>;
}

export interface PurchaseInvoiceControllerUnApproveRequest {
    id: string;
}

export interface PurchaseInvoiceControllerUpdateRequest {
    id: string;
    createUpdatePurchaseInvoiceDto: CreateUpdatePurchaseInvoiceDto;
}

export interface PurchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItemsRequest {
    id: string;
    createUpdatePurchaseInvoiceEstimateItemsDto: CreateUpdatePurchaseInvoiceEstimateItemsDto;
}

export interface PurchaseInvoiceControllerUpdateTeamsAndProjectsRequest {
    id: string;
    createUpdatePurchaseInvoiceTeamsAndProjectsDto: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto;
}

export interface PurchaserControllerCreateRequest {
    createUpdatePurchaserDto: CreateUpdatePurchaserDto;
}

export interface PurchaserControllerGetRequest {
    id: string;
}

export interface PurchaserControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    clientId?: string;
}

export interface PurchaserControllerGetAllByTeamIdsRequest {
    teamIds: Array<string>;
    pattern: string;
    limit: number;
    parentProjectId?: string;
}

export interface PurchaserControllerGetAllByTeamIdsAndBudgetRequest {
    teamIds: Array<string>;
    budgetId: string;
    pattern: string;
    limit: number;
    parentProjectId?: string;
}

export interface PurchaserControllerGetAllByTeamIdsForRoleWithoutTeamsRequest {
    teamIds: Array<string>;
    pattern: string;
    limit: number;
}

export interface PurchaserControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
    clientId?: string;
}

export interface PurchaserControllerGetLastEditorRequest {
    id: string;
}

export interface PurchaserControllerUpdateRequest {
    id: string;
    createUpdatePurchaserDto: CreateUpdatePurchaserDto;
}

export interface ReportControllerReportEstimateItemsRawDataRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportFVSByFilterRawDataRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportFVSControllingByFilterRawDataRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportFVZByFilterRawDataRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportFVZControllingByFilterRawDataRequest {
    filterYearData: FilterYearData;
}

export interface ReportControllerReportForDocumentControlRequest {
    budgetId?: string;
    projectId?: string;
}

export interface ReportControllerReportProjectByMonthRawDataRequest {
    filterYearData: FilterYearData;
}

export interface RoleControllerCreateRequest {
    createUpdateRoleDto: CreateUpdateRoleDto;
}

export interface RoleControllerDeleteRequest {
    id: string;
}

export interface RoleControllerGetRequest {
    id: string;
}

export interface RoleControllerGetLastEditorRequest {
    id: string;
}

export interface RoleControllerUpdateRequest {
    id: string;
    createUpdateRoleDto: CreateUpdateRoleDto;
}

export interface SalesInvoiceControllerCreateRequest {
    createUpdateSalesInvoiceDto: CreateUpdateSalesInvoiceDto;
}

export interface SalesInvoiceControllerCreateCorrectionRequest {
    createCorrectionSalesInvoiceDto: CreateCorrectionSalesInvoiceDto;
}

export interface SalesInvoiceControllerDeactivateRequest {
    id: string;
}

export interface SalesInvoiceControllerFindAvailableInvoiceNumbersRequest {
    companyId: string;
    isCorrection: boolean;
    type: string;
    invoiceDate: Date;
}

export interface SalesInvoiceControllerFindByEstimateItemRequest {
    itemId: string;
}

export interface SalesInvoiceControllerFindByProjectRequest {
    projectId: string;
}

export interface SalesInvoiceControllerGetRequest {
    id: string;
}

export interface SalesInvoiceControllerGetAllByPackageXMLByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    packageXMLId: string;
}

export interface SalesInvoiceControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    status?: string;
}

export interface SalesInvoiceControllerGetAllByPortionByNegativePositionsDiffRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SalesInvoiceControllerGetAllCountRequest {
    filterText: string;
    filterColumns?: object;
    status?: string;
}

export interface SalesInvoiceControllerGetAllCountByNegativePositionsDiffRequest {
    filterText: string;
}

export interface SalesInvoiceControllerGetAllForCompanyYearByPortionRequest {
    companyId: string;
    status: Array<string>;
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    year?: number;
}

export interface SalesInvoiceControllerGetAllForCompanyYearCountRequest {
    companyId: string;
    status: Array<string>;
    filterText: string;
    year?: number;
}

export interface SalesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
    type?: string;
}

export interface SalesInvoiceControllerGetAllReadyToSentWithoutCorrectCountRequest {
    filterText: string;
    filterColumns?: object;
    type?: string;
}

export interface SalesInvoiceControllerGetAllSentToSymphonyByPortionRequest {
    sentToSymphonyHandel: boolean;
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    filterColumns?: object;
}

export interface SalesInvoiceControllerGetAllSentToSymphonyCountRequest {
    sentToSymphonyHandel: boolean;
    filterText: string;
    filterColumns?: object;
}

export interface SalesInvoiceControllerGetCountByPackageXMLRequest {
    filterText: string;
    packageXMLId: string;
}

export interface SalesInvoiceControllerGetLastEditorRequest {
    id: string;
}

export interface SalesInvoiceControllerUpdateRequest {
    id: string;
    createUpdateSalesInvoiceDto: CreateUpdateSalesInvoiceDto;
}

export interface SalesInvoiceControllerUpdateSummaryRequest {
    id: string;
    createUpdateSalesInvoiceDto: CreateUpdateSalesInvoiceDto;
}

export interface SalesInvoicePositionControllerCreateManyRequest {
    createUpdateSalesInvoicePositionsDto: CreateUpdateSalesInvoicePositionsDto;
}

export interface SavedPdfControllerHasInvoiceSavedPdfRequest {
    invoiceId: string;
}

export interface SpecialInvoicingControllerCreateRequest {
    createUpdateSpecialInvoicingDto: CreateUpdateSpecialInvoicingDto;
}

export interface SpecialInvoicingControllerDeactivateRequest {
    id: string;
}

export interface SpecialInvoicingControllerGetRequest {
    id: string;
}

export interface SpecialInvoicingControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    statuses?: Array<string>;
}

export interface SpecialInvoicingControllerGetAllCountRequest {
    filterText: string;
    statuses?: Array<string>;
}

export interface SpecialInvoicingControllerGetLastEditorRequest {
    id: string;
}

export interface SpecialInvoicingControllerUpdateRequest {
    id: string;
    createUpdateSpecialInvoicingDto: CreateUpdateSpecialInvoicingDto;
}

export interface SpecialInvoicingControllerUpdateDecisionRequest {
    id: string;
    decisionSpecialInvoicingDto: DecisionSpecialInvoicingDto;
}

export interface SymphonyClientControllerCreateRequest {
    createUpdateSymphonyClientDto: CreateUpdateSymphonyClientDto;
}

export interface SymphonyClientControllerGetRequest {
    id: string;
}

export interface SymphonyClientControllerGetAllByPortionForClientRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    clientId?: string;
    filterToCurrentCompany?: boolean;
}

export interface SymphonyClientControllerGetAllCountForClientRequest {
    filterText: string;
    clientId?: string;
    filterToCurrentCompany?: boolean;
}

export interface SymphonyClientControllerGetByClientCompanyRequest {
    clientId: string;
    companyId: string;
}

export interface SymphonyClientControllerGetLastEditorRequest {
    id: string;
}

export interface SymphonyClientControllerUpdateRequest {
    id: string;
    createUpdateSymphonyClientDto: CreateUpdateSymphonyClientDto;
}

export interface TbInvoiceControllerExportFSVRequest {
    createExportToSymphonyDataDto: CreateExportToSymphonyDataDto;
}

export interface TeamControllerCreateRequest {
    createUpdateTeamDto: CreateUpdateTeamDto;
}

export interface TeamControllerGetRequest {
    id: string;
}

export interface TeamControllerGetAllActiveRequest {
    filterText: string;
}

export interface TeamControllerGetAllActiveByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface TeamControllerGetAllActiveCountRequest {
    filterText: string;
}

export interface TeamControllerGetAllByCompanyIdsRequest {
    companyIds: Array<string>;
}

export interface TeamControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface TeamControllerGetAllByProjectIdRequest {
    projectId: string;
}

export interface TeamControllerGetAllCountRequest {
    filterText: string;
}

export interface TeamControllerGetLastEditorRequest {
    id: string;
}

export interface TeamControllerGetMyForPermissionByCompanyIdsRequest {
    companyIds: Array<string>;
    permission: string;
}

export interface TeamControllerUpdateRequest {
    id: string;
    createUpdateTeamDto: CreateUpdateTeamDto;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async applicationControllerCreateRaw(requestParameters: ApplicationControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApplicationDto>> {
        if (requestParameters.createUpdateApplicationDto === null || requestParameters.createUpdateApplicationDto === undefined) {
            throw new runtime.RequiredError('createUpdateApplicationDto','Required parameter requestParameters.createUpdateApplicationDto was null or undefined when calling applicationControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateApplicationDtoToJSON(requestParameters.createUpdateApplicationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplicationDtoFromJSON(jsonValue));
    }

    /**
     */
    async applicationControllerCreate(createUpdateApplicationDto: CreateUpdateApplicationDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApplicationDto> {
        const response = await this.applicationControllerCreateRaw({ createUpdateApplicationDto: createUpdateApplicationDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerGetRaw(requestParameters: ApplicationControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApplicationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplicationDtoFromJSON(jsonValue));
    }

    /**
     */
    async applicationControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApplicationDto> {
        const response = await this.applicationControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerGetAllByPortionRaw(requestParameters: ApplicationControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetApplicationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        if (requestParameters.onlyActive === null || requestParameters.onlyActive === undefined) {
            throw new runtime.RequiredError('onlyActive','Required parameter requestParameters.onlyActive was null or undefined when calling applicationControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        if (requestParameters.withContextCompany !== undefined) {
            queryParameters['withContextCompany'] = requestParameters.withContextCompany;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetApplicationDtoFromJSON));
    }

    /**
     */
    async applicationControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, status: string, onlyActive: boolean, withContextCompany?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetApplicationDto>> {
        const response = await this.applicationControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, status: status, onlyActive: onlyActive, withContextCompany: withContextCompany }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerGetAllByPortionByCurrentUserRaw(requestParameters: ApplicationControllerGetAllByPortionByCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetApplicationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling applicationControllerGetAllByPortionByCurrentUser.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling applicationControllerGetAllByPortionByCurrentUser.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling applicationControllerGetAllByPortionByCurrentUser.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling applicationControllerGetAllByPortionByCurrentUser.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationControllerGetAllByPortionByCurrentUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/search/byCurrentUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetApplicationDtoFromJSON));
    }

    /**
     */
    async applicationControllerGetAllByPortionByCurrentUser(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetApplicationDto>> {
        const response = await this.applicationControllerGetAllByPortionByCurrentUserRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerGetAllCountRaw(requestParameters: ApplicationControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationControllerGetAllCount.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling applicationControllerGetAllCount.');
        }

        if (requestParameters.onlyActive === null || requestParameters.onlyActive === undefined) {
            throw new runtime.RequiredError('onlyActive','Required parameter requestParameters.onlyActive was null or undefined when calling applicationControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        if (requestParameters.withContextCompany !== undefined) {
            queryParameters['withContextCompany'] = requestParameters.withContextCompany;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async applicationControllerGetAllCount(filterText: string, status: string, onlyActive: boolean, withContextCompany?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.applicationControllerGetAllCountRaw({ filterText: filterText, status: status, onlyActive: onlyActive, withContextCompany: withContextCompany }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerGetCountByCurrentUserRaw(requestParameters: ApplicationControllerGetCountByCurrentUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationControllerGetCountByCurrentUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/search/countByCurrentUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async applicationControllerGetCountByCurrentUser(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.applicationControllerGetCountByCurrentUserRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerGetLastEditorRaw(requestParameters: ApplicationControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async applicationControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.applicationControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationControllerUpdateRaw(requestParameters: ApplicationControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationControllerUpdate.');
        }

        if (requestParameters.createUpdateApplicationDto === null || requestParameters.createUpdateApplicationDto === undefined) {
            throw new runtime.RequiredError('createUpdateApplicationDto','Required parameter requestParameters.createUpdateApplicationDto was null or undefined when calling applicationControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateApplicationDtoToJSON(requestParameters.createUpdateApplicationDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async applicationControllerUpdate(id: string, createUpdateApplicationDto: CreateUpdateApplicationDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applicationControllerUpdateRaw({ id: id, createUpdateApplicationDto: createUpdateApplicationDto }, initOverrides);
    }

    /**
     */
    async applicationControllerUpdateByAdminRaw(requestParameters: ApplicationControllerUpdateByAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationControllerUpdateByAdmin.');
        }

        if (requestParameters.updateAdminApplicationDto === null || requestParameters.updateAdminApplicationDto === undefined) {
            throw new runtime.RequiredError('updateAdminApplicationDto','Required parameter requestParameters.updateAdminApplicationDto was null or undefined when calling applicationControllerUpdateByAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application/byAdmin/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAdminApplicationDtoToJSON(requestParameters.updateAdminApplicationDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async applicationControllerUpdateByAdmin(id: string, updateAdminApplicationDto: UpdateAdminApplicationDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applicationControllerUpdateByAdminRaw({ id: id, updateAdminApplicationDto: updateAdminApplicationDto }, initOverrides);
    }

    /**
     */
    async applicationUnblockControllerCreateRaw(requestParameters: ApplicationUnblockControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApplicationUnblockDto>> {
        if (requestParameters.createUpdateApplicationUnblockDto === null || requestParameters.createUpdateApplicationUnblockDto === undefined) {
            throw new runtime.RequiredError('createUpdateApplicationUnblockDto','Required parameter requestParameters.createUpdateApplicationUnblockDto was null or undefined when calling applicationUnblockControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateApplicationUnblockDtoToJSON(requestParameters.createUpdateApplicationUnblockDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplicationUnblockDtoFromJSON(jsonValue));
    }

    /**
     */
    async applicationUnblockControllerCreate(createUpdateApplicationUnblockDto: CreateUpdateApplicationUnblockDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApplicationUnblockDto> {
        const response = await this.applicationUnblockControllerCreateRaw({ createUpdateApplicationUnblockDto: createUpdateApplicationUnblockDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerDeactivateRaw(requestParameters: ApplicationUnblockControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationUnblockControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async applicationUnblockControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applicationUnblockControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async applicationUnblockControllerGetRaw(requestParameters: ApplicationUnblockControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApplicationUnblockDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationUnblockControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplicationUnblockDtoFromJSON(jsonValue));
    }

    /**
     */
    async applicationUnblockControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApplicationUnblockDto> {
        const response = await this.applicationUnblockControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerGetAllByPortionRaw(requestParameters: ApplicationUnblockControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetApplicationUnblockDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling applicationUnblockControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling applicationUnblockControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling applicationUnblockControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling applicationUnblockControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationUnblockControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.estimateByMonthId !== undefined) {
            queryParameters['estimateByMonthId'] = requestParameters.estimateByMonthId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetApplicationUnblockDtoFromJSON));
    }

    /**
     */
    async applicationUnblockControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, estimateByMonthId?: string, status?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetApplicationUnblockDto>> {
        const response = await this.applicationUnblockControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, estimateByMonthId: estimateByMonthId, status: status }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerGetAllCountRaw(requestParameters: ApplicationUnblockControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationUnblockControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.estimateByMonthId !== undefined) {
            queryParameters['estimateByMonthId'] = requestParameters.estimateByMonthId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async applicationUnblockControllerGetAllCount(filterText: string, estimateByMonthId?: string, status?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.applicationUnblockControllerGetAllCountRaw({ filterText: filterText, estimateByMonthId: estimateByMonthId, status: status }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerGetAllPendingByPortionRaw(requestParameters: ApplicationUnblockControllerGetAllPendingByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetApplicationUnblockDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling applicationUnblockControllerGetAllPendingByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling applicationUnblockControllerGetAllPendingByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling applicationUnblockControllerGetAllPendingByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling applicationUnblockControllerGetAllPendingByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationUnblockControllerGetAllPendingByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/searchPending/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetApplicationUnblockDtoFromJSON));
    }

    /**
     */
    async applicationUnblockControllerGetAllPendingByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetApplicationUnblockDto>> {
        const response = await this.applicationUnblockControllerGetAllPendingByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerGetAllPendingCountRaw(requestParameters: ApplicationUnblockControllerGetAllPendingCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling applicationUnblockControllerGetAllPendingCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/searchPending/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async applicationUnblockControllerGetAllPendingCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.applicationUnblockControllerGetAllPendingCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerGetLastEditorRaw(requestParameters: ApplicationUnblockControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationUnblockControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async applicationUnblockControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.applicationUnblockControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationUnblockControllerUpdateRaw(requestParameters: ApplicationUnblockControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationUnblockControllerUpdate.');
        }

        if (requestParameters.createUpdateApplicationUnblockDto === null || requestParameters.createUpdateApplicationUnblockDto === undefined) {
            throw new runtime.RequiredError('createUpdateApplicationUnblockDto','Required parameter requestParameters.createUpdateApplicationUnblockDto was null or undefined when calling applicationUnblockControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateApplicationUnblockDtoToJSON(requestParameters.createUpdateApplicationUnblockDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async applicationUnblockControllerUpdate(id: string, createUpdateApplicationUnblockDto: CreateUpdateApplicationUnblockDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applicationUnblockControllerUpdateRaw({ id: id, createUpdateApplicationUnblockDto: createUpdateApplicationUnblockDto }, initOverrides);
    }

    /**
     */
    async applicationUnblockControllerUpdateDecisionRaw(requestParameters: ApplicationUnblockControllerUpdateDecisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling applicationUnblockControllerUpdateDecision.');
        }

        if (requestParameters.decisionApplicationUnblockDto === null || requestParameters.decisionApplicationUnblockDto === undefined) {
            throw new runtime.RequiredError('decisionApplicationUnblockDto','Required parameter requestParameters.decisionApplicationUnblockDto was null or undefined when calling applicationUnblockControllerUpdateDecision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applicationUnblock/decision/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DecisionApplicationUnblockDtoToJSON(requestParameters.decisionApplicationUnblockDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async applicationUnblockControllerUpdateDecision(id: string, decisionApplicationUnblockDto: DecisionApplicationUnblockDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applicationUnblockControllerUpdateDecisionRaw({ id: id, decisionApplicationUnblockDto: decisionApplicationUnblockDto }, initOverrides);
    }

    /**
     */
    async attachmentControllerCreateRaw(requestParameters: AttachmentControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAttachmentDto>> {
        if (requestParameters.createUpdateAttachmentDto === null || requestParameters.createUpdateAttachmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateAttachmentDto','Required parameter requestParameters.createUpdateAttachmentDto was null or undefined when calling attachmentControllerCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.onlyOneByObjectType !== undefined) {
            queryParameters['onlyOneByObjectType'] = requestParameters.onlyOneByObjectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateAttachmentDtoToJSON(requestParameters.createUpdateAttachmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAttachmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerCreate(createUpdateAttachmentDto: CreateUpdateAttachmentDto, onlyOneByObjectType?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAttachmentDto> {
        const response = await this.attachmentControllerCreateRaw({ createUpdateAttachmentDto: createUpdateAttachmentDto, onlyOneByObjectType: onlyOneByObjectType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerDeleteRaw(requestParameters: AttachmentControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async attachmentControllerDelete(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.attachmentControllerDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async attachmentControllerGetAllForObjectRaw(requestParameters: AttachmentControllerGetAllForObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAttachmentDto>>> {
        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling attachmentControllerGetAllForObject.');
        }

        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling attachmentControllerGetAllForObject.');
        }

        const queryParameters: any = {};

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/forObject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAttachmentDtoFromJSON));
    }

    /**
     */
    async attachmentControllerGetAllForObject(objectType: string, objectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAttachmentDto>> {
        const response = await this.attachmentControllerGetAllForObjectRaw({ objectType: objectType, objectId: objectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetAllForObjectAndShortnameRaw(requestParameters: AttachmentControllerGetAllForObjectAndShortnameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAttachmentDto>>> {
        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling attachmentControllerGetAllForObjectAndShortname.');
        }

        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling attachmentControllerGetAllForObjectAndShortname.');
        }

        if (requestParameters.shortname === null || requestParameters.shortname === undefined) {
            throw new runtime.RequiredError('shortname','Required parameter requestParameters.shortname was null or undefined when calling attachmentControllerGetAllForObjectAndShortname.');
        }

        const queryParameters: any = {};

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        if (requestParameters.shortname !== undefined) {
            queryParameters['shortname'] = requestParameters.shortname;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/forObjectAndShortname`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAttachmentDtoFromJSON));
    }

    /**
     */
    async attachmentControllerGetAllForObjectAndShortname(objectType: string, objectId: string, shortname: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAttachmentDto>> {
        const response = await this.attachmentControllerGetAllForObjectAndShortnameRaw({ objectType: objectType, objectId: objectId, shortname: shortname }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetAllForObjectInCompanyRaw(requestParameters: AttachmentControllerGetAllForObjectInCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAttachmentDto>>> {
        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling attachmentControllerGetAllForObjectInCompany.');
        }

        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling attachmentControllerGetAllForObjectInCompany.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling attachmentControllerGetAllForObjectInCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/forObjectInCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAttachmentDtoFromJSON));
    }

    /**
     */
    async attachmentControllerGetAllForObjectInCompany(objectType: string, objectId: string, companyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAttachmentDto>> {
        const response = await this.attachmentControllerGetAllForObjectInCompanyRaw({ objectType: objectType, objectId: objectId, companyId: companyId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetFileDownloadRaw(requestParameters: AttachmentControllerGetFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttachmentFileContent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGetFileDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}/fileDownload`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFileContentFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGetFileDownload(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttachmentFileContent> {
        const response = await this.attachmentControllerGetFileDownloadRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetFileDownloadByObjectIdAndObjectTypeRaw(requestParameters: AttachmentControllerGetFileDownloadByObjectIdAndObjectTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttachmentFileContent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGetFileDownloadByObjectIdAndObjectType.');
        }

        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling attachmentControllerGetFileDownloadByObjectIdAndObjectType.');
        }

        const queryParameters: any = {};

        if (requestParameters.objectType !== undefined) {
            queryParameters['objectType'] = requestParameters.objectType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/fileDownloadByObjectId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFileContentFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGetFileDownloadByObjectIdAndObjectType(id: string, objectType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttachmentFileContent> {
        const response = await this.attachmentControllerGetFileDownloadByObjectIdAndObjectTypeRaw({ id: id, objectType: objectType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetLastEditorRaw(requestParameters: AttachmentControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.attachmentControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetLastForProjectByShortnameRaw(requestParameters: AttachmentControllerGetLastForProjectByShortnameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAttachmentDto>>> {
        if (requestParameters.shortname === null || requestParameters.shortname === undefined) {
            throw new runtime.RequiredError('shortname','Required parameter requestParameters.shortname was null or undefined when calling attachmentControllerGetLastForProjectByShortname.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling attachmentControllerGetLastForProjectByShortname.');
        }

        const queryParameters: any = {};

        if (requestParameters.shortname !== undefined) {
            queryParameters['shortname'] = requestParameters.shortname;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/lastForProjectByShortname`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAttachmentDtoFromJSON));
    }

    /**
     */
    async attachmentControllerGetLastForProjectByShortname(shortname: string, projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAttachmentDto>> {
        const response = await this.attachmentControllerGetLastForProjectByShortnameRaw({ shortname: shortname, projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetZipByAttachmentIdsRaw(requestParameters: AttachmentControllerGetZipByAttachmentIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectNumber === null || requestParameters.projectNumber === undefined) {
            throw new runtime.RequiredError('projectNumber','Required parameter requestParameters.projectNumber was null or undefined when calling attachmentControllerGetZipByAttachmentIds.');
        }

        if (requestParameters.attachmentsIds === null || requestParameters.attachmentsIds === undefined) {
            throw new runtime.RequiredError('attachmentsIds','Required parameter requestParameters.attachmentsIds was null or undefined when calling attachmentControllerGetZipByAttachmentIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectNumber !== undefined) {
            queryParameters['projectNumber'] = requestParameters.projectNumber;
        }

        if (requestParameters.attachmentsIds) {
            queryParameters['attachmentsIds'] = requestParameters.attachmentsIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/zip/byAttachmentIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async attachmentControllerGetZipByAttachmentIds(projectNumber: string, attachmentsIds: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.attachmentControllerGetZipByAttachmentIdsRaw({ projectNumber: projectNumber, attachmentsIds: attachmentsIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async attachmentControllerUpdateRaw(requestParameters: AttachmentControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerUpdate.');
        }

        if (requestParameters.createUpdateAttachmentDto === null || requestParameters.createUpdateAttachmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateAttachmentDto','Required parameter requestParameters.createUpdateAttachmentDto was null or undefined when calling attachmentControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateAttachmentDtoToJSON(requestParameters.createUpdateAttachmentDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async attachmentControllerUpdate(id: string, createUpdateAttachmentDto: CreateUpdateAttachmentDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.attachmentControllerUpdateRaw({ id: id, createUpdateAttachmentDto: createUpdateAttachmentDto }, initOverrides);
    }

    /**
     */
    async bankAccountControllerCreateRaw(requestParameters: BankAccountControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankAccountDto>> {
        if (requestParameters.createUpdateBankAccountDto === null || requestParameters.createUpdateBankAccountDto === undefined) {
            throw new runtime.RequiredError('createUpdateBankAccountDto','Required parameter requestParameters.createUpdateBankAccountDto was null or undefined when calling bankAccountControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bankAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBankAccountDtoToJSON(requestParameters.createUpdateBankAccountDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankAccountDtoFromJSON(jsonValue));
    }

    /**
     */
    async bankAccountControllerCreate(createUpdateBankAccountDto: CreateUpdateBankAccountDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankAccountDto> {
        const response = await this.bankAccountControllerCreateRaw({ createUpdateBankAccountDto: createUpdateBankAccountDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async bankAccountControllerGetRaw(requestParameters: BankAccountControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBankAccountDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankAccountControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bankAccount/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBankAccountDtoFromJSON(jsonValue));
    }

    /**
     */
    async bankAccountControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBankAccountDto> {
        const response = await this.bankAccountControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async bankAccountControllerGetAllByPortionRaw(requestParameters: BankAccountControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBankAccountDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling bankAccountControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling bankAccountControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling bankAccountControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling bankAccountControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling bankAccountControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bankAccount/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBankAccountDtoFromJSON));
    }

    /**
     */
    async bankAccountControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBankAccountDto>> {
        const response = await this.bankAccountControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async bankAccountControllerGetAllCountRaw(requestParameters: BankAccountControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bankAccount/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bankAccountControllerGetAllCount(filterText?: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.bankAccountControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async bankAccountControllerGetLastEditorRaw(requestParameters: BankAccountControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankAccountControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bankAccount/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async bankAccountControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.bankAccountControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async bankAccountControllerUpdateRaw(requestParameters: BankAccountControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankAccountControllerUpdate.');
        }

        if (requestParameters.createUpdateBankAccountDto === null || requestParameters.createUpdateBankAccountDto === undefined) {
            throw new runtime.RequiredError('createUpdateBankAccountDto','Required parameter requestParameters.createUpdateBankAccountDto was null or undefined when calling bankAccountControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bankAccount/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBankAccountDtoToJSON(requestParameters.createUpdateBankAccountDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bankAccountControllerUpdate(id: string, createUpdateBankAccountDto: CreateUpdateBankAccountDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bankAccountControllerUpdateRaw({ id: id, createUpdateBankAccountDto: createUpdateBankAccountDto }, initOverrides);
    }

    /**
     */
    async blockadeMonthControllerCheckBlockedMonthRaw(requestParameters: BlockadeMonthControllerCheckBlockedMonthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling blockadeMonthControllerCheckBlockedMonth.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling blockadeMonthControllerCheckBlockedMonth.');
        }

        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling blockadeMonthControllerCheckBlockedMonth.');
        }

        if (requestParameters.isSalesBlocked === null || requestParameters.isSalesBlocked === undefined) {
            throw new runtime.RequiredError('isSalesBlocked','Required parameter requestParameters.isSalesBlocked was null or undefined when calling blockadeMonthControllerCheckBlockedMonth.');
        }

        if (requestParameters.isPurchaseBlocked === null || requestParameters.isPurchaseBlocked === undefined) {
            throw new runtime.RequiredError('isPurchaseBlocked','Required parameter requestParameters.isPurchaseBlocked was null or undefined when calling blockadeMonthControllerCheckBlockedMonth.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.isSalesBlocked !== undefined) {
            queryParameters['isSalesBlocked'] = requestParameters.isSalesBlocked;
        }

        if (requestParameters.isPurchaseBlocked !== undefined) {
            queryParameters['isPurchaseBlocked'] = requestParameters.isPurchaseBlocked;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/search/checkBlockade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async blockadeMonthControllerCheckBlockedMonth(date: Date, companyId: string, teamIds: Array<string>, isSalesBlocked: boolean, isPurchaseBlocked: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.blockadeMonthControllerCheckBlockedMonthRaw({ date: date, companyId: companyId, teamIds: teamIds, isSalesBlocked: isSalesBlocked, isPurchaseBlocked: isPurchaseBlocked }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerCreateRaw(requestParameters: BlockadeMonthControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBlockadeMonthDto>> {
        if (requestParameters.createUpdateBlockadeMonthDto === null || requestParameters.createUpdateBlockadeMonthDto === undefined) {
            throw new runtime.RequiredError('createUpdateBlockadeMonthDto','Required parameter requestParameters.createUpdateBlockadeMonthDto was null or undefined when calling blockadeMonthControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBlockadeMonthDtoToJSON(requestParameters.createUpdateBlockadeMonthDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBlockadeMonthDtoFromJSON(jsonValue));
    }

    /**
     */
    async blockadeMonthControllerCreate(createUpdateBlockadeMonthDto: CreateUpdateBlockadeMonthDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBlockadeMonthDto> {
        const response = await this.blockadeMonthControllerCreateRaw({ createUpdateBlockadeMonthDto: createUpdateBlockadeMonthDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerDeactivateRaw(requestParameters: BlockadeMonthControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blockadeMonthControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async blockadeMonthControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.blockadeMonthControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async blockadeMonthControllerFindByCompanyRaw(requestParameters: BlockadeMonthControllerFindByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBlockadeMonthDto>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling blockadeMonthControllerFindByCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/search/byCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBlockadeMonthDtoFromJSON));
    }

    /**
     */
    async blockadeMonthControllerFindByCompany(companyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBlockadeMonthDto>> {
        const response = await this.blockadeMonthControllerFindByCompanyRaw({ companyId: companyId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerGetRaw(requestParameters: BlockadeMonthControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBlockadeMonthDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blockadeMonthControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBlockadeMonthDtoFromJSON(jsonValue));
    }

    /**
     */
    async blockadeMonthControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBlockadeMonthDto> {
        const response = await this.blockadeMonthControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerGetAllByPortionRaw(requestParameters: BlockadeMonthControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBlockadeMonthDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling blockadeMonthControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling blockadeMonthControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling blockadeMonthControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling blockadeMonthControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling blockadeMonthControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBlockadeMonthDtoFromJSON));
    }

    /**
     */
    async blockadeMonthControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBlockadeMonthDto>> {
        const response = await this.blockadeMonthControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerGetAllCountRaw(requestParameters: BlockadeMonthControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling blockadeMonthControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async blockadeMonthControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.blockadeMonthControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerGetLastEditorRaw(requestParameters: BlockadeMonthControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blockadeMonthControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async blockadeMonthControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.blockadeMonthControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async blockadeMonthControllerUpdateRaw(requestParameters: BlockadeMonthControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling blockadeMonthControllerUpdate.');
        }

        if (requestParameters.createUpdateBlockadeMonthDto === null || requestParameters.createUpdateBlockadeMonthDto === undefined) {
            throw new runtime.RequiredError('createUpdateBlockadeMonthDto','Required parameter requestParameters.createUpdateBlockadeMonthDto was null or undefined when calling blockadeMonthControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/blockadeMonth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBlockadeMonthDtoToJSON(requestParameters.createUpdateBlockadeMonthDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async blockadeMonthControllerUpdate(id: string, createUpdateBlockadeMonthDto: CreateUpdateBlockadeMonthDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.blockadeMonthControllerUpdateRaw({ id: id, createUpdateBlockadeMonthDto: createUpdateBlockadeMonthDto }, initOverrides);
    }

    /**
     */
    async brandControllerCreateRaw(requestParameters: BrandControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandDto>> {
        if (requestParameters.createUpdateBrandDto === null || requestParameters.createUpdateBrandDto === undefined) {
            throw new runtime.RequiredError('createUpdateBrandDto','Required parameter requestParameters.createUpdateBrandDto was null or undefined when calling brandControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBrandDtoToJSON(requestParameters.createUpdateBrandDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandDtoFromJSON(jsonValue));
    }

    /**
     */
    async brandControllerCreate(createUpdateBrandDto: CreateUpdateBrandDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandDto> {
        const response = await this.brandControllerCreateRaw({ createUpdateBrandDto: createUpdateBrandDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerGetRaw(requestParameters: BrandControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBrandDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling brandControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandDtoFromJSON(jsonValue));
    }

    /**
     */
    async brandControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBrandDto> {
        const response = await this.brandControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerGetAllByBudgetRaw(requestParameters: BrandControllerGetAllByBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBrandDto>>> {
        if (requestParameters.budgetId === null || requestParameters.budgetId === undefined) {
            throw new runtime.RequiredError('budgetId','Required parameter requestParameters.budgetId was null or undefined when calling brandControllerGetAllByBudget.');
        }

        const queryParameters: any = {};

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/search/byBudget`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBrandDtoFromJSON));
    }

    /**
     */
    async brandControllerGetAllByBudget(budgetId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBrandDto>> {
        const response = await this.brandControllerGetAllByBudgetRaw({ budgetId: budgetId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerGetAllByPortionRaw(requestParameters: BrandControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBrandDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling brandControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling brandControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling brandControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling brandControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling brandControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBrandDtoFromJSON));
    }

    /**
     */
    async brandControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBrandDto>> {
        const response = await this.brandControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerGetAllByPurchaserIdRaw(requestParameters: BrandControllerGetAllByPurchaserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBrandDto>>> {
        if (requestParameters.purchaserId === null || requestParameters.purchaserId === undefined) {
            throw new runtime.RequiredError('purchaserId','Required parameter requestParameters.purchaserId was null or undefined when calling brandControllerGetAllByPurchaserId.');
        }

        const queryParameters: any = {};

        if (requestParameters.purchaserId !== undefined) {
            queryParameters['purchaserId'] = requestParameters.purchaserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/search/byPurchaserId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBrandDtoFromJSON));
    }

    /**
     */
    async brandControllerGetAllByPurchaserId(purchaserId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBrandDto>> {
        const response = await this.brandControllerGetAllByPurchaserIdRaw({ purchaserId: purchaserId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerGetAllCountRaw(requestParameters: BrandControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling brandControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async brandControllerGetAllCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.brandControllerGetAllCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerGetLastEditorRaw(requestParameters: BrandControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling brandControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async brandControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.brandControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async brandControllerUpdateRaw(requestParameters: BrandControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling brandControllerUpdate.');
        }

        if (requestParameters.createUpdateBrandDto === null || requestParameters.createUpdateBrandDto === undefined) {
            throw new runtime.RequiredError('createUpdateBrandDto','Required parameter requestParameters.createUpdateBrandDto was null or undefined when calling brandControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBrandDtoToJSON(requestParameters.createUpdateBrandDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async brandControllerUpdate(id: string, createUpdateBrandDto: CreateUpdateBrandDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.brandControllerUpdateRaw({ id: id, createUpdateBrandDto: createUpdateBrandDto }, initOverrides);
    }

    /**
     */
    async budgetControllerCreateRaw(requestParameters: BudgetControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBudgetDto>> {
        if (requestParameters.createUpdateBudgetDto === null || requestParameters.createUpdateBudgetDto === undefined) {
            throw new runtime.RequiredError('createUpdateBudgetDto','Required parameter requestParameters.createUpdateBudgetDto was null or undefined when calling budgetControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBudgetDtoToJSON(requestParameters.createUpdateBudgetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBudgetDtoFromJSON(jsonValue));
    }

    /**
     */
    async budgetControllerCreate(createUpdateBudgetDto: CreateUpdateBudgetDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBudgetDto> {
        const response = await this.budgetControllerCreateRaw({ createUpdateBudgetDto: createUpdateBudgetDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerGetRaw(requestParameters: BudgetControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBudgetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling budgetControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBudgetDtoFromJSON(jsonValue));
    }

    /**
     */
    async budgetControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBudgetDto> {
        const response = await this.budgetControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerGetAllByPortionRaw(requestParameters: BudgetControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBudgetDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling budgetControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling budgetControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling budgetControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling budgetControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling budgetControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBudgetDtoFromJSON));
    }

    /**
     */
    async budgetControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBudgetDto>> {
        const response = await this.budgetControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerGetAllCountRaw(requestParameters: BudgetControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling budgetControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async budgetControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.budgetControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerGetByPatternRaw(requestParameters: BudgetControllerGetByPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBudgetDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling budgetControllerGetByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling budgetControllerGetByPattern.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/search/byName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBudgetDtoFromJSON));
    }

    /**
     */
    async budgetControllerGetByPattern(pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBudgetDto>> {
        const response = await this.budgetControllerGetByPatternRaw({ pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerGetForDocumentControlByPatternRaw(requestParameters: BudgetControllerGetForDocumentControlByPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBudgetDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling budgetControllerGetForDocumentControlByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling budgetControllerGetForDocumentControlByPattern.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/search/forDocumentControlByName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBudgetDtoFromJSON));
    }

    /**
     */
    async budgetControllerGetForDocumentControlByPattern(pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBudgetDto>> {
        const response = await this.budgetControllerGetForDocumentControlByPatternRaw({ pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerGetLastEditorRaw(requestParameters: BudgetControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling budgetControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async budgetControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.budgetControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async budgetControllerUpdateRaw(requestParameters: BudgetControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling budgetControllerUpdate.');
        }

        if (requestParameters.createUpdateBudgetDto === null || requestParameters.createUpdateBudgetDto === undefined) {
            throw new runtime.RequiredError('createUpdateBudgetDto','Required parameter requestParameters.createUpdateBudgetDto was null or undefined when calling budgetControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBudgetDtoToJSON(requestParameters.createUpdateBudgetDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async budgetControllerUpdate(id: string, createUpdateBudgetDto: CreateUpdateBudgetDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.budgetControllerUpdateRaw({ id: id, createUpdateBudgetDto: createUpdateBudgetDto }, initOverrides);
    }

    /**
     */
    async calendarControllerAddDayRaw(requestParameters: CalendarControllerAddDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCalendarDto>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling calendarControllerAddDay.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCalendarDtoFromJSON(jsonValue));
    }

    /**
     */
    async calendarControllerAddDay(date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCalendarDto> {
        const response = await this.calendarControllerAddDayRaw({ date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendarControllerCreateYearRaw(requestParameters: CalendarControllerCreateYearRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling calendarControllerCreateYear.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/createYear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async calendarControllerCreateYear(date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.calendarControllerCreateYearRaw({ date: date }, initOverrides);
    }

    /**
     */
    async calendarControllerGetAllForYearRaw(requestParameters: CalendarControllerGetAllForYearRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling calendarControllerGetAllForYear.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/get/allForYear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async calendarControllerGetAllForYear(year: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.calendarControllerGetAllForYearRaw({ year: year }, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendarControllerRemoveDayRaw(requestParameters: CalendarControllerRemoveDayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling calendarControllerRemoveDay.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/calendar/remove/day`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async calendarControllerRemoveDay(date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.calendarControllerRemoveDayRaw({ date: date }, initOverrides);
    }

    /**
     */
    async clientControllerCreateRaw(requestParameters: ClientControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClientDto>> {
        if (requestParameters.createUpdateClientDto === null || requestParameters.createUpdateClientDto === undefined) {
            throw new runtime.RequiredError('createUpdateClientDto','Required parameter requestParameters.createUpdateClientDto was null or undefined when calling clientControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateClientDtoToJSON(requestParameters.createUpdateClientDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async clientControllerCreate(createUpdateClientDto: CreateUpdateClientDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClientDto> {
        const response = await this.clientControllerCreateRaw({ createUpdateClientDto: createUpdateClientDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerFormattedAddressRaw(requestParameters: ClientControllerFormattedAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClientExtendedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientControllerFormattedAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/formattedAddress/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClientExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async clientControllerFormattedAddress(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClientExtendedDto> {
        const response = await this.clientControllerFormattedAddressRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetRaw(requestParameters: ClientControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetClientExtendedWithCompanyIdDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetClientExtendedWithCompanyIdDtoFromJSON(jsonValue));
    }

    /**
     */
    async clientControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetClientExtendedWithCompanyIdDto> {
        const response = await this.clientControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetAllByPortionRaw(requestParameters: ClientControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetClientDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling clientControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling clientControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling clientControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling clientControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling clientControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientDtoFromJSON));
    }

    /**
     */
    async clientControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetClientDto>> {
        const response = await this.clientControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetAllCountRaw(requestParameters: ClientControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling clientControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async clientControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.clientControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetAllWithSymphonyByPortionRaw(requestParameters: ClientControllerGetAllWithSymphonyByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetClientWithSymphonyCodeDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling clientControllerGetAllWithSymphonyByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling clientControllerGetAllWithSymphonyByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling clientControllerGetAllWithSymphonyByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling clientControllerGetAllWithSymphonyByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling clientControllerGetAllWithSymphonyByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/searchWithCode/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientWithSymphonyCodeDtoFromJSON));
    }

    /**
     */
    async clientControllerGetAllWithSymphonyByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetClientWithSymphonyCodeDto>> {
        const response = await this.clientControllerGetAllWithSymphonyByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetAllWithSymphonyCountRaw(requestParameters: ClientControllerGetAllWithSymphonyCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling clientControllerGetAllWithSymphonyCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/searchWithCode/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async clientControllerGetAllWithSymphonyCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.clientControllerGetAllWithSymphonyCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetAllWithoutSymphonyByPortionRaw(requestParameters: ClientControllerGetAllWithoutSymphonyByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetClientWithInvoiceNumbers>>> {
        if (requestParameters.invoiceType === null || requestParameters.invoiceType === undefined) {
            throw new runtime.RequiredError('invoiceType','Required parameter requestParameters.invoiceType was null or undefined when calling clientControllerGetAllWithoutSymphonyByPortion.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling clientControllerGetAllWithoutSymphonyByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling clientControllerGetAllWithoutSymphonyByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling clientControllerGetAllWithoutSymphonyByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling clientControllerGetAllWithoutSymphonyByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling clientControllerGetAllWithoutSymphonyByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.invoiceType !== undefined) {
            queryParameters['invoiceType'] = requestParameters.invoiceType;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/searchWithoutCode/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientWithInvoiceNumbersFromJSON));
    }

    /**
     */
    async clientControllerGetAllWithoutSymphonyByPortion(invoiceType: number, pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetClientWithInvoiceNumbers>> {
        const response = await this.clientControllerGetAllWithoutSymphonyByPortionRaw({ invoiceType: invoiceType, pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetAllWithoutSymphonyCountRaw(requestParameters: ClientControllerGetAllWithoutSymphonyCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.invoiceType === null || requestParameters.invoiceType === undefined) {
            throw new runtime.RequiredError('invoiceType','Required parameter requestParameters.invoiceType was null or undefined when calling clientControllerGetAllWithoutSymphonyCount.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling clientControllerGetAllWithoutSymphonyCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.invoiceType !== undefined) {
            queryParameters['invoiceType'] = requestParameters.invoiceType;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/searchWithoutCode/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async clientControllerGetAllWithoutSymphonyCount(invoiceType: number, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.clientControllerGetAllWithoutSymphonyCountRaw({ invoiceType: invoiceType, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetByNipRaw(requestParameters: ClientControllerGetByNipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetClientDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling clientControllerGetByNip.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling clientControllerGetByNip.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/search/byNip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientDtoFromJSON));
    }

    /**
     */
    async clientControllerGetByNip(pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetClientDto>> {
        const response = await this.clientControllerGetByNipRaw({ pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetByNipOrNameRaw(requestParameters: ClientControllerGetByNipOrNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetClientDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling clientControllerGetByNipOrName.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling clientControllerGetByNipOrName.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/search/byNipOrName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientDtoFromJSON));
    }

    /**
     */
    async clientControllerGetByNipOrName(pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetClientDto>> {
        const response = await this.clientControllerGetByNipOrNameRaw({ pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetDistinctCountriesRaw(requestParameters: ClientControllerGetDistinctCountriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling clientControllerGetDistinctCountries.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling clientControllerGetDistinctCountries.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/search/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async clientControllerGetDistinctCountries(pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.clientControllerGetDistinctCountriesRaw({ pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerGetLastEditorRaw(requestParameters: ClientControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async clientControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.clientControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientControllerUpdateRaw(requestParameters: ClientControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientControllerUpdate.');
        }

        if (requestParameters.createUpdateClientDto === null || requestParameters.createUpdateClientDto === undefined) {
            throw new runtime.RequiredError('createUpdateClientDto','Required parameter requestParameters.createUpdateClientDto was null or undefined when calling clientControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateClientDtoToJSON(requestParameters.createUpdateClientDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientControllerUpdate(id: string, createUpdateClientDto: CreateUpdateClientDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clientControllerUpdateRaw({ id: id, createUpdateClientDto: createUpdateClientDto }, initOverrides);
    }

    /**
     */
    async companyControllerCreateRaw(requestParameters: CompanyControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompanyDto>> {
        if (requestParameters.createUpdateCompanyDto === null || requestParameters.createUpdateCompanyDto === undefined) {
            throw new runtime.RequiredError('createUpdateCompanyDto','Required parameter requestParameters.createUpdateCompanyDto was null or undefined when calling companyControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCompanyDtoToJSON(requestParameters.createUpdateCompanyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerCreate(createUpdateCompanyDto: CreateUpdateCompanyDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompanyDto> {
        const response = await this.companyControllerCreateRaw({ createUpdateCompanyDto: createUpdateCompanyDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerGetRaw(requestParameters: CompanyControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCompanyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCompanyDto> {
        const response = await this.companyControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerGetAllNotAssignedAndCurrentRaw(requestParameters: CompanyControllerGetAllNotAssignedAndCurrentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCompanyWithoutLogoDto>>> {
        const queryParameters: any = {};

        if (requestParameters.currentCompanyId !== undefined) {
            queryParameters['currentCompanyId'] = requestParameters.currentCompanyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/get/notAssigned`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyWithoutLogoDtoFromJSON));
    }

    /**
     */
    async companyControllerGetAllNotAssignedAndCurrent(currentCompanyId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCompanyWithoutLogoDto>> {
        const response = await this.companyControllerGetAllNotAssignedAndCurrentRaw({ currentCompanyId: currentCompanyId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerGetCompanyIdByClientIdRaw(requestParameters: CompanyControllerGetCompanyIdByClientIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/get/companyIdByClientId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async companyControllerGetCompanyIdByClientId(clientId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.companyControllerGetCompanyIdByClientIdRaw({ clientId: clientId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerGetLastEditorRaw(requestParameters: CompanyControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.companyControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerGetManyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCompanyDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyDtoFromJSON));
    }

    /**
     */
    async companyControllerGetMany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCompanyDto>> {
        const response = await this.companyControllerGetManyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerGetManyWithoutLogoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCompanyWithoutLogoDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/getWithoutLogo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyWithoutLogoDtoFromJSON));
    }

    /**
     */
    async companyControllerGetManyWithoutLogo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCompanyWithoutLogoDto>> {
        const response = await this.companyControllerGetManyWithoutLogoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async companyControllerUpdateRaw(requestParameters: CompanyControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyControllerUpdate.');
        }

        if (requestParameters.createUpdateCompanyDto === null || requestParameters.createUpdateCompanyDto === undefined) {
            throw new runtime.RequiredError('createUpdateCompanyDto','Required parameter requestParameters.createUpdateCompanyDto was null or undefined when calling companyControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCompanyDtoToJSON(requestParameters.createUpdateCompanyDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async companyControllerUpdate(id: string, createUpdateCompanyDto: CreateUpdateCompanyDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.companyControllerUpdateRaw({ id: id, createUpdateCompanyDto: createUpdateCompanyDto }, initOverrides);
    }

    /**
     */
    async dictionaryControllerChangeToInactiveRaw(requestParameters: DictionaryControllerChangeToInactiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerChangeToInactive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dictionaryControllerChangeToInactive(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dictionaryControllerChangeToInactiveRaw({ id: id }, initOverrides);
    }

    /**
     */
    async dictionaryControllerCreateRaw(requestParameters: DictionaryControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDictionaryDto>> {
        if (requestParameters.createUpdateDictionaryDto === null || requestParameters.createUpdateDictionaryDto === undefined) {
            throw new runtime.RequiredError('createUpdateDictionaryDto','Required parameter requestParameters.createUpdateDictionaryDto was null or undefined when calling dictionaryControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateDictionaryDtoToJSON(requestParameters.createUpdateDictionaryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerCreate(createUpdateDictionaryDto: CreateUpdateDictionaryDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDictionaryDto> {
        const response = await this.dictionaryControllerCreateRaw({ createUpdateDictionaryDto: createUpdateDictionaryDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetRaw(requestParameters: DictionaryControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDictionaryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDictionaryDto> {
        const response = await this.dictionaryControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetActiveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async dictionaryControllerGetActive(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetDictionaryDto>> {
        const response = await this.dictionaryControllerGetActiveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetByTypeRaw(requestParameters: DictionaryControllerGetByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetDictionaryDto>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling dictionaryControllerGetByType.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/get/byType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryDtoFromJSON));
    }

    /**
     */
    async dictionaryControllerGetByType(type: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetDictionaryDto>> {
        const response = await this.dictionaryControllerGetByTypeRaw({ type: type }, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetByTypeAndShortnameRaw(requestParameters: DictionaryControllerGetByTypeAndShortnameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDictionaryDto>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling dictionaryControllerGetByTypeAndShortname.');
        }

        if (requestParameters.symbol === null || requestParameters.symbol === undefined) {
            throw new runtime.RequiredError('symbol','Required parameter requestParameters.symbol was null or undefined when calling dictionaryControllerGetByTypeAndShortname.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.symbol !== undefined) {
            queryParameters['symbol'] = requestParameters.symbol;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/get/byTypeAndShortname`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerGetByTypeAndShortname(type: string, symbol: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDictionaryDto> {
        const response = await this.dictionaryControllerGetByTypeAndShortnameRaw({ type: type, symbol: symbol }, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerGetLastEditorRaw(requestParameters: DictionaryControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.dictionaryControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryControllerUpdateRaw(requestParameters: DictionaryControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryControllerUpdate.');
        }

        if (requestParameters.createUpdateDictionaryDto === null || requestParameters.createUpdateDictionaryDto === undefined) {
            throw new runtime.RequiredError('createUpdateDictionaryDto','Required parameter requestParameters.createUpdateDictionaryDto was null or undefined when calling dictionaryControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateDictionaryDtoToJSON(requestParameters.createUpdateDictionaryDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dictionaryControllerUpdate(id: string, createUpdateDictionaryDto: CreateUpdateDictionaryDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dictionaryControllerUpdateRaw({ id: id, createUpdateDictionaryDto: createUpdateDictionaryDto }, initOverrides);
    }

    /**
     */
    async dictionaryTypeControllerGetRaw(requestParameters: DictionaryTypeControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDictionaryTypeDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dictionaryTypeControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionaryType/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDictionaryTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async dictionaryTypeControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDictionaryTypeDto> {
        const response = await this.dictionaryTypeControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async dictionaryTypeControllerGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetDictionaryTypeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionaryType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDictionaryTypeDtoFromJSON));
    }

    /**
     */
    async dictionaryTypeControllerGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetDictionaryTypeDto>> {
        const response = await this.dictionaryTypeControllerGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async documentationControllerGetForFilenameRaw(requestParameters: DocumentationControllerGetForFilenameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDocumentationDto>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling documentationControllerGetForFilename.');
        }

        const queryParameters: any = {};

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documentation/byFilename`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentationDtoFromJSON(jsonValue));
    }

    /**
     */
    async documentationControllerGetForFilename(filename: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDocumentationDto> {
        const response = await this.documentationControllerGetForFilenameRaw({ filename: filename }, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentationControllerGetVersionInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVersionInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documentation/getVersionInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVersionInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async documentationControllerGetVersionInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVersionInfoDto> {
        const response = await this.documentationControllerGetVersionInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async emailConfigControllerCreateRaw(requestParameters: EmailConfigControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailConfigDto>> {
        if (requestParameters.createUpdateEmailConfigDto === null || requestParameters.createUpdateEmailConfigDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmailConfigDto','Required parameter requestParameters.createUpdateEmailConfigDto was null or undefined when calling emailConfigControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email_config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmailConfigDtoToJSON(requestParameters.createUpdateEmailConfigDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailConfigControllerCreate(createUpdateEmailConfigDto: CreateUpdateEmailConfigDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailConfigDto> {
        const response = await this.emailConfigControllerCreateRaw({ createUpdateEmailConfigDto: createUpdateEmailConfigDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailConfigControllerGetRaw(requestParameters: EmailConfigControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailConfigDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling emailConfigControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email_config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailConfigControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailConfigDto> {
        const response = await this.emailConfigControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailConfigControllerGetAllByPortionRaw(requestParameters: EmailConfigControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmailConfigDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling emailConfigControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling emailConfigControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling emailConfigControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling emailConfigControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling emailConfigControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.withContextCompany !== undefined) {
            queryParameters['withContextCompany'] = requestParameters.withContextCompany;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email_config/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmailConfigDtoFromJSON));
    }

    /**
     */
    async emailConfigControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, withContextCompany?: any, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmailConfigDto>> {
        const response = await this.emailConfigControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, withContextCompany: withContextCompany }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailConfigControllerGetAllCountRaw(requestParameters: EmailConfigControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling emailConfigControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email_config/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async emailConfigControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.emailConfigControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailConfigControllerGetLastEditorRaw(requestParameters: EmailConfigControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling emailConfigControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email_config/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailConfigControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.emailConfigControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailConfigControllerUpdateRaw(requestParameters: EmailConfigControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling emailConfigControllerUpdate.');
        }

        if (requestParameters.createUpdateEmailConfigDto === null || requestParameters.createUpdateEmailConfigDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmailConfigDto','Required parameter requestParameters.createUpdateEmailConfigDto was null or undefined when calling emailConfigControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email_config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmailConfigDtoToJSON(requestParameters.createUpdateEmailConfigDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async emailConfigControllerUpdate(id: string, createUpdateEmailConfigDto: CreateUpdateEmailConfigDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.emailConfigControllerUpdateRaw({ id: id, createUpdateEmailConfigDto: createUpdateEmailConfigDto }, initOverrides);
    }

    /**
     */
    async emailControllerSendApplicationEmailRaw(requestParameters: EmailControllerSendApplicationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling emailControllerSendApplicationEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendApplicationEmail/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendApplicationEmail(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendApplicationEmailRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendApplicationExecutionEmailRaw(requestParameters: EmailControllerSendApplicationExecutionEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling emailControllerSendApplicationExecutionEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendApplicationExecutionEmail/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendApplicationExecutionEmail(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendApplicationExecutionEmailRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendEmailByTypeRaw(requestParameters: EmailControllerSendEmailByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.objectId === null || requestParameters.objectId === undefined) {
            throw new runtime.RequiredError('objectId','Required parameter requestParameters.objectId was null or undefined when calling emailControllerSendEmailByType.');
        }

        if (requestParameters.emailType === null || requestParameters.emailType === undefined) {
            throw new runtime.RequiredError('emailType','Required parameter requestParameters.emailType was null or undefined when calling emailControllerSendEmailByType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendEmailByType`.replace(`{${"objectId"}}`, encodeURIComponent(String(requestParameters.objectId))).replace(`{${"emailType"}}`, encodeURIComponent(String(requestParameters.emailType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendEmailByType(objectId: string, emailType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendEmailByTypeRaw({ objectId: objectId, emailType: emailType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendOrderEmailRaw(requestParameters: EmailControllerSendOrderEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.order === null || requestParameters.order === undefined) {
            throw new runtime.RequiredError('order','Required parameter requestParameters.order was null or undefined when calling emailControllerSendOrderEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendOrderEmail/{order}`.replace(`{${"order"}}`, encodeURIComponent(String(requestParameters.order))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendOrderEmail(order: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendOrderEmailRaw({ order: order }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendOrderExecutionEmailRaw(requestParameters: EmailControllerSendOrderExecutionEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.orderExecution === null || requestParameters.orderExecution === undefined) {
            throw new runtime.RequiredError('orderExecution','Required parameter requestParameters.orderExecution was null or undefined when calling emailControllerSendOrderExecutionEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendOrderExecutionEmail/{orderExecution}`.replace(`{${"orderExecution"}}`, encodeURIComponent(String(requestParameters.orderExecution))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendOrderExecutionEmail(orderExecution: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendOrderExecutionEmailRaw({ orderExecution: orderExecution }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendProjectEmailRaw(requestParameters: EmailControllerSendProjectEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling emailControllerSendProjectEmail.');
        }

        if (requestParameters.applicationType === null || requestParameters.applicationType === undefined) {
            throw new runtime.RequiredError('applicationType','Required parameter requestParameters.applicationType was null or undefined when calling emailControllerSendProjectEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendProjectEmail/{project}/{applicationType}`.replace(`{${"project"}}`, encodeURIComponent(String(requestParameters.project))).replace(`{${"applicationType"}}`, encodeURIComponent(String(requestParameters.applicationType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendProjectEmail(project: string, applicationType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendProjectEmailRaw({ project: project, applicationType: applicationType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendProjectResponseEmailRaw(requestParameters: EmailControllerSendProjectResponseEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling emailControllerSendProjectResponseEmail.');
        }

        if (requestParameters.applicationType === null || requestParameters.applicationType === undefined) {
            throw new runtime.RequiredError('applicationType','Required parameter requestParameters.applicationType was null or undefined when calling emailControllerSendProjectResponseEmail.');
        }

        if (requestParameters.accepted === null || requestParameters.accepted === undefined) {
            throw new runtime.RequiredError('accepted','Required parameter requestParameters.accepted was null or undefined when calling emailControllerSendProjectResponseEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendProjectResponseEmail/{project}/{applicationType}/{accepted}`.replace(`{${"project"}}`, encodeURIComponent(String(requestParameters.project))).replace(`{${"applicationType"}}`, encodeURIComponent(String(requestParameters.applicationType))).replace(`{${"accepted"}}`, encodeURIComponent(String(requestParameters.accepted))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendProjectResponseEmail(project: string, applicationType: string, accepted: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendProjectResponseEmailRaw({ project: project, applicationType: applicationType, accepted: accepted }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendPurchaseDeficienciesEmailRaw(requestParameters: EmailControllerSendPurchaseDeficienciesEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.tv === null || requestParameters.tv === undefined) {
            throw new runtime.RequiredError('tv','Required parameter requestParameters.tv was null or undefined when calling emailControllerSendPurchaseDeficienciesEmail.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling emailControllerSendPurchaseDeficienciesEmail.');
        }

        if (requestParameters.message === null || requestParameters.message === undefined) {
            throw new runtime.RequiredError('message','Required parameter requestParameters.message was null or undefined when calling emailControllerSendPurchaseDeficienciesEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.tv !== undefined) {
            queryParameters['tv'] = requestParameters.tv;
        }

        if (requestParameters.email) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendPurchaseDeficienciesEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendPurchaseDeficienciesEmail(tv: boolean, email: Array<string>, message: string, teamId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendPurchaseDeficienciesEmailRaw({ tv: tv, email: email, message: message, teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendSpecialInvoiceEmailRaw(requestParameters: EmailControllerSendSpecialInvoiceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling emailControllerSendSpecialInvoiceEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.correction !== undefined) {
            queryParameters['correction'] = requestParameters.correction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendSpecialInvoiceEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendSpecialInvoiceEmail(id: string, correction?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendSpecialInvoiceEmailRaw({ id: id, correction: correction }, initOverrides);
        return await response.value();
    }

    /**
     */
    async emailControllerSendUnblockEmailRaw(requestParameters: EmailControllerSendUnblockEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.application === null || requestParameters.application === undefined) {
            throw new runtime.RequiredError('application','Required parameter requestParameters.application was null or undefined when calling emailControllerSendUnblockEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendUnblockEmail/{application}`.replace(`{${"application"}}`, encodeURIComponent(String(requestParameters.application))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendUnblockEmail(application: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmailDto> {
        const response = await this.emailControllerSendUnblockEmailRaw({ application: application }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerAddToEmployeesFromOneToolRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/userList/ByOneTool`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeControllerAddToEmployeesFromOneTool(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.employeeControllerAddToEmployeesFromOneToolRaw(initOverrides);
    }

    /**
     */
    async employeeControllerGetAllByPortionRaw(requestParameters: EmployeeControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeWithRolesDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling employeeControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling employeeControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling employeeControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling employeeControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling employeeControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeWithRolesDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeWithRolesDto>> {
        const response = await this.employeeControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerGetAllCountRaw(requestParameters: EmployeeControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling employeeControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async employeeControllerGetAllCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.employeeControllerGetAllCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerGetAllForOrdersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/search/forOrders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetAllForOrders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeDto>> {
        const response = await this.employeeControllerGetAllForOrdersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerGetByCompanyRaw(requestParameters: EmployeeControllerGetByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.withTechnical !== undefined) {
            queryParameters['withTechnical'] = requestParameters.withTechnical;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/getByCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetByCompany(id?: string, withTechnical?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeDto>> {
        const response = await this.employeeControllerGetByCompanyRaw({ id: id, withTechnical: withTechnical }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerGetCurrentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmployeeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/loggedIn/BySub`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployeeDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeControllerGetCurrent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmployeeDto> {
        const response = await this.employeeControllerGetCurrentRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerGetNotActiveByPortionRaw(requestParameters: EmployeeControllerGetNotActiveByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeWithRolesDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling employeeControllerGetNotActiveByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling employeeControllerGetNotActiveByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling employeeControllerGetNotActiveByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling employeeControllerGetNotActiveByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling employeeControllerGetNotActiveByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/searchNotActive/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeWithRolesDtoFromJSON));
    }

    /**
     */
    async employeeControllerGetNotActiveByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeWithRolesDto>> {
        const response = await this.employeeControllerGetNotActiveByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeControllerGetNotActiveCountRaw(requestParameters: EmployeeControllerGetNotActiveCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling employeeControllerGetNotActiveCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/searchNotActive/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async employeeControllerGetNotActiveCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.employeeControllerGetNotActiveCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerCreateRaw(requestParameters: EmployeeRoleControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmployeeRoleDto>> {
        if (requestParameters.createUpdateEmployeeRoleDto === null || requestParameters.createUpdateEmployeeRoleDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmployeeRoleDto','Required parameter requestParameters.createUpdateEmployeeRoleDto was null or undefined when calling employeeRoleControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmployeeRoleDtoToJSON(requestParameters.createUpdateEmployeeRoleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployeeRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeRoleControllerCreate(createUpdateEmployeeRoleDto: CreateUpdateEmployeeRoleDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmployeeRoleDto> {
        const response = await this.employeeRoleControllerCreateRaw({ createUpdateEmployeeRoleDto: createUpdateEmployeeRoleDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerCreateEmployeeWithRolesRaw(requestParameters: EmployeeRoleControllerCreateEmployeeWithRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createUpdateEmployeeRolesDto === null || requestParameters.createUpdateEmployeeRolesDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmployeeRolesDto','Required parameter requestParameters.createUpdateEmployeeRolesDto was null or undefined when calling employeeRoleControllerCreateEmployeeWithRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/employeeWithRoles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmployeeRolesDtoToJSON(requestParameters.createUpdateEmployeeRolesDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeRoleControllerCreateEmployeeWithRoles(createUpdateEmployeeRolesDto: CreateUpdateEmployeeRolesDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.employeeRoleControllerCreateEmployeeWithRolesRaw({ createUpdateEmployeeRolesDto: createUpdateEmployeeRolesDto }, initOverrides);
    }

    /**
     */
    async employeeRoleControllerDeleteRaw(requestParameters: EmployeeRoleControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeRoleControllerDelete(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.employeeRoleControllerDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async employeeRoleControllerDeleteEmployeeRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/delete/EmployeeRoles`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeRoleControllerDeleteEmployeeRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.employeeRoleControllerDeleteEmployeeRolesRaw(initOverrides);
    }

    /**
     */
    async employeeRoleControllerFindTeamsWithCompanyByUserRaw(requestParameters: EmployeeRoleControllerFindTeamsWithCompanyByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TeamInCompanyInTokenDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerFindTeamsWithCompanyByUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/findTeamsWithCompanyByUser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamInCompanyInTokenDtoFromJSON));
    }

    /**
     */
    async employeeRoleControllerFindTeamsWithCompanyByUser(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TeamInCompanyInTokenDto>> {
        const response = await this.employeeRoleControllerFindTeamsWithCompanyByUserRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerGetRaw(requestParameters: EmployeeRoleControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmployeeRoleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployeeRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeRoleControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmployeeRoleDto> {
        const response = await this.employeeRoleControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerGetAllByPortionRaw(requestParameters: EmployeeRoleControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeRoleDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling employeeRoleControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling employeeRoleControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling employeeRoleControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling employeeRoleControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling employeeRoleControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeRoleDtoFromJSON));
    }

    /**
     */
    async employeeRoleControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeRoleDto>> {
        const response = await this.employeeRoleControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerGetAllCountRaw(requestParameters: EmployeeRoleControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling employeeRoleControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async employeeRoleControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.employeeRoleControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerGetLastEditorRaw(requestParameters: EmployeeRoleControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async employeeRoleControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.employeeRoleControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerGetWithTheSameRoleByRoleIdRaw(requestParameters: EmployeeRoleControllerGetWithTheSameRoleByRoleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeRoleDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerGetWithTheSameRoleByRoleId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/withTheSameRoleByRoleId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeRoleDtoFromJSON));
    }

    /**
     */
    async employeeRoleControllerGetWithTheSameRoleByRoleId(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeRoleDto>> {
        const response = await this.employeeRoleControllerGetWithTheSameRoleByRoleIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerGetWithTheSameUserByUserIdRaw(requestParameters: EmployeeRoleControllerGetWithTheSameUserByUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEmployeeRoleDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerGetWithTheSameUserByUserId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/withTheSameUserByUserId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmployeeRoleDtoFromJSON));
    }

    /**
     */
    async employeeRoleControllerGetWithTheSameUserByUserId(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEmployeeRoleDto>> {
        const response = await this.employeeRoleControllerGetWithTheSameUserByUserIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async employeeRoleControllerUpdateRaw(requestParameters: EmployeeRoleControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling employeeRoleControllerUpdate.');
        }

        if (requestParameters.createUpdateEmployeeRoleDto === null || requestParameters.createUpdateEmployeeRoleDto === undefined) {
            throw new runtime.RequiredError('createUpdateEmployeeRoleDto','Required parameter requestParameters.createUpdateEmployeeRoleDto was null or undefined when calling employeeRoleControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employeeRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEmployeeRoleDtoToJSON(requestParameters.createUpdateEmployeeRoleDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async employeeRoleControllerUpdate(id: string, createUpdateEmployeeRoleDto: CreateUpdateEmployeeRoleDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.employeeRoleControllerUpdateRaw({ id: id, createUpdateEmployeeRoleDto: createUpdateEmployeeRoleDto }, initOverrides);
    }

    /**
     */
    async estimateByMonthControllerCloneRaw(requestParameters: EstimateByMonthControllerCloneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateByMonthDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateByMonthControllerClone.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling estimateByMonthControllerClone.');
        }

        if (requestParameters.withAmounts === null || requestParameters.withAmounts === undefined) {
            throw new runtime.RequiredError('withAmounts','Required parameter requestParameters.withAmounts was null or undefined when calling estimateByMonthControllerClone.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.withAmounts !== undefined) {
            queryParameters['withAmounts'] = requestParameters.withAmounts;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/clone/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateByMonthDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateByMonthControllerClone(id: string, date: Date, withAmounts: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateByMonthDto> {
        const response = await this.estimateByMonthControllerCloneRaw({ id: id, date: date, withAmounts: withAmounts }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerCreateRaw(requestParameters: EstimateByMonthControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateByMonthDto>> {
        if (requestParameters.createUpdateEstimateByMonthDto === null || requestParameters.createUpdateEstimateByMonthDto === undefined) {
            throw new runtime.RequiredError('createUpdateEstimateByMonthDto','Required parameter requestParameters.createUpdateEstimateByMonthDto was null or undefined when calling estimateByMonthControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEstimateByMonthDtoToJSON(requestParameters.createUpdateEstimateByMonthDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateByMonthDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateByMonthControllerCreate(createUpdateEstimateByMonthDto: CreateUpdateEstimateByMonthDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateByMonthDto> {
        const response = await this.estimateByMonthControllerCreateRaw({ createUpdateEstimateByMonthDto: createUpdateEstimateByMonthDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerDeactivateRaw(requestParameters: EstimateByMonthControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateByMonthControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateByMonthControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateByMonthControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async estimateByMonthControllerFindByProjectRaw(requestParameters: EstimateByMonthControllerFindByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateByMonthDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateByMonthControllerFindByProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/search/estimatesByProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateByMonthDtoFromJSON));
    }

    /**
     */
    async estimateByMonthControllerFindByProject(projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateByMonthDto>> {
        const response = await this.estimateByMonthControllerFindByProjectRaw({ projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerFindEstimateItemsByProjectRaw(requestParameters: EstimateByMonthControllerFindEstimateItemsByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateByMonthWithItemsDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateByMonthControllerFindEstimateItemsByProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/search/estimateItemsByProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateByMonthWithItemsDtoFromJSON));
    }

    /**
     */
    async estimateByMonthControllerFindEstimateItemsByProject(projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateByMonthWithItemsDto>> {
        const response = await this.estimateByMonthControllerFindEstimateItemsByProjectRaw({ projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerGetRaw(requestParameters: EstimateByMonthControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateByMonthDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateByMonthControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateByMonthDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateByMonthControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateByMonthDto> {
        const response = await this.estimateByMonthControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerGetAllByPortionRaw(requestParameters: EstimateByMonthControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateByMonthWithItemsDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling estimateByMonthControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling estimateByMonthControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling estimateByMonthControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling estimateByMonthControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling estimateByMonthControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateByMonthWithItemsDtoFromJSON));
    }

    /**
     */
    async estimateByMonthControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateByMonthWithItemsDto>> {
        const response = await this.estimateByMonthControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerGetAllCountRaw(requestParameters: EstimateByMonthControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling estimateByMonthControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async estimateByMonthControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.estimateByMonthControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerGetLastEditorRaw(requestParameters: EstimateByMonthControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateByMonthControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateByMonthControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.estimateByMonthControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateByMonthControllerToggleBlockedRaw(requestParameters: EstimateByMonthControllerToggleBlockedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling estimateByMonthControllerToggleBlocked.');
        }

        if (requestParameters.value === null || requestParameters.value === undefined) {
            throw new runtime.RequiredError('value','Required parameter requestParameters.value was null or undefined when calling estimateByMonthControllerToggleBlocked.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateByMonthControllerToggleBlocked.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.value !== undefined) {
            queryParameters['value'] = requestParameters.value;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/toggleBlocked/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateByMonthControllerToggleBlocked(type: string, value: boolean, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateByMonthControllerToggleBlockedRaw({ type: type, value: value, id: id }, initOverrides);
    }

    /**
     */
    async estimateByMonthControllerUpdateStatusRaw(requestParameters: EstimateByMonthControllerUpdateStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling estimateByMonthControllerUpdateStatus.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateByMonthControllerUpdateStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateByMonth/status/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateByMonthControllerUpdateStatus(status: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateByMonthControllerUpdateStatusRaw({ status: status, id: id }, initOverrides);
    }

    /**
     */
    async estimateItemControllerCheckItemLockRaw(requestParameters: EstimateItemControllerCheckItemLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateItemLockDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateItemControllerCheckItemLock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/checkItemLock/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateItemLockDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateItemControllerCheckItemLock(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateItemLockDto> {
        const response = await this.estimateItemControllerCheckItemLockRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerCloneRaw(requestParameters: EstimateItemControllerCloneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.itemsIds === null || requestParameters.itemsIds === undefined) {
            throw new runtime.RequiredError('itemsIds','Required parameter requestParameters.itemsIds was null or undefined when calling estimateItemControllerClone.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling estimateItemControllerClone.');
        }

        if (requestParameters.withAmounts === null || requestParameters.withAmounts === undefined) {
            throw new runtime.RequiredError('withAmounts','Required parameter requestParameters.withAmounts was null or undefined when calling estimateItemControllerClone.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerClone.');
        }

        const queryParameters: any = {};

        if (requestParameters.itemsIds) {
            queryParameters['itemsIds'] = requestParameters.itemsIds;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.withAmounts !== undefined) {
            queryParameters['withAmounts'] = requestParameters.withAmounts;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/cloneItems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateItemControllerClone(itemsIds: Array<string>, date: Date, withAmounts: boolean, projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateItemControllerCloneRaw({ itemsIds: itemsIds, date: date, withAmounts: withAmounts, projectId: projectId }, initOverrides);
    }

    /**
     */
    async estimateItemControllerCreateRaw(requestParameters: EstimateItemControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateItemDto>> {
        if (requestParameters.createUpdateEstimateItemDto === null || requestParameters.createUpdateEstimateItemDto === undefined) {
            throw new runtime.RequiredError('createUpdateEstimateItemDto','Required parameter requestParameters.createUpdateEstimateItemDto was null or undefined when calling estimateItemControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEstimateItemDtoToJSON(requestParameters.createUpdateEstimateItemDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateItemControllerCreate(createUpdateEstimateItemDto: CreateUpdateEstimateItemDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateItemDto> {
        const response = await this.estimateItemControllerCreateRaw({ createUpdateEstimateItemDto: createUpdateEstimateItemDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerCreateEstimateItemFromPaeRaw(requestParameters: EstimateItemControllerCreateEstimateItemFromPaeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createPaeImportDto === null || requestParameters.createPaeImportDto === undefined) {
            throw new runtime.RequiredError('createPaeImportDto','Required parameter requestParameters.createPaeImportDto was null or undefined when calling estimateItemControllerCreateEstimateItemFromPae.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/createEstimateItemFromPae`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePaeImportDtoToJSON(requestParameters.createPaeImportDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateItemControllerCreateEstimateItemFromPae(createPaeImportDto: CreatePaeImportDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateItemControllerCreateEstimateItemFromPaeRaw({ createPaeImportDto: createPaeImportDto }, initOverrides);
    }

    /**
     */
    async estimateItemControllerDeactivateRaw(requestParameters: EstimateItemControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateItemControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateItemControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateItemControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async estimateItemControllerFindAllByProjectAndTeamRaw(requestParameters: EstimateItemControllerFindAllByProjectAndTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerFindAllByProjectAndTeam.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling estimateItemControllerFindAllByProjectAndTeam.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/byProjectAndTeam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindAllByProjectAndTeam(projectId: string, teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindAllByProjectAndTeamRaw({ projectId: projectId, teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindByEstimateMonthRaw(requestParameters: EstimateItemControllerFindByEstimateMonthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.estimateMonthId === null || requestParameters.estimateMonthId === undefined) {
            throw new runtime.RequiredError('estimateMonthId','Required parameter requestParameters.estimateMonthId was null or undefined when calling estimateItemControllerFindByEstimateMonth.');
        }

        const queryParameters: any = {};

        if (requestParameters.estimateMonthId !== undefined) {
            queryParameters['estimateMonthId'] = requestParameters.estimateMonthId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/itemsByEstimateMonth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindByEstimateMonth(estimateMonthId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindByEstimateMonthRaw({ estimateMonthId: estimateMonthId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindByProjectRaw(requestParameters: EstimateItemControllerFindByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerFindByProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/byProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindByProject(projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindByProjectRaw({ projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindByProjectAndClientRaw(requestParameters: EstimateItemControllerFindByProjectAndClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerFindByProjectAndClient.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling estimateItemControllerFindByProjectAndClient.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/byProjectAndClient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindByProjectAndClient(projectId: string, clientId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindByProjectAndClientRaw({ projectId: projectId, clientId: clientId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindByProjectAndMonthRaw(requestParameters: EstimateItemControllerFindByProjectAndMonthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerFindByProjectAndMonth.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling estimateItemControllerFindByProjectAndMonth.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/byProjectAndMonth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindByProjectAndMonth(projectId: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindByProjectAndMonthRaw({ projectId: projectId, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindByProjectProfileMonthRaw(requestParameters: EstimateItemControllerFindByProjectProfileMonthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerFindByProjectProfileMonth.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/byProjectProfileMonth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindByProjectProfileMonth(projectId: string, profileId?: string, date?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindByProjectProfileMonthRaw({ projectId: projectId, profileId: profileId, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindForDocumentControlByPortionRaw(requestParameters: EstimateItemControllerFindForDocumentControlByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDocumentControlDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling estimateItemControllerFindForDocumentControlByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling estimateItemControllerFindForDocumentControlByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling estimateItemControllerFindForDocumentControlByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling estimateItemControllerFindForDocumentControlByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling estimateItemControllerFindForDocumentControlByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/forDocumentControl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDocumentControlDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindForDocumentControlByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, budgetId?: string, projectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDocumentControlDto>> {
        const response = await this.estimateItemControllerFindForDocumentControlByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, budgetId: budgetId, projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindForDocumentControlCountRaw(requestParameters: EstimateItemControllerFindForDocumentControlCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling estimateItemControllerFindForDocumentControlCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/searchForDocumentControl/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async estimateItemControllerFindForDocumentControlCount(filterText: string, filterColumns?: object, budgetId?: string, projectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.estimateItemControllerFindForDocumentControlCountRaw({ filterText: filterText, filterColumns: filterColumns, budgetId: budgetId, projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindForOrderExecutionSumRaw(requestParameters: EstimateItemControllerFindForOrderExecutionSumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling estimateItemControllerFindForOrderExecutionSum.');
        }

        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling estimateItemControllerFindForOrderExecutionSum.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling estimateItemControllerFindForOrderExecutionSum.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling estimateItemControllerFindForOrderExecutionSum.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/forOrderExecutionSum`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindForOrderExecutionSum(projectId: string, teamIds: Array<string>, clientId: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindForOrderExecutionSumRaw({ projectId: projectId, teamIds: teamIds, clientId: clientId, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindForPurchaseInvoiceByClientAndProjectsRaw(requestParameters: EstimateItemControllerFindForPurchaseInvoiceByClientAndProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.purchaseInvoiceId === null || requestParameters.purchaseInvoiceId === undefined) {
            throw new runtime.RequiredError('purchaseInvoiceId','Required parameter requestParameters.purchaseInvoiceId was null or undefined when calling estimateItemControllerFindForPurchaseInvoiceByClientAndProjects.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling estimateItemControllerFindForPurchaseInvoiceByClientAndProjects.');
        }

        if (requestParameters.projectIds === null || requestParameters.projectIds === undefined) {
            throw new runtime.RequiredError('projectIds','Required parameter requestParameters.projectIds was null or undefined when calling estimateItemControllerFindForPurchaseInvoiceByClientAndProjects.');
        }

        const queryParameters: any = {};

        if (requestParameters.purchaseInvoiceId !== undefined) {
            queryParameters['purchaseInvoiceId'] = requestParameters.purchaseInvoiceId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.projectIds) {
            queryParameters['projectIds'] = requestParameters.projectIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/itemsForPurchaseInvoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindForPurchaseInvoiceByClientAndProjects(purchaseInvoiceId: string, clientId: string, projectIds: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindForPurchaseInvoiceByClientAndProjectsRaw({ purchaseInvoiceId: purchaseInvoiceId, clientId: clientId, projectIds: projectIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindPurchaseDeficienciesCountRaw(requestParameters: EstimateItemControllerFindPurchaseDeficienciesCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling estimateItemControllerFindPurchaseDeficienciesCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.tv !== undefined) {
            queryParameters['tv'] = requestParameters.tv;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/searchPurchaseDeficiencies/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async estimateItemControllerFindPurchaseDeficienciesCount(filterText: string, filterColumns?: object, tv?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.estimateItemControllerFindPurchaseDeficienciesCountRaw({ filterText: filterText, filterColumns: filterColumns, tv: tv }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerFindReadyToInvoiceByProjectsRaw(requestParameters: EstimateItemControllerFindReadyToInvoiceByProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemDto>>> {
        if (requestParameters.projectIds === null || requestParameters.projectIds === undefined) {
            throw new runtime.RequiredError('projectIds','Required parameter requestParameters.projectIds was null or undefined when calling estimateItemControllerFindReadyToInvoiceByProjects.');
        }

        if (requestParameters.salesInvoiceId === null || requestParameters.salesInvoiceId === undefined) {
            throw new runtime.RequiredError('salesInvoiceId','Required parameter requestParameters.salesInvoiceId was null or undefined when calling estimateItemControllerFindReadyToInvoiceByProjects.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectIds) {
            queryParameters['projectIds'] = requestParameters.projectIds;
        }

        if (requestParameters.salesInvoiceId !== undefined) {
            queryParameters['salesInvoiceId'] = requestParameters.salesInvoiceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/itemsReadyToInvoiceByProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerFindReadyToInvoiceByProjects(projectIds: Array<string>, salesInvoiceId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemDto>> {
        const response = await this.estimateItemControllerFindReadyToInvoiceByProjectsRaw({ projectIds: projectIds, salesInvoiceId: salesInvoiceId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerGetRaw(requestParameters: EstimateItemControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateItemDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateItemControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateItemControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateItemDto> {
        const response = await this.estimateItemControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerGetActionGroupsByCompanyAndBudgetRaw(requestParameters: EstimateItemControllerGetActionGroupsByCompanyAndBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling estimateItemControllerGetActionGroupsByCompanyAndBudget.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling estimateItemControllerGetActionGroupsByCompanyAndBudget.');
        }

        if (requestParameters.budgetId === null || requestParameters.budgetId === undefined) {
            throw new runtime.RequiredError('budgetId','Required parameter requestParameters.budgetId was null or undefined when calling estimateItemControllerGetActionGroupsByCompanyAndBudget.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling estimateItemControllerGetActionGroupsByCompanyAndBudget.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/actionGroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async estimateItemControllerGetActionGroupsByCompanyAndBudget(pattern: string, companyId: string, budgetId: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.estimateItemControllerGetActionGroupsByCompanyAndBudgetRaw({ pattern: pattern, companyId: companyId, budgetId: budgetId, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerGetEstimateItemsFromPaeRaw(requestParameters: EstimateItemControllerGetEstimateItemsFromPaeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaeImportDto>> {
        if (requestParameters.projectNumber === null || requestParameters.projectNumber === undefined) {
            throw new runtime.RequiredError('projectNumber','Required parameter requestParameters.projectNumber was null or undefined when calling estimateItemControllerGetEstimateItemsFromPae.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling estimateItemControllerGetEstimateItemsFromPae.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling estimateItemControllerGetEstimateItemsFromPae.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectNumber !== undefined) {
            queryParameters['projectNumber'] = requestParameters.projectNumber;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/getEstimateItemsFromPae/byProjectAndDate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaeImportDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateItemControllerGetEstimateItemsFromPae(projectNumber: string, year: number, month: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaeImportDto> {
        const response = await this.estimateItemControllerGetEstimateItemsFromPaeRaw({ projectNumber: projectNumber, year: year, month: month }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerGetLastEditorRaw(requestParameters: EstimateItemControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateItemControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateItemControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.estimateItemControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerGetPurchaseDeficienciesRaw(requestParameters: EstimateItemControllerGetPurchaseDeficienciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetEstimateItemPurchaseDeficienciesDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling estimateItemControllerGetPurchaseDeficiencies.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling estimateItemControllerGetPurchaseDeficiencies.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling estimateItemControllerGetPurchaseDeficiencies.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling estimateItemControllerGetPurchaseDeficiencies.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling estimateItemControllerGetPurchaseDeficiencies.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.tv !== undefined) {
            queryParameters['tv'] = requestParameters.tv;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/search/purchaseDeficiencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEstimateItemPurchaseDeficienciesDtoFromJSON));
    }

    /**
     */
    async estimateItemControllerGetPurchaseDeficiencies(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, tv?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetEstimateItemPurchaseDeficienciesDto>> {
        const response = await this.estimateItemControllerGetPurchaseDeficienciesRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, tv: tv }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerUpdateRaw(requestParameters: EstimateItemControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEstimateItemDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateItemControllerUpdate.');
        }

        if (requestParameters.createUpdateEstimateItemDto === null || requestParameters.createUpdateEstimateItemDto === undefined) {
            throw new runtime.RequiredError('createUpdateEstimateItemDto','Required parameter requestParameters.createUpdateEstimateItemDto was null or undefined when calling estimateItemControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateEstimateItemDtoToJSON(requestParameters.createUpdateEstimateItemDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEstimateItemDtoFromJSON(jsonValue));
    }

    /**
     */
    async estimateItemControllerUpdate(id: string, createUpdateEstimateItemDto: CreateUpdateEstimateItemDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEstimateItemDto> {
        const response = await this.estimateItemControllerUpdateRaw({ id: id, createUpdateEstimateItemDto: createUpdateEstimateItemDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async estimateItemControllerUpdatePurchaseNetTotalRaw(requestParameters: EstimateItemControllerUpdatePurchaseNetTotalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling estimateItemControllerUpdatePurchaseNetTotal.');
        }

        if (requestParameters.updatePurchaseNetTotalDto === null || requestParameters.updatePurchaseNetTotalDto === undefined) {
            throw new runtime.RequiredError('updatePurchaseNetTotalDto','Required parameter requestParameters.updatePurchaseNetTotalDto was null or undefined when calling estimateItemControllerUpdatePurchaseNetTotal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/estimateItem/updatePurchaseNetTotal/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePurchaseNetTotalDtoToJSON(requestParameters.updatePurchaseNetTotalDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async estimateItemControllerUpdatePurchaseNetTotal(id: string, updatePurchaseNetTotalDto: UpdatePurchaseNetTotalDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.estimateItemControllerUpdatePurchaseNetTotalRaw({ id: id, updatePurchaseNetTotalDto: updatePurchaseNetTotalDto }, initOverrides);
    }

    /**
     */
    async excelConfigControllerCreateRaw(requestParameters: ExcelConfigControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigDto>> {
        if (requestParameters.createUpdateExcelConfigDto === null || requestParameters.createUpdateExcelConfigDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelConfigDto','Required parameter requestParameters.createUpdateExcelConfigDto was null or undefined when calling excelConfigControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelConfigDtoToJSON(requestParameters.createUpdateExcelConfigDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigControllerCreate(createUpdateExcelConfigDto: CreateUpdateExcelConfigDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigDto> {
        const response = await this.excelConfigControllerCreateRaw({ createUpdateExcelConfigDto: createUpdateExcelConfigDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigControllerGetRaw(requestParameters: ExcelConfigControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigDto> {
        const response = await this.excelConfigControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigControllerGetAllByPortionRaw(requestParameters: ExcelConfigControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExcelConfigDto>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling excelConfigControllerGetAllByPortion.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling excelConfigControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling excelConfigControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling excelConfigControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling excelConfigControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling excelConfigControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExcelConfigDtoFromJSON));
    }

    /**
     */
    async excelConfigControllerGetAllByPortion(companyId: string, pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExcelConfigDto>> {
        const response = await this.excelConfigControllerGetAllByPortionRaw({ companyId: companyId, pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigControllerGetAllCountRaw(requestParameters: ExcelConfigControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling excelConfigControllerGetAllCount.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling excelConfigControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async excelConfigControllerGetAllCount(companyId: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.excelConfigControllerGetAllCountRaw({ companyId: companyId, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigControllerGetLastEditorRaw(requestParameters: ExcelConfigControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.excelConfigControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigControllerSearchRaw(requestParameters: ExcelConfigControllerSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExcelConfigDto>>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling excelConfigControllerSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig/search/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExcelConfigDtoFromJSON));
    }

    /**
     */
    async excelConfigControllerSearch(limit: number, filterText?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExcelConfigDto>> {
        const response = await this.excelConfigControllerSearchRaw({ limit: limit, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigControllerUpdateRaw(requestParameters: ExcelConfigControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigControllerUpdate.');
        }

        if (requestParameters.createUpdateExcelConfigDto === null || requestParameters.createUpdateExcelConfigDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelConfigDto','Required parameter requestParameters.createUpdateExcelConfigDto was null or undefined when calling excelConfigControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfig/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelConfigDtoToJSON(requestParameters.createUpdateExcelConfigDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async excelConfigControllerUpdate(id: string, createUpdateExcelConfigDto: CreateUpdateExcelConfigDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.excelConfigControllerUpdateRaw({ id: id, createUpdateExcelConfigDto: createUpdateExcelConfigDto }, initOverrides);
    }

    /**
     */
    async excelConfigHeaderControllerCreateRaw(requestParameters: ExcelConfigHeaderControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigHeaderDto>> {
        if (requestParameters.createUpdateExcelConfigHeaderDto === null || requestParameters.createUpdateExcelConfigHeaderDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelConfigHeaderDto','Required parameter requestParameters.createUpdateExcelConfigHeaderDto was null or undefined when calling excelConfigHeaderControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigHeader`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelConfigHeaderDtoToJSON(requestParameters.createUpdateExcelConfigHeaderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigHeaderDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigHeaderControllerCreate(createUpdateExcelConfigHeaderDto: CreateUpdateExcelConfigHeaderDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigHeaderDto> {
        const response = await this.excelConfigHeaderControllerCreateRaw({ createUpdateExcelConfigHeaderDto: createUpdateExcelConfigHeaderDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigHeaderControllerGetRaw(requestParameters: ExcelConfigHeaderControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigHeaderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigHeaderControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigHeader/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigHeaderDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigHeaderControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigHeaderDto> {
        const response = await this.excelConfigHeaderControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigHeaderControllerGetAllByConfigRaw(requestParameters: ExcelConfigHeaderControllerGetAllByConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExcelConfigHeaderDto>>> {
        if (requestParameters.excelConfigId === null || requestParameters.excelConfigId === undefined) {
            throw new runtime.RequiredError('excelConfigId','Required parameter requestParameters.excelConfigId was null or undefined when calling excelConfigHeaderControllerGetAllByConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.excelConfigId !== undefined) {
            queryParameters['excelConfigId'] = requestParameters.excelConfigId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigHeader/search/byConfig`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExcelConfigHeaderDtoFromJSON));
    }

    /**
     */
    async excelConfigHeaderControllerGetAllByConfig(excelConfigId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExcelConfigHeaderDto>> {
        const response = await this.excelConfigHeaderControllerGetAllByConfigRaw({ excelConfigId: excelConfigId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigHeaderControllerGetLastEditorRaw(requestParameters: ExcelConfigHeaderControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigHeaderControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigHeader/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigHeaderControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.excelConfigHeaderControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigHeaderControllerUpdateRaw(requestParameters: ExcelConfigHeaderControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigHeaderControllerUpdate.');
        }

        if (requestParameters.createUpdateExcelConfigHeaderDto === null || requestParameters.createUpdateExcelConfigHeaderDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelConfigHeaderDto','Required parameter requestParameters.createUpdateExcelConfigHeaderDto was null or undefined when calling excelConfigHeaderControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigHeader/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelConfigHeaderDtoToJSON(requestParameters.createUpdateExcelConfigHeaderDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async excelConfigHeaderControllerUpdate(id: string, createUpdateExcelConfigHeaderDto: CreateUpdateExcelConfigHeaderDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.excelConfigHeaderControllerUpdateRaw({ id: id, createUpdateExcelConfigHeaderDto: createUpdateExcelConfigHeaderDto }, initOverrides);
    }

    /**
     */
    async excelConfigPositionControllerCreateRaw(requestParameters: ExcelConfigPositionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigPositionDto>> {
        if (requestParameters.createUpdateExcelConfigPositionDto === null || requestParameters.createUpdateExcelConfigPositionDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelConfigPositionDto','Required parameter requestParameters.createUpdateExcelConfigPositionDto was null or undefined when calling excelConfigPositionControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigPosition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelConfigPositionDtoToJSON(requestParameters.createUpdateExcelConfigPositionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigPositionDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigPositionControllerCreate(createUpdateExcelConfigPositionDto: CreateUpdateExcelConfigPositionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigPositionDto> {
        const response = await this.excelConfigPositionControllerCreateRaw({ createUpdateExcelConfigPositionDto: createUpdateExcelConfigPositionDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigPositionControllerGetRaw(requestParameters: ExcelConfigPositionControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigPositionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigPositionControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigPosition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigPositionDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigPositionControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigPositionDto> {
        const response = await this.excelConfigPositionControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigPositionControllerGetAllByConfigRaw(requestParameters: ExcelConfigPositionControllerGetAllByConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExcelConfigPositionDto>>> {
        if (requestParameters.excelConfigId === null || requestParameters.excelConfigId === undefined) {
            throw new runtime.RequiredError('excelConfigId','Required parameter requestParameters.excelConfigId was null or undefined when calling excelConfigPositionControllerGetAllByConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.excelConfigId !== undefined) {
            queryParameters['excelConfigId'] = requestParameters.excelConfigId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigPosition/search/byConfig`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExcelConfigPositionDtoFromJSON));
    }

    /**
     */
    async excelConfigPositionControllerGetAllByConfig(excelConfigId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExcelConfigPositionDto>> {
        const response = await this.excelConfigPositionControllerGetAllByConfigRaw({ excelConfigId: excelConfigId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigPositionControllerGetLastEditorRaw(requestParameters: ExcelConfigPositionControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigPositionControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigPosition/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigPositionControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.excelConfigPositionControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigPositionControllerGetSimilarPositionWithParamsRaw(requestParameters: ExcelConfigPositionControllerGetSimilarPositionWithParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelConfigPositionDto>> {
        if (requestParameters.positionId === null || requestParameters.positionId === undefined) {
            throw new runtime.RequiredError('positionId','Required parameter requestParameters.positionId was null or undefined when calling excelConfigPositionControllerGetSimilarPositionWithParams.');
        }

        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling excelConfigPositionControllerGetSimilarPositionWithParams.');
        }

        const queryParameters: any = {};

        if (requestParameters.positionId !== undefined) {
            queryParameters['positionId'] = requestParameters.positionId;
        }

        if (requestParameters.configId !== undefined) {
            queryParameters['configId'] = requestParameters.configId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigPosition/search/byPosition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelConfigPositionDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelConfigPositionControllerGetSimilarPositionWithParams(positionId: string, configId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelConfigPositionDto> {
        const response = await this.excelConfigPositionControllerGetSimilarPositionWithParamsRaw({ positionId: positionId, configId: configId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelConfigPositionControllerUpdateRaw(requestParameters: ExcelConfigPositionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling excelConfigPositionControllerUpdate.');
        }

        if (requestParameters.createUpdateExcelConfigPositionDto === null || requestParameters.createUpdateExcelConfigPositionDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelConfigPositionDto','Required parameter requestParameters.createUpdateExcelConfigPositionDto was null or undefined when calling excelConfigPositionControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excelConfigPosition/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelConfigPositionDtoToJSON(requestParameters.createUpdateExcelConfigPositionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async excelConfigPositionControllerUpdate(id: string, createUpdateExcelConfigPositionDto: CreateUpdateExcelConfigPositionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.excelConfigPositionControllerUpdateRaw({ id: id, createUpdateExcelConfigPositionDto: createUpdateExcelConfigPositionDto }, initOverrides);
    }

    /**
     */
    async excelControllerGetAggregatedDataRaw(requestParameters: ExcelControllerGetAggregatedDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExcelRowDto>>> {
        if (requestParameters.getExcelDataDto === null || requestParameters.getExcelDataDto === undefined) {
            throw new runtime.RequiredError('getExcelDataDto','Required parameter requestParameters.getExcelDataDto was null or undefined when calling excelControllerGetAggregatedData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excel/getAggregatedData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetExcelDataDtoToJSON(requestParameters.getExcelDataDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExcelRowDtoFromJSON));
    }

    /**
     */
    async excelControllerGetAggregatedData(getExcelDataDto: GetExcelDataDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExcelRowDto>> {
        const response = await this.excelControllerGetAggregatedDataRaw({ getExcelDataDto: getExcelDataDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelControllerGetDataFromExcelRaw(requestParameters: ExcelControllerGetDataFromExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelDataDto>> {
        if (requestParameters.createUpdateExcelDto === null || requestParameters.createUpdateExcelDto === undefined) {
            throw new runtime.RequiredError('createUpdateExcelDto','Required parameter requestParameters.createUpdateExcelDto was null or undefined when calling excelControllerGetDataFromExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excel/getDataFromExcel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExcelDtoToJSON(requestParameters.createUpdateExcelDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelDataDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelControllerGetDataFromExcel(createUpdateExcelDto: CreateUpdateExcelDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelDataDto> {
        const response = await this.excelControllerGetDataFromExcelRaw({ createUpdateExcelDto: createUpdateExcelDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelControllerGetExcelAndPokDataRaw(requestParameters: ExcelControllerGetExcelAndPokDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelAndPokDataDto>> {
        if (requestParameters.getExcelDataDto === null || requestParameters.getExcelDataDto === undefined) {
            throw new runtime.RequiredError('getExcelDataDto','Required parameter requestParameters.getExcelDataDto was null or undefined when calling excelControllerGetExcelAndPokData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excel/getExcelAndPokData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetExcelDataDtoToJSON(requestParameters.getExcelDataDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelAndPokDataDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelControllerGetExcelAndPokData(getExcelDataDto: GetExcelDataDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelAndPokDataDto> {
        const response = await this.excelControllerGetExcelAndPokDataRaw({ getExcelDataDto: getExcelDataDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelControllerImportExcelDataRaw(requestParameters: ExcelControllerImportExcelDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetResultsDto>> {
        if (requestParameters.getExcelAndPokDataDto === null || requestParameters.getExcelAndPokDataDto === undefined) {
            throw new runtime.RequiredError('getExcelAndPokDataDto','Required parameter requestParameters.getExcelAndPokDataDto was null or undefined when calling excelControllerImportExcelData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excel/importExcelData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetExcelAndPokDataDtoToJSON(requestParameters.getExcelAndPokDataDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetResultsDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelControllerImportExcelData(getExcelAndPokDataDto: GetExcelAndPokDataDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetResultsDto> {
        const response = await this.excelControllerImportExcelDataRaw({ getExcelAndPokDataDto: getExcelAndPokDataDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async excelControllerRecheckDataRaw(requestParameters: ExcelControllerRecheckDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExcelDataDto>> {
        if (requestParameters.aggregate === null || requestParameters.aggregate === undefined) {
            throw new runtime.RequiredError('aggregate','Required parameter requestParameters.aggregate was null or undefined when calling excelControllerRecheckData.');
        }

        if (requestParameters.getExcelDataDto === null || requestParameters.getExcelDataDto === undefined) {
            throw new runtime.RequiredError('getExcelDataDto','Required parameter requestParameters.getExcelDataDto was null or undefined when calling excelControllerRecheckData.');
        }

        const queryParameters: any = {};

        if (requestParameters.aggregate !== undefined) {
            queryParameters['aggregate'] = requestParameters.aggregate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/excel/recheckData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetExcelDataDtoToJSON(requestParameters.getExcelDataDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExcelDataDtoFromJSON(jsonValue));
    }

    /**
     */
    async excelControllerRecheckData(aggregate: boolean, getExcelDataDto: GetExcelDataDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExcelDataDto> {
        const response = await this.excelControllerRecheckDataRaw({ aggregate: aggregate, getExcelDataDto: getExcelDataDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangeApiControllerGetExchangeRateRaw(requestParameters: ExchangeApiControllerGetExchangeRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangeDto>> {
        if (requestParameters.isoCode === null || requestParameters.isoCode === undefined) {
            throw new runtime.RequiredError('isoCode','Required parameter requestParameters.isoCode was null or undefined when calling exchangeApiControllerGetExchangeRate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling exchangeApiControllerGetExchangeRate.');
        }

        const queryParameters: any = {};

        if (requestParameters.isoCode !== undefined) {
            queryParameters['isoCode'] = requestParameters.isoCode;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exchangeApi/exchangeRate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeDtoFromJSON(jsonValue));
    }

    /**
     */
    async exchangeApiControllerGetExchangeRate(isoCode: string, date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangeDto> {
        const response = await this.exchangeApiControllerGetExchangeRateRaw({ isoCode: isoCode, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async gusApiControllerGetByNipRaw(requestParameters: GusApiControllerGetByNipRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GusClientDto>> {
        if (requestParameters.nip === null || requestParameters.nip === undefined) {
            throw new runtime.RequiredError('nip','Required parameter requestParameters.nip was null or undefined when calling gusApiControllerGetByNip.');
        }

        const queryParameters: any = {};

        if (requestParameters.nip !== undefined) {
            queryParameters['nip'] = requestParameters.nip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/gusApi/nip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GusClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async gusApiControllerGetByNip(nip: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GusClientDto> {
        const response = await this.gusApiControllerGetByNipRaw({ nip: nip }, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoiceEstimateItemControllerCreateManyRaw(requestParameters: InvoiceEstimateItemControllerCreateManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetInvoiceEstimateItemDto>>> {
        if (requestParameters.createUpdateInvoiceEstimateItemsDto === null || requestParameters.createUpdateInvoiceEstimateItemsDto === undefined) {
            throw new runtime.RequiredError('createUpdateInvoiceEstimateItemsDto','Required parameter requestParameters.createUpdateInvoiceEstimateItemsDto was null or undefined when calling invoiceEstimateItemControllerCreateMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoiceEstimateItem/createEstimateItemsPositions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateInvoiceEstimateItemsDtoToJSON(requestParameters.createUpdateInvoiceEstimateItemsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInvoiceEstimateItemDtoFromJSON));
    }

    /**
     */
    async invoiceEstimateItemControllerCreateMany(createUpdateInvoiceEstimateItemsDto: CreateUpdateInvoiceEstimateItemsDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetInvoiceEstimateItemDto>> {
        const response = await this.invoiceEstimateItemControllerCreateManyRaw({ createUpdateInvoiceEstimateItemsDto: createUpdateInvoiceEstimateItemsDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobConfigControllerCreateRaw(requestParameters: JobConfigControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetJobConfigDto>> {
        if (requestParameters.createUpdateJobConfigDto === null || requestParameters.createUpdateJobConfigDto === undefined) {
            throw new runtime.RequiredError('createUpdateJobConfigDto','Required parameter requestParameters.createUpdateJobConfigDto was null or undefined when calling jobConfigControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJobConfigDtoToJSON(requestParameters.createUpdateJobConfigDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJobConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async jobConfigControllerCreate(createUpdateJobConfigDto: CreateUpdateJobConfigDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetJobConfigDto> {
        const response = await this.jobConfigControllerCreateRaw({ createUpdateJobConfigDto: createUpdateJobConfigDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobConfigControllerGetRaw(requestParameters: JobConfigControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetJobConfigDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jobConfigControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJobConfigDtoFromJSON(jsonValue));
    }

    /**
     */
    async jobConfigControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetJobConfigDto> {
        const response = await this.jobConfigControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobConfigControllerGetAllByPortionRaw(requestParameters: JobConfigControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetJobConfigDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling jobConfigControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling jobConfigControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling jobConfigControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling jobConfigControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling jobConfigControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJobConfigDtoFromJSON));
    }

    /**
     */
    async jobConfigControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetJobConfigDto>> {
        const response = await this.jobConfigControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobConfigControllerGetAllCountRaw(requestParameters: JobConfigControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling jobConfigControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async jobConfigControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.jobConfigControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobConfigControllerGetLastEditorRaw(requestParameters: JobConfigControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jobConfigControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async jobConfigControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.jobConfigControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobConfigControllerSynchronizeAllJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config/synchronize/allJobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async jobConfigControllerSynchronizeAllJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.jobConfigControllerSynchronizeAllJobsRaw(initOverrides);
    }

    /**
     */
    async jobConfigControllerUpdateRaw(requestParameters: JobConfigControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jobConfigControllerUpdate.');
        }

        if (requestParameters.createUpdateJobConfigDto === null || requestParameters.createUpdateJobConfigDto === undefined) {
            throw new runtime.RequiredError('createUpdateJobConfigDto','Required parameter requestParameters.createUpdateJobConfigDto was null or undefined when calling jobConfigControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/job_config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJobConfigDtoToJSON(requestParameters.createUpdateJobConfigDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async jobConfigControllerUpdate(id: string, createUpdateJobConfigDto: CreateUpdateJobConfigDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.jobConfigControllerUpdateRaw({ id: id, createUpdateJobConfigDto: createUpdateJobConfigDto }, initOverrides);
    }

    /**
     */
    async loginControllerLoginRaw(requestParameters: LoginControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenDto>> {
        if (requestParameters.loginDto === null || requestParameters.loginDto === undefined) {
            throw new runtime.RequiredError('loginDto','Required parameter requestParameters.loginDto was null or undefined when calling loginControllerLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDtoToJSON(requestParameters.loginDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async loginControllerLogin(loginDto: LoginDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenDto> {
        const response = await this.loginControllerLoginRaw({ loginDto: loginDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginControllerLoginWithGoogleRaw(requestParameters: LoginControllerLoginWithGoogleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenDto>> {
        if (requestParameters.loginWithGoogleDto === null || requestParameters.loginWithGoogleDto === undefined) {
            throw new runtime.RequiredError('loginWithGoogleDto','Required parameter requestParameters.loginWithGoogleDto was null or undefined when calling loginControllerLoginWithGoogle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login/loginWithGoogle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithGoogleDtoToJSON(requestParameters.loginWithGoogleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async loginControllerLoginWithGoogle(loginWithGoogleDto: LoginWithGoogleDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenDto> {
        const response = await this.loginControllerLoginWithGoogleRaw({ loginWithGoogleDto: loginWithGoogleDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginControllerRefreshTokenRaw(requestParameters: LoginControllerRefreshTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenDto>> {
        if (requestParameters.refreshToken === null || requestParameters.refreshToken === undefined) {
            throw new runtime.RequiredError('refreshToken','Required parameter requestParameters.refreshToken was null or undefined when calling loginControllerRefreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.refreshToken !== undefined && requestParameters.refreshToken !== null) {
            headerParameters['refreshToken'] = String(requestParameters.refreshToken);
        }

        const response = await this.request({
            path: `/login/refreshTokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async loginControllerRefreshToken(refreshToken: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenDto> {
        const response = await this.loginControllerRefreshTokenRaw({ refreshToken: refreshToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async memoControllerCreatePrivateMemoRaw(requestParameters: MemoControllerCreatePrivateMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMemoDto>> {
        if (requestParameters.createPrivateMemoDto === null || requestParameters.createPrivateMemoDto === undefined) {
            throw new runtime.RequiredError('createPrivateMemoDto','Required parameter requestParameters.createPrivateMemoDto was null or undefined when calling memoControllerCreatePrivateMemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memos/createPrivateMemo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePrivateMemoDtoToJSON(requestParameters.createPrivateMemoDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerCreatePrivateMemo(createPrivateMemoDto: CreatePrivateMemoDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMemoDto> {
        const response = await this.memoControllerCreatePrivateMemoRaw({ createPrivateMemoDto: createPrivateMemoDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async memoControllerCreatePublicMemoRaw(requestParameters: MemoControllerCreatePublicMemoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMemoDto>> {
        if (requestParameters.createPublicMemoDto === null || requestParameters.createPublicMemoDto === undefined) {
            throw new runtime.RequiredError('createPublicMemoDto','Required parameter requestParameters.createPublicMemoDto was null or undefined when calling memoControllerCreatePublicMemo.');
        }

        const queryParameters: any = {};

        if (requestParameters.employeeId !== undefined) {
            queryParameters['employeeId'] = requestParameters.employeeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memos/createPublicMemo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePublicMemoDtoToJSON(requestParameters.createPublicMemoDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMemoDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerCreatePublicMemo(createPublicMemoDto: CreatePublicMemoDto, employeeId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMemoDto> {
        const response = await this.memoControllerCreatePublicMemoRaw({ createPublicMemoDto: createPublicMemoDto, employeeId: employeeId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async memoControllerGetSubjectThreadStatsRaw(requestParameters: MemoControllerGetSubjectThreadStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSubjectThreadStatsDto>> {
        if (requestParameters.subjectType === null || requestParameters.subjectType === undefined) {
            throw new runtime.RequiredError('subjectType','Required parameter requestParameters.subjectType was null or undefined when calling memoControllerGetSubjectThreadStats.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memoControllerGetSubjectThreadStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memos/subjectThreadStats/{subjectType}/{id}`.replace(`{${"subjectType"}}`, encodeURIComponent(String(requestParameters.subjectType))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSubjectThreadStatsDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerGetSubjectThreadStats(subjectType: MemoControllerGetSubjectThreadStatsSubjectTypeEnum, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSubjectThreadStatsDto> {
        const response = await this.memoControllerGetSubjectThreadStatsRaw({ subjectType: subjectType, id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async memoControllerGetThreadMemosRaw(requestParameters: MemoControllerGetThreadMemosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetMemoDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memoControllerGetThreadMemos.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling memoControllerGetThreadMemos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memos/thread/{id}/{count}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"count"}}`, encodeURIComponent(String(requestParameters.count))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetMemoDtoFromJSON));
    }

    /**
     */
    async memoControllerGetThreadMemos(id: string, count: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetMemoDto>> {
        const response = await this.memoControllerGetThreadMemosRaw({ id: id, count: count }, initOverrides);
        return await response.value();
    }

    /**
     */
    async memoControllerGetThreadsForUserRaw(requestParameters: MemoControllerGetThreadsForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetThreadDto>>> {
        if (requestParameters.threadsCount === null || requestParameters.threadsCount === undefined) {
            throw new runtime.RequiredError('threadsCount','Required parameter requestParameters.threadsCount was null or undefined when calling memoControllerGetThreadsForUser.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling memoControllerGetThreadsForUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memos/threads/forUser/{threadsCount}/{filter}`.replace(`{${"threadsCount"}}`, encodeURIComponent(String(requestParameters.threadsCount))).replace(`{${"filter"}}`, encodeURIComponent(String(requestParameters.filter))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetThreadDtoFromJSON));
    }

    /**
     */
    async memoControllerGetThreadsForUser(threadsCount: number, filter: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetThreadDto>> {
        const response = await this.memoControllerGetThreadsForUserRaw({ threadsCount: threadsCount, filter: filter }, initOverrides);
        return await response.value();
    }

    /**
     */
    async memoControllerGetThreadsForUserStatsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThreadsStatsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/memos/threadsStats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThreadsStatsDtoFromJSON(jsonValue));
    }

    /**
     */
    async memoControllerGetThreadsForUserStats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThreadsStatsDto> {
        const response = await this.memoControllerGetThreadsForUserStatsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerAcceptRaw(requestParameters: OrderControllerAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerAccept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderControllerAccept(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.orderControllerAcceptRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerCreateRaw(requestParameters: OrderControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderDto>> {
        if (requestParameters.createUpdateOrderDto === null || requestParameters.createUpdateOrderDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrderDto','Required parameter requestParameters.createUpdateOrderDto was null or undefined when calling orderControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrderDtoToJSON(requestParameters.createUpdateOrderDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerCreate(createUpdateOrderDto: CreateUpdateOrderDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderDto> {
        const response = await this.orderControllerCreateRaw({ createUpdateOrderDto: createUpdateOrderDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerDeactivateRaw(requestParameters: OrderControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async orderControllerGetRaw(requestParameters: OrderControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderDto> {
        const response = await this.orderControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllByPortionRaw(requestParameters: OrderControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderDtoFromJSON));
    }

    /**
     */
    async orderControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderDto>> {
        const response = await this.orderControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllByPortionCompletedRaw(requestParameters: OrderControllerGetAllByPortionCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllByPortionCompleted.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/completedByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderDtoFromJSON));
    }

    /**
     */
    async orderControllerGetAllByPortionCompleted(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderDto>> {
        const response = await this.orderControllerGetAllByPortionCompletedRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllByPortionForProjectRaw(requestParameters: OrderControllerGetAllByPortionForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderControllerGetAllByPortionForProject.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderControllerGetAllByPortionForProject.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderControllerGetAllByPortionForProject.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderControllerGetAllByPortionForProject.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllByPortionForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.resultProjectId !== undefined) {
            queryParameters['resultProjectId'] = requestParameters.resultProjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/forProjectByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderDtoFromJSON));
    }

    /**
     */
    async orderControllerGetAllByPortionForProject(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, projectId?: string, resultProjectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderDto>> {
        const response = await this.orderControllerGetAllByPortionForProjectRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, projectId: projectId, resultProjectId: resultProjectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllByPortionWaitingRaw(requestParameters: OrderControllerGetAllByPortionWaitingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllByPortionWaiting.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/waitingByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderDtoFromJSON));
    }

    /**
     */
    async orderControllerGetAllByPortionWaiting(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderDto>> {
        const response = await this.orderControllerGetAllByPortionWaitingRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllCountRaw(requestParameters: OrderControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllCountCompletedRaw(requestParameters: OrderControllerGetAllCountCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllCountCompleted.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/completedCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderControllerGetAllCountCompleted(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderControllerGetAllCountCompletedRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllCountForProjectRaw(requestParameters: OrderControllerGetAllCountForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllCountForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.resultProjectId !== undefined) {
            queryParameters['resultProjectId'] = requestParameters.resultProjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/countForProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderControllerGetAllCountForProject(filterText: string, filterColumns?: object, projectId?: string, resultProjectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderControllerGetAllCountForProjectRaw({ filterText: filterText, filterColumns: filterColumns, projectId: projectId, resultProjectId: resultProjectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetAllCountWaitingRaw(requestParameters: OrderControllerGetAllCountWaitingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderControllerGetAllCountWaiting.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/search/waitingCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderControllerGetAllCountWaiting(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderControllerGetAllCountWaitingRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerGetLastEditorRaw(requestParameters: OrderControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.orderControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderControllerRejectRaw(requestParameters: OrderControllerRejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerReject.');
        }

        if (requestParameters.rejectOrderDto === null || requestParameters.rejectOrderDto === undefined) {
            throw new runtime.RequiredError('rejectOrderDto','Required parameter requestParameters.rejectOrderDto was null or undefined when calling orderControllerReject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RejectOrderDtoToJSON(requestParameters.rejectOrderDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderControllerReject(id: string, rejectOrderDto: RejectOrderDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderControllerRejectRaw({ id: id, rejectOrderDto: rejectOrderDto }, initOverrides);
    }

    /**
     */
    async orderControllerUpdateRaw(requestParameters: OrderControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerUpdate.');
        }

        if (requestParameters.createUpdateOrderDto === null || requestParameters.createUpdateOrderDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrderDto','Required parameter requestParameters.createUpdateOrderDto was null or undefined when calling orderControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrderDtoToJSON(requestParameters.createUpdateOrderDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderControllerUpdate(id: string, createUpdateOrderDto: CreateUpdateOrderDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderControllerUpdateRaw({ id: id, createUpdateOrderDto: createUpdateOrderDto }, initOverrides);
    }

    /**
     */
    async orderExecutionControllerAcceptRaw(requestParameters: OrderExecutionControllerAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderExecutionControllerAccept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderExecutionControllerAccept(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderExecutionControllerAcceptRaw({ id: id }, initOverrides);
    }

    /**
     */
    async orderExecutionControllerCreateRaw(requestParameters: OrderExecutionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderExecutionDto>> {
        if (requestParameters.createUpdateOrderExecutionDto === null || requestParameters.createUpdateOrderExecutionDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrderExecutionDto','Required parameter requestParameters.createUpdateOrderExecutionDto was null or undefined when calling orderExecutionControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrderExecutionDtoToJSON(requestParameters.createUpdateOrderExecutionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderExecutionDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderExecutionControllerCreate(createUpdateOrderExecutionDto: CreateUpdateOrderExecutionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderExecutionDto> {
        const response = await this.orderExecutionControllerCreateRaw({ createUpdateOrderExecutionDto: createUpdateOrderExecutionDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerDeactivateRaw(requestParameters: OrderExecutionControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderExecutionControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderExecutionControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderExecutionControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async orderExecutionControllerGetRaw(requestParameters: OrderExecutionControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderExecutionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderExecutionControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderExecutionDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderExecutionControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderExecutionDto> {
        const response = await this.orderExecutionControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionRaw(requestParameters: OrderExecutionControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderExecutionDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderExecutionControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderExecutionControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderExecutionControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderExecutionControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderExecutionDtoFromJSON));
    }

    /**
     */
    async orderExecutionControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderExecutionDto>> {
        const response = await this.orderExecutionControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionCompletedRaw(requestParameters: OrderExecutionControllerGetAllByPortionCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderExecutionDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderExecutionControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderExecutionControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderExecutionControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderExecutionControllerGetAllByPortionCompleted.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllByPortionCompleted.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/completedByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderExecutionDtoFromJSON));
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionCompleted(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderExecutionDto>> {
        const response = await this.orderExecutionControllerGetAllByPortionCompletedRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionForProjectRaw(requestParameters: OrderExecutionControllerGetAllByPortionForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderExecutionDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderExecutionControllerGetAllByPortionForProject.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderExecutionControllerGetAllByPortionForProject.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderExecutionControllerGetAllByPortionForProject.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderExecutionControllerGetAllByPortionForProject.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllByPortionForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.resultProjectId !== undefined) {
            queryParameters['resultProjectId'] = requestParameters.resultProjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/forProjectByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderExecutionDtoFromJSON));
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionForProject(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, projectId?: string, resultProjectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderExecutionDto>> {
        const response = await this.orderExecutionControllerGetAllByPortionForProjectRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, projectId: projectId, resultProjectId: resultProjectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionWaitingRaw(requestParameters: OrderExecutionControllerGetAllByPortionWaitingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrderExecutionDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling orderExecutionControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling orderExecutionControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling orderExecutionControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling orderExecutionControllerGetAllByPortionWaiting.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllByPortionWaiting.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/waitingByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrderExecutionDtoFromJSON));
    }

    /**
     */
    async orderExecutionControllerGetAllByPortionWaiting(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrderExecutionDto>> {
        const response = await this.orderExecutionControllerGetAllByPortionWaitingRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllCountRaw(requestParameters: OrderExecutionControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderExecutionControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderExecutionControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllCountCompletedRaw(requestParameters: OrderExecutionControllerGetAllCountCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllCountCompleted.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/completedCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderExecutionControllerGetAllCountCompleted(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderExecutionControllerGetAllCountCompletedRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllCountForProjectRaw(requestParameters: OrderExecutionControllerGetAllCountForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllCountForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.resultProjectId !== undefined) {
            queryParameters['resultProjectId'] = requestParameters.resultProjectId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/countForProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderExecutionControllerGetAllCountForProject(filterText: string, filterColumns?: object, projectId?: string, resultProjectId?: string, status?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderExecutionControllerGetAllCountForProjectRaw({ filterText: filterText, filterColumns: filterColumns, projectId: projectId, resultProjectId: resultProjectId, status: status }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetAllCountWaitingRaw(requestParameters: OrderExecutionControllerGetAllCountWaitingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling orderExecutionControllerGetAllCountWaiting.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/search/waitingCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async orderExecutionControllerGetAllCountWaiting(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.orderExecutionControllerGetAllCountWaitingRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerGetLastEditorRaw(requestParameters: OrderExecutionControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderExecutionControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderExecutionControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.orderExecutionControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderExecutionControllerRejectRaw(requestParameters: OrderExecutionControllerRejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderExecutionControllerReject.');
        }

        if (requestParameters.rejectOrderDto === null || requestParameters.rejectOrderDto === undefined) {
            throw new runtime.RequiredError('rejectOrderDto','Required parameter requestParameters.rejectOrderDto was null or undefined when calling orderExecutionControllerReject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RejectOrderDtoToJSON(requestParameters.rejectOrderDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderExecutionControllerReject(id: string, rejectOrderDto: RejectOrderDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderExecutionControllerRejectRaw({ id: id, rejectOrderDto: rejectOrderDto }, initOverrides);
    }

    /**
     */
    async orderExecutionControllerUpdateRaw(requestParameters: OrderExecutionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderExecutionControllerUpdate.');
        }

        if (requestParameters.createUpdateOrderExecutionDto === null || requestParameters.createUpdateOrderExecutionDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrderExecutionDto','Required parameter requestParameters.createUpdateOrderExecutionDto was null or undefined when calling orderExecutionControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecution/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrderExecutionDtoToJSON(requestParameters.createUpdateOrderExecutionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderExecutionControllerUpdate(id: string, createUpdateOrderExecutionDto: CreateUpdateOrderExecutionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderExecutionControllerUpdateRaw({ id: id, createUpdateOrderExecutionDto: createUpdateOrderExecutionDto }, initOverrides);
    }

    /**
     */
    async orderExecutionPositionControllerCreateRaw(requestParameters: OrderExecutionPositionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrderExecutionPositionDto>> {
        if (requestParameters.createUpdateOrderExecutionPositionDto === null || requestParameters.createUpdateOrderExecutionPositionDto === undefined) {
            throw new runtime.RequiredError('createUpdateOrderExecutionPositionDto','Required parameter requestParameters.createUpdateOrderExecutionPositionDto was null or undefined when calling orderExecutionPositionControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orderExecutionPosition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOrderExecutionPositionDtoToJSON(requestParameters.createUpdateOrderExecutionPositionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderExecutionPositionDtoFromJSON(jsonValue));
    }

    /**
     */
    async orderExecutionPositionControllerCreate(createUpdateOrderExecutionPositionDto: CreateUpdateOrderExecutionPositionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrderExecutionPositionDto> {
        const response = await this.orderExecutionPositionControllerCreateRaw({ createUpdateOrderExecutionPositionDto: createUpdateOrderExecutionPositionDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerCreateRaw(requestParameters: PackageInvoicesXMLControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPackageInvoicesXMLDto>> {
        if (requestParameters.createUpdatePackageInvoicesXMLDto === null || requestParameters.createUpdatePackageInvoicesXMLDto === undefined) {
            throw new runtime.RequiredError('createUpdatePackageInvoicesXMLDto','Required parameter requestParameters.createUpdatePackageInvoicesXMLDto was null or undefined when calling packageInvoicesXMLControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePackageInvoicesXMLDtoToJSON(requestParameters.createUpdatePackageInvoicesXMLDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPackageInvoicesXMLDtoFromJSON(jsonValue));
    }

    /**
     */
    async packageInvoicesXMLControllerCreate(createUpdatePackageInvoicesXMLDto: CreateUpdatePackageInvoicesXMLDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPackageInvoicesXMLDto> {
        const response = await this.packageInvoicesXMLControllerCreateRaw({ createUpdatePackageInvoicesXMLDto: createUpdatePackageInvoicesXMLDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerGetRaw(requestParameters: PackageInvoicesXMLControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPackageInvoicesXMLDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling packageInvoicesXMLControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPackageInvoicesXMLDtoFromJSON(jsonValue));
    }

    /**
     */
    async packageInvoicesXMLControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPackageInvoicesXMLDto> {
        const response = await this.packageInvoicesXMLControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerGetAllByTypeByPortionRaw(requestParameters: PackageInvoicesXMLControllerGetAllByTypeByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPackageInvoicesXMLDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeByPortion.');
        }

        if (requestParameters.packageType === null || requestParameters.packageType === undefined) {
            throw new runtime.RequiredError('packageType','Required parameter requestParameters.packageType was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.packageType !== undefined) {
            queryParameters['packageType'] = requestParameters.packageType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML/search/byTypeByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPackageInvoicesXMLDtoFromJSON));
    }

    /**
     */
    async packageInvoicesXMLControllerGetAllByTypeByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, packageType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPackageInvoicesXMLDto>> {
        const response = await this.packageInvoicesXMLControllerGetAllByTypeByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, packageType: packageType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerGetAllByTypeCountRaw(requestParameters: PackageInvoicesXMLControllerGetAllByTypeCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeCount.');
        }

        if (requestParameters.packageType === null || requestParameters.packageType === undefined) {
            throw new runtime.RequiredError('packageType','Required parameter requestParameters.packageType was null or undefined when calling packageInvoicesXMLControllerGetAllByTypeCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.packageType !== undefined) {
            queryParameters['packageType'] = requestParameters.packageType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML/search/byTypeCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async packageInvoicesXMLControllerGetAllByTypeCount(filterText: string, packageType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.packageInvoicesXMLControllerGetAllByTypeCountRaw({ filterText: filterText, packageType: packageType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerGetXMLFromPackageXMLRaw(requestParameters: PackageInvoicesXMLControllerGetXMLFromPackageXMLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.packageXML === null || requestParameters.packageXML === undefined) {
            throw new runtime.RequiredError('packageXML','Required parameter requestParameters.packageXML was null or undefined when calling packageInvoicesXMLControllerGetXMLFromPackageXML.');
        }

        const queryParameters: any = {};

        if (requestParameters.packageXML !== undefined) {
            queryParameters['packageXML'] = requestParameters.packageXML;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML/xml/packageFVZ`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async packageInvoicesXMLControllerGetXMLFromPackageXML(packageXML: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.packageInvoicesXMLControllerGetXMLFromPackageXMLRaw({ packageXML: packageXML }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerGetZipBySalesInvoicesIdsRaw(requestParameters: PackageInvoicesXMLControllerGetZipBySalesInvoicesIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectNumber === null || requestParameters.projectNumber === undefined) {
            throw new runtime.RequiredError('projectNumber','Required parameter requestParameters.projectNumber was null or undefined when calling packageInvoicesXMLControllerGetZipBySalesInvoicesIds.');
        }

        if (requestParameters.salesInvoicesIds === null || requestParameters.salesInvoicesIds === undefined) {
            throw new runtime.RequiredError('salesInvoicesIds','Required parameter requestParameters.salesInvoicesIds was null or undefined when calling packageInvoicesXMLControllerGetZipBySalesInvoicesIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectNumber !== undefined) {
            queryParameters['projectNumber'] = requestParameters.projectNumber;
        }

        if (requestParameters.salesInvoicesIds) {
            queryParameters['salesInvoicesIds'] = requestParameters.salesInvoicesIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML/zip/bySalesInvoicesIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async packageInvoicesXMLControllerGetZipBySalesInvoicesIds(projectNumber: string, salesInvoicesIds: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.packageInvoicesXMLControllerGetZipBySalesInvoicesIdsRaw({ projectNumber: projectNumber, salesInvoicesIds: salesInvoicesIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async packageInvoicesXMLControllerGetZipFromPackageXMLRaw(requestParameters: PackageInvoicesXMLControllerGetZipFromPackageXMLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.packageXML === null || requestParameters.packageXML === undefined) {
            throw new runtime.RequiredError('packageXML','Required parameter requestParameters.packageXML was null or undefined when calling packageInvoicesXMLControllerGetZipFromPackageXML.');
        }

        if (requestParameters.copy === null || requestParameters.copy === undefined) {
            throw new runtime.RequiredError('copy','Required parameter requestParameters.copy was null or undefined when calling packageInvoicesXMLControllerGetZipFromPackageXML.');
        }

        const queryParameters: any = {};

        if (requestParameters.packageXML !== undefined) {
            queryParameters['packageXML'] = requestParameters.packageXML;
        }

        if (requestParameters.copy !== undefined) {
            queryParameters['copy'] = requestParameters.copy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/packageInvoicesXML/zip/packageFVZ`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async packageInvoicesXMLControllerGetZipFromPackageXML(packageXML: string, copy: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.packageInvoicesXMLControllerGetZipFromPackageXMLRaw({ packageXML: packageXML, copy: copy }, initOverrides);
        return await response.value();
    }

    /**
     */
    async pdfPrinterControllerGetZipOrderExecutionsByProjectRaw(requestParameters: PdfPrinterControllerGetZipOrderExecutionsByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling pdfPrinterControllerGetZipOrderExecutionsByProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/zip/OrderExecutions/ByProjectIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async pdfPrinterControllerGetZipOrderExecutionsByProject(projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.pdfPrinterControllerGetZipOrderExecutionsByProjectRaw({ projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async pdfPrinterControllerInvoiceDataPrintoutRaw(requestParameters: PdfPrinterControllerInvoiceDataPrintoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerInvoiceDataPrintout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/invoiceDataPrintout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerInvoiceDataPrintout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pdfPrinterControllerInvoiceDataPrintoutRaw({ id: id }, initOverrides);
    }

    /**
     */
    async pdfPrinterControllerPrintInvoiceRaw(requestParameters: PdfPrinterControllerPrintInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerPrintInvoice.');
        }

        if (requestParameters.duplicate === null || requestParameters.duplicate === undefined) {
            throw new runtime.RequiredError('duplicate','Required parameter requestParameters.duplicate was null or undefined when calling pdfPrinterControllerPrintInvoice.');
        }

        if (requestParameters.copy === null || requestParameters.copy === undefined) {
            throw new runtime.RequiredError('copy','Required parameter requestParameters.copy was null or undefined when calling pdfPrinterControllerPrintInvoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.duplicate !== undefined) {
            queryParameters['duplicate'] = requestParameters.duplicate;
        }

        if (requestParameters.copy !== undefined) {
            queryParameters['copy'] = requestParameters.copy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printInvoice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintInvoice(id: string, duplicate: boolean, copy: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pdfPrinterControllerPrintInvoiceRaw({ id: id, duplicate: duplicate, copy: copy }, initOverrides);
    }

    /**
     */
    async pdfPrinterControllerPrintOrderExecutionRaw(requestParameters: PdfPrinterControllerPrintOrderExecutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerPrintOrderExecution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printOrderExecution/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintOrderExecution(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pdfPrinterControllerPrintOrderExecutionRaw({ id: id }, initOverrides);
    }

    /**
     */
    async positionControllerCreateRaw(requestParameters: PositionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPositionDto>> {
        if (requestParameters.createUpdatePositionDto === null || requestParameters.createUpdatePositionDto === undefined) {
            throw new runtime.RequiredError('createUpdatePositionDto','Required parameter requestParameters.createUpdatePositionDto was null or undefined when calling positionControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePositionDtoToJSON(requestParameters.createUpdatePositionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPositionDtoFromJSON(jsonValue));
    }

    /**
     */
    async positionControllerCreate(createUpdatePositionDto: CreateUpdatePositionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPositionDto> {
        const response = await this.positionControllerCreateRaw({ createUpdatePositionDto: createUpdatePositionDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetRaw(requestParameters: PositionControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPositionExtendedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling positionControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPositionExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async positionControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPositionExtendedDto> {
        const response = await this.positionControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetAllByPortionForClientRaw(requestParameters: PositionControllerGetAllByPortionForClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPositionExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling positionControllerGetAllByPortionForClient.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling positionControllerGetAllByPortionForClient.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling positionControllerGetAllByPortionForClient.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling positionControllerGetAllByPortionForClient.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling positionControllerGetAllByPortionForClient.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/searchForClient/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPositionExtendedDtoFromJSON));
    }

    /**
     */
    async positionControllerGetAllByPortionForClient(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, clientId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPositionExtendedDto>> {
        const response = await this.positionControllerGetAllByPortionForClientRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, clientId: clientId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetAllByProfileIdTeamIdAndNameRaw(requestParameters: PositionControllerGetAllByProfileIdTeamIdAndNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPositionDto>>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling positionControllerGetAllByProfileIdTeamIdAndName.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling positionControllerGetAllByProfileIdTeamIdAndName.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling positionControllerGetAllByProfileIdTeamIdAndName.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling positionControllerGetAllByProfileIdTeamIdAndName.');
        }

        const queryParameters: any = {};

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/search/byProfileIdTeamIdAndName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPositionDtoFromJSON));
    }

    /**
     */
    async positionControllerGetAllByProfileIdTeamIdAndName(profileId: string, teamId: string, pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPositionDto>> {
        const response = await this.positionControllerGetAllByProfileIdTeamIdAndNameRaw({ profileId: profileId, teamId: teamId, pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetAllByTeamIdsCommissionAndNameRaw(requestParameters: PositionControllerGetAllByTeamIdsCommissionAndNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPositionDto>>> {
        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling positionControllerGetAllByTeamIdsCommissionAndName.');
        }

        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling positionControllerGetAllByTeamIdsCommissionAndName.');
        }

        if (requestParameters.commission === null || requestParameters.commission === undefined) {
            throw new runtime.RequiredError('commission','Required parameter requestParameters.commission was null or undefined when calling positionControllerGetAllByTeamIdsCommissionAndName.');
        }

        if (requestParameters.budgetId === null || requestParameters.budgetId === undefined) {
            throw new runtime.RequiredError('budgetId','Required parameter requestParameters.budgetId was null or undefined when calling positionControllerGetAllByTeamIdsCommissionAndName.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling positionControllerGetAllByTeamIdsCommissionAndName.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling positionControllerGetAllByTeamIdsCommissionAndName.');
        }

        const queryParameters: any = {};

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        if (requestParameters.commission !== undefined) {
            queryParameters['commission'] = requestParameters.commission;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/search/byTeamIdCommissionAndName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPositionDtoFromJSON));
    }

    /**
     */
    async positionControllerGetAllByTeamIdsCommissionAndName(teamIds: Array<string>, profileId: string, commission: boolean, budgetId: string, pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPositionDto>> {
        const response = await this.positionControllerGetAllByTeamIdsCommissionAndNameRaw({ teamIds: teamIds, profileId: profileId, commission: commission, budgetId: budgetId, pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetAllClientsByProjectIdRaw(requestParameters: PositionControllerGetAllClientsByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetClientDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling positionControllerGetAllClientsByProjectId.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling positionControllerGetAllClientsByProjectId.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling positionControllerGetAllClientsByProjectId.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/search/byProjectId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetClientDtoFromJSON));
    }

    /**
     */
    async positionControllerGetAllClientsByProjectId(projectId: string, pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetClientDto>> {
        const response = await this.positionControllerGetAllClientsByProjectIdRaw({ projectId: projectId, pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetAllCountForClientRaw(requestParameters: PositionControllerGetAllCountForClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling positionControllerGetAllCountForClient.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/searchForClient/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async positionControllerGetAllCountForClient(filterText: string, filterColumns?: object, clientId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.positionControllerGetAllCountForClientRaw({ filterText: filterText, filterColumns: filterColumns, clientId: clientId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetDistinctGroupNamesRaw(requestParameters: PositionControllerGetDistinctGroupNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling positionControllerGetDistinctGroupNames.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling positionControllerGetDistinctGroupNames.');
        }

        const queryParameters: any = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/search/group_names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async positionControllerGetDistinctGroupNames(pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.positionControllerGetDistinctGroupNamesRaw({ pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerGetLastEditorRaw(requestParameters: PositionControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling positionControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async positionControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.positionControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async positionControllerUpdateRaw(requestParameters: PositionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling positionControllerUpdate.');
        }

        if (requestParameters.createUpdatePositionDto === null || requestParameters.createUpdatePositionDto === undefined) {
            throw new runtime.RequiredError('createUpdatePositionDto','Required parameter requestParameters.createUpdatePositionDto was null or undefined when calling positionControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/position/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePositionDtoToJSON(requestParameters.createUpdatePositionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async positionControllerUpdate(id: string, createUpdatePositionDto: CreateUpdatePositionDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.positionControllerUpdateRaw({ id: id, createUpdatePositionDto: createUpdatePositionDto }, initOverrides);
    }

    /**
     */
    async profileCategoryControllerCreateRaw(requestParameters: ProfileCategoryControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProfileCategoryDto>> {
        if (requestParameters.createUpdateProfileCategoryDto === null || requestParameters.createUpdateProfileCategoryDto === undefined) {
            throw new runtime.RequiredError('createUpdateProfileCategoryDto','Required parameter requestParameters.createUpdateProfileCategoryDto was null or undefined when calling profileCategoryControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProfileCategoryDtoToJSON(requestParameters.createUpdateProfileCategoryDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileCategoryControllerCreate(createUpdateProfileCategoryDto: CreateUpdateProfileCategoryDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProfileCategoryDto> {
        const response = await this.profileCategoryControllerCreateRaw({ createUpdateProfileCategoryDto: createUpdateProfileCategoryDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileCategoryControllerGetRaw(requestParameters: ProfileCategoryControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProfileCategoryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileCategoryControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileCategoryDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileCategoryControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProfileCategoryDto> {
        const response = await this.profileCategoryControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileCategoryControllerGetAllByPortionRaw(requestParameters: ProfileCategoryControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProfileCategoryDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling profileCategoryControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling profileCategoryControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling profileCategoryControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling profileCategoryControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling profileCategoryControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProfileCategoryDtoFromJSON));
    }

    /**
     */
    async profileCategoryControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProfileCategoryDto>> {
        const response = await this.profileCategoryControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileCategoryControllerGetAllByProfileRaw(requestParameters: ProfileCategoryControllerGetAllByProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProfileCategoryDto>>> {
        if (requestParameters.profile === null || requestParameters.profile === undefined) {
            throw new runtime.RequiredError('profile','Required parameter requestParameters.profile was null or undefined when calling profileCategoryControllerGetAllByProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.profile !== undefined) {
            queryParameters['profile'] = requestParameters.profile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory/search/byProfile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProfileCategoryDtoFromJSON));
    }

    /**
     */
    async profileCategoryControllerGetAllByProfile(profile: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProfileCategoryDto>> {
        const response = await this.profileCategoryControllerGetAllByProfileRaw({ profile: profile }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileCategoryControllerGetAllCountRaw(requestParameters: ProfileCategoryControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async profileCategoryControllerGetAllCount(filterText?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.profileCategoryControllerGetAllCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileCategoryControllerGetLastEditorRaw(requestParameters: ProfileCategoryControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileCategoryControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileCategoryControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.profileCategoryControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileCategoryControllerUpdateRaw(requestParameters: ProfileCategoryControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileCategoryControllerUpdate.');
        }

        if (requestParameters.createUpdateProfileCategoryDto === null || requestParameters.createUpdateProfileCategoryDto === undefined) {
            throw new runtime.RequiredError('createUpdateProfileCategoryDto','Required parameter requestParameters.createUpdateProfileCategoryDto was null or undefined when calling profileCategoryControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profileCategory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProfileCategoryDtoToJSON(requestParameters.createUpdateProfileCategoryDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profileCategoryControllerUpdate(id: string, createUpdateProfileCategoryDto: CreateUpdateProfileCategoryDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.profileCategoryControllerUpdateRaw({ id: id, createUpdateProfileCategoryDto: createUpdateProfileCategoryDto }, initOverrides);
    }

    /**
     */
    async profileControllerCreateRaw(requestParameters: ProfileControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProfileDto>> {
        if (requestParameters.createUpdateProfileDto === null || requestParameters.createUpdateProfileDto === undefined) {
            throw new runtime.RequiredError('createUpdateProfileDto','Required parameter requestParameters.createUpdateProfileDto was null or undefined when calling profileControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProfileDtoToJSON(requestParameters.createUpdateProfileDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerCreate(createUpdateProfileDto: CreateUpdateProfileDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProfileDto> {
        const response = await this.profileControllerCreateRaw({ createUpdateProfileDto: createUpdateProfileDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetRaw(requestParameters: ProfileControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExtendedProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExtendedProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExtendedProfileDto> {
        const response = await this.profileControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetAllByCompanyIdsTeamIdsRaw(requestParameters: ProfileControllerGetAllByCompanyIdsTeamIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExtendedProfileDto>>> {
        if (requestParameters.companyIds === null || requestParameters.companyIds === undefined) {
            throw new runtime.RequiredError('companyIds','Required parameter requestParameters.companyIds was null or undefined when calling profileControllerGetAllByCompanyIdsTeamIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/search/byCompanyIdsTeamIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExtendedProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetAllByCompanyIdsTeamIds(companyIds: Array<string>, teamIds?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExtendedProfileDto>> {
        const response = await this.profileControllerGetAllByCompanyIdsTeamIdsRaw({ companyIds: companyIds, teamIds: teamIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetAllByCompanyIdsTeamIdsAuthRaw(requestParameters: ProfileControllerGetAllByCompanyIdsTeamIdsAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExtendedProfileDto>>> {
        if (requestParameters.companyIds === null || requestParameters.companyIds === undefined) {
            throw new runtime.RequiredError('companyIds','Required parameter requestParameters.companyIds was null or undefined when calling profileControllerGetAllByCompanyIdsTeamIdsAuth.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/search/byCompanyIdsTeamIdsAuth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExtendedProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetAllByCompanyIdsTeamIdsAuth(companyIds: Array<string>, teamIds?: Array<string>, budgetId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExtendedProfileDto>> {
        const response = await this.profileControllerGetAllByCompanyIdsTeamIdsAuthRaw({ companyIds: companyIds, teamIds: teamIds, budgetId: budgetId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetAllByPortionRaw(requestParameters: ProfileControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetExtendedProfileDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling profileControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling profileControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling profileControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling profileControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling profileControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExtendedProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetExtendedProfileDto>> {
        const response = await this.profileControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetAllCountRaw(requestParameters: ProfileControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async profileControllerGetAllCount(filterText?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.profileControllerGetAllCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerGetLastEditorRaw(requestParameters: ProfileControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.profileControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async profileControllerUpdateRaw(requestParameters: ProfileControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerUpdate.');
        }

        if (requestParameters.createUpdateProfileDto === null || requestParameters.createUpdateProfileDto === undefined) {
            throw new runtime.RequiredError('createUpdateProfileDto','Required parameter requestParameters.createUpdateProfileDto was null or undefined when calling profileControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProfileDtoToJSON(requestParameters.createUpdateProfileDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profileControllerUpdate(id: string, createUpdateProfileDto: CreateUpdateProfileDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.profileControllerUpdateRaw({ id: id, createUpdateProfileDto: createUpdateProfileDto }, initOverrides);
    }

    /**
     */
    async projectControllerApplicationRaw(requestParameters: ProjectControllerApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerApplication.');
        }

        if (requestParameters.createUpdateProjectApplicationDto === null || requestParameters.createUpdateProjectApplicationDto === undefined) {
            throw new runtime.RequiredError('createUpdateProjectApplicationDto','Required parameter requestParameters.createUpdateProjectApplicationDto was null or undefined when calling projectControllerApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/application`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProjectApplicationDtoToJSON(requestParameters.createUpdateProjectApplicationDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectControllerApplication(id: string, createUpdateProjectApplicationDto: CreateUpdateProjectApplicationDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectControllerApplicationRaw({ id: id, createUpdateProjectApplicationDto: createUpdateProjectApplicationDto }, initOverrides);
    }

    /**
     */
    async projectControllerApplicationAcceptRaw(requestParameters: ProjectControllerApplicationAcceptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerApplicationAccept.');
        }

        if (requestParameters.projectApplicationApproveDto === null || requestParameters.projectApplicationApproveDto === undefined) {
            throw new runtime.RequiredError('projectApplicationApproveDto','Required parameter requestParameters.projectApplicationApproveDto was null or undefined when calling projectControllerApplicationAccept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/applicationAccept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectApplicationApproveDtoToJSON(requestParameters.projectApplicationApproveDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectControllerApplicationAccept(id: string, projectApplicationApproveDto: ProjectApplicationApproveDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectControllerApplicationAcceptRaw({ id: id, projectApplicationApproveDto: projectApplicationApproveDto }, initOverrides);
    }

    /**
     */
    async projectControllerApplicationRejectRaw(requestParameters: ProjectControllerApplicationRejectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerApplicationReject.');
        }

        if (requestParameters.projectApplicationRejectDto === null || requestParameters.projectApplicationRejectDto === undefined) {
            throw new runtime.RequiredError('projectApplicationRejectDto','Required parameter requestParameters.projectApplicationRejectDto was null or undefined when calling projectControllerApplicationReject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/applicationReject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectApplicationRejectDtoToJSON(requestParameters.projectApplicationRejectDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectControllerApplicationReject(id: string, projectApplicationRejectDto: ProjectApplicationRejectDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectControllerApplicationRejectRaw({ id: id, projectApplicationRejectDto: projectApplicationRejectDto }, initOverrides);
    }

    /**
     */
    async projectControllerCloneRaw(requestParameters: ProjectControllerCloneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectDto>> {
        if (requestParameters.cloneProjectDto === null || requestParameters.cloneProjectDto === undefined) {
            throw new runtime.RequiredError('cloneProjectDto','Required parameter requestParameters.cloneProjectDto was null or undefined when calling projectControllerClone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/clone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloneProjectDtoToJSON(requestParameters.cloneProjectDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerClone(cloneProjectDto: CloneProjectDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectDto> {
        const response = await this.projectControllerCloneRaw({ cloneProjectDto: cloneProjectDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerCreateRaw(requestParameters: ProjectControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectDto>> {
        if (requestParameters.createUpdateProjectWithTeamDto === null || requestParameters.createUpdateProjectWithTeamDto === undefined) {
            throw new runtime.RequiredError('createUpdateProjectWithTeamDto','Required parameter requestParameters.createUpdateProjectWithTeamDto was null or undefined when calling projectControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProjectWithTeamDtoToJSON(requestParameters.createUpdateProjectWithTeamDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerCreate(createUpdateProjectWithTeamDto: CreateUpdateProjectWithTeamDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectDto> {
        const response = await this.projectControllerCreateRaw({ createUpdateProjectWithTeamDto: createUpdateProjectWithTeamDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetRaw(requestParameters: ProjectControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectDto> {
        const response = await this.projectControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllApplicationByPortionRaw(requestParameters: ProjectControllerGetAllApplicationByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetAllApplicationByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetAllApplicationByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetAllApplicationByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetAllApplicationByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllApplicationByPortion.');
        }

        if (requestParameters.applicationType === null || requestParameters.applicationType === undefined) {
            throw new runtime.RequiredError('applicationType','Required parameter requestParameters.applicationType was null or undefined when calling projectControllerGetAllApplicationByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.applicationType !== undefined) {
            queryParameters['applicationType'] = requestParameters.applicationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/applicationByCompanyPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllApplicationByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, applicationType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerGetAllApplicationByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, applicationType: applicationType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllApplicationCountRaw(requestParameters: ProjectControllerGetAllApplicationCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllApplicationCount.');
        }

        if (requestParameters.applicationType === null || requestParameters.applicationType === undefined) {
            throw new runtime.RequiredError('applicationType','Required parameter requestParameters.applicationType was null or undefined when calling projectControllerGetAllApplicationCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.applicationType !== undefined) {
            queryParameters['applicationType'] = requestParameters.applicationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/applicationByCompanyCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetAllApplicationCount(filterText: string, applicationType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetAllApplicationCountRaw({ filterText: filterText, applicationType: applicationType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllApplicationWaitingByPortionRaw(requestParameters: ProjectControllerGetAllApplicationWaitingByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetAllApplicationWaitingByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetAllApplicationWaitingByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetAllApplicationWaitingByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetAllApplicationWaitingByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllApplicationWaitingByPortion.');
        }

        if (requestParameters.applicationType === null || requestParameters.applicationType === undefined) {
            throw new runtime.RequiredError('applicationType','Required parameter requestParameters.applicationType was null or undefined when calling projectControllerGetAllApplicationWaitingByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.applicationType !== undefined) {
            queryParameters['applicationType'] = requestParameters.applicationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/applicationWaitingByCompanyPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllApplicationWaitingByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, applicationType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerGetAllApplicationWaitingByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, applicationType: applicationType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllApplicationWaitingCountRaw(requestParameters: ProjectControllerGetAllApplicationWaitingCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllApplicationWaitingCount.');
        }

        if (requestParameters.applicationType === null || requestParameters.applicationType === undefined) {
            throw new runtime.RequiredError('applicationType','Required parameter requestParameters.applicationType was null or undefined when calling projectControllerGetAllApplicationWaitingCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.applicationType !== undefined) {
            queryParameters['applicationType'] = requestParameters.applicationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/applicationWaitingByCompanyCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetAllApplicationWaitingCount(filterText: string, applicationType: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetAllApplicationWaitingCountRaw({ filterText: filterText, applicationType: applicationType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllByCompanyRaw(requestParameters: ProjectControllerGetAllByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling projectControllerGetAllByCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/byCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllByCompany(limit: number, filterText?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerGetAllByCompanyRaw({ limit: limit, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllByPortionRaw(requestParameters: ProjectControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/byCompanyPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllCountRaw(requestParameters: ProjectControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/byCompanyCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortionRaw(requestParameters: ProjectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectWithPurchaseInvoicesDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.idsToExclude) {
            queryParameters['idsToExclude'] = requestParameters.idsToExclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/byClientAndCompanyForPurchaseInvoicePage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectWithPurchaseInvoicesDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, clientId: string, idsToExclude?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectWithPurchaseInvoicesDto>> {
        const response = await this.projectControllerGetAllForPurchaseInvoiceByClientAndCompanyByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, clientId: clientId, idsToExclude: idsToExclude }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllForPurchaseInvoiceByClientAndCompanyCountRaw(requestParameters: ProjectControllerGetAllForPurchaseInvoiceByClientAndCompanyCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyCount.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByClientAndCompanyCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.idsToExclude) {
            queryParameters['idsToExclude'] = requestParameters.idsToExclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/byClientAndCompanyForPurchaseInvoiceCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetAllForPurchaseInvoiceByClientAndCompanyCount(filterText: string, clientId: string, idsToExclude?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetAllForPurchaseInvoiceByClientAndCompanyCountRaw({ filterText: filterText, clientId: clientId, idsToExclude: idsToExclude }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllForPurchaseInvoiceByPurchaseInvoiceRaw(requestParameters: ProjectControllerGetAllForPurchaseInvoiceByPurchaseInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectExtendedDto>>> {
        if (requestParameters.purchaseInvoiceId === null || requestParameters.purchaseInvoiceId === undefined) {
            throw new runtime.RequiredError('purchaseInvoiceId','Required parameter requestParameters.purchaseInvoiceId was null or undefined when calling projectControllerGetAllForPurchaseInvoiceByPurchaseInvoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.purchaseInvoiceId !== undefined) {
            queryParameters['purchaseInvoiceId'] = requestParameters.purchaseInvoiceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/byPurchaseInvoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectExtendedDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllForPurchaseInvoiceByPurchaseInvoice(purchaseInvoiceId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectExtendedDto>> {
        const response = await this.projectControllerGetAllForPurchaseInvoiceByPurchaseInvoiceRaw({ purchaseInvoiceId: purchaseInvoiceId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllReadyToCorrectionByPortionRaw(requestParameters: ProjectControllerGetAllReadyToCorrectionByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetAllReadyToCorrectionByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetAllReadyToCorrectionByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetAllReadyToCorrectionByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetAllReadyToCorrectionByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllReadyToCorrectionByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/readyToCorrectionByCompanyPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllReadyToCorrectionByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerGetAllReadyToCorrectionByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllReadyToCorrectionCountRaw(requestParameters: ProjectControllerGetAllReadyToCorrectionCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllReadyToCorrectionCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/readyToCorrectionByCompanyCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetAllReadyToCorrectionCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetAllReadyToCorrectionCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllReadyToInvoiceByPortionRaw(requestParameters: ProjectControllerGetAllReadyToInvoiceByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetAllReadyToInvoiceByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetAllReadyToInvoiceByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetAllReadyToInvoiceByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetAllReadyToInvoiceByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllReadyToInvoiceByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.idsToExclude) {
            queryParameters['idsToExclude'] = requestParameters.idsToExclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/readyToInvoiceByCompanyPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerGetAllReadyToInvoiceByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, clientId?: string, budgetId?: string, idsToExclude?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerGetAllReadyToInvoiceByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, clientId: clientId, budgetId: budgetId, idsToExclude: idsToExclude }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetAllReadyToInvoiceCountRaw(requestParameters: ProjectControllerGetAllReadyToInvoiceCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetAllReadyToInvoiceCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.idsToExclude) {
            queryParameters['idsToExclude'] = requestParameters.idsToExclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/readyToInvoiceByCompanyCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetAllReadyToInvoiceCount(filterText: string, clientId?: string, budgetId?: string, idsToExclude?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetAllReadyToInvoiceCountRaw({ filterText: filterText, clientId: clientId, budgetId: budgetId, idsToExclude: idsToExclude }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetExtendedRaw(requestParameters: ProjectControllerGetExtendedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectExtendedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerGetExtended.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/extended`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerGetExtended(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectExtendedDto> {
        const response = await this.projectControllerGetExtendedRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetForFinanceByPortionRaw(requestParameters: ProjectControllerGetForFinanceByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectForFinanceDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling projectControllerGetForFinanceByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling projectControllerGetForFinanceByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling projectControllerGetForFinanceByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling projectControllerGetForFinanceByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetForFinanceByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/forFinancePage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectForFinanceDtoFromJSON));
    }

    /**
     */
    async projectControllerGetForFinanceByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, dateFrom?: Date, dateTo?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectForFinanceDto>> {
        const response = await this.projectControllerGetForFinanceByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, dateFrom: dateFrom, dateTo: dateTo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetForFinanceCountRaw(requestParameters: ProjectControllerGetForFinanceCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetForFinanceCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/forFinanceCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projectControllerGetForFinanceCount(filterText: string, filterColumns?: object, dateFrom?: Date, dateTo?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.projectControllerGetForFinanceCountRaw({ filterText: filterText, filterColumns: filterColumns, dateFrom: dateFrom, dateTo: dateTo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetLastEditorRaw(requestParameters: ProjectControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.projectControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetSumForFinanceRaw(requestParameters: ProjectControllerGetSumForFinanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectForFinanceDto>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling projectControllerGetSumForFinance.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/sumForFinance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectForFinanceDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerGetSumForFinance(filterText: string, filterColumns?: object, dateFrom?: Date, dateTo?: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectForFinanceDto> {
        const response = await this.projectControllerGetSumForFinanceRaw({ filterText: filterText, filterColumns: filterColumns, dateFrom: dateFrom, dateTo: dateTo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetWarningsWithFVZRaw(requestParameters: ProjectControllerGetWarningsWithFVZRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling projectControllerGetWarningsWithFVZ.');
        }

        if (requestParameters.purchaseInvoicesIds === null || requestParameters.purchaseInvoicesIds === undefined) {
            throw new runtime.RequiredError('purchaseInvoicesIds','Required parameter requestParameters.purchaseInvoicesIds was null or undefined when calling projectControllerGetWarningsWithFVZ.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.purchaseInvoicesIds) {
            queryParameters['purchaseInvoicesIds'] = requestParameters.purchaseInvoicesIds;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = (requestParameters.month as any).toISOString();
        }

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/warningsWithDownloadFVZ/forProjectAndMonth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async projectControllerGetWarningsWithFVZ(projectId: string, purchaseInvoicesIds: Array<string>, month?: Date, profileId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.projectControllerGetWarningsWithFVZRaw({ projectId: projectId, purchaseInvoicesIds: purchaseInvoicesIds, month: month, profileId: profileId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerGetWithParentRaw(requestParameters: ProjectControllerGetWithParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProjectDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerGetWithParent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/withParent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerGetWithParent(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProjectDto> {
        const response = await this.projectControllerGetWithParentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerInternalProjectsByPurchaserForCompanyAndTeamsRaw(requestParameters: ProjectControllerInternalProjectsByPurchaserForCompanyAndTeamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProjectDto>>> {
        if (requestParameters.purchaserId === null || requestParameters.purchaserId === undefined) {
            throw new runtime.RequiredError('purchaserId','Required parameter requestParameters.purchaserId was null or undefined when calling projectControllerInternalProjectsByPurchaserForCompanyAndTeams.');
        }

        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling projectControllerInternalProjectsByPurchaserForCompanyAndTeams.');
        }

        const queryParameters: any = {};

        if (requestParameters.purchaserId !== undefined) {
            queryParameters['purchaserId'] = requestParameters.purchaserId;
        }

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/search/internalProjectsByPurchaserForCompanyAndTeams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProjectDtoFromJSON));
    }

    /**
     */
    async projectControllerInternalProjectsByPurchaserForCompanyAndTeams(purchaserId: string, teamIds: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProjectDto>> {
        const response = await this.projectControllerInternalProjectsByPurchaserForCompanyAndTeamsRaw({ purchaserId: purchaserId, teamIds: teamIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerIsDelayedRaw(requestParameters: ProjectControllerIsDelayedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDelayedProjectDto>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling projectControllerIsDelayed.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/check/isDelayed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDelayedProjectDtoFromJSON(jsonValue));
    }

    /**
     */
    async projectControllerIsDelayed(date: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDelayedProjectDto> {
        const response = await this.projectControllerIsDelayedRaw({ date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerUniqueServicesNamesRaw(requestParameters: ProjectControllerUniqueServicesNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerUniqueServicesNames.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/uniqueServicesNames/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async projectControllerUniqueServicesNames(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.projectControllerUniqueServicesNamesRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectControllerUpdateRaw(requestParameters: ProjectControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectControllerUpdate.');
        }

        if (requestParameters.createUpdateProjectWithTeamDto === null || requestParameters.createUpdateProjectWithTeamDto === undefined) {
            throw new runtime.RequiredError('createUpdateProjectWithTeamDto','Required parameter requestParameters.createUpdateProjectWithTeamDto was null or undefined when calling projectControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProjectWithTeamDtoToJSON(requestParameters.createUpdateProjectWithTeamDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async projectControllerUpdate(id: string, createUpdateProjectWithTeamDto: CreateUpdateProjectWithTeamDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectControllerUpdateRaw({ id: id, createUpdateProjectWithTeamDto: createUpdateProjectWithTeamDto }, initOverrides);
    }

    /**
     */
    async purchaseInvoiceControllerApproveRaw(requestParameters: PurchaseInvoiceControllerApproveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerApprove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseInvoiceControllerApprove(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseInvoiceControllerApproveRaw({ id: id }, initOverrides);
    }

    /**
     */
    async purchaseInvoiceControllerCreateRaw(requestParameters: PurchaseInvoiceControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaseInvoiceDto>> {
        if (requestParameters.createUpdatePurchaseInvoiceDto === null || requestParameters.createUpdatePurchaseInvoiceDto === undefined) {
            throw new runtime.RequiredError('createUpdatePurchaseInvoiceDto','Required parameter requestParameters.createUpdatePurchaseInvoiceDto was null or undefined when calling purchaseInvoiceControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePurchaseInvoiceDtoToJSON(requestParameters.createUpdatePurchaseInvoiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaseInvoiceDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerCreate(createUpdatePurchaseInvoiceDto: CreateUpdatePurchaseInvoiceDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaseInvoiceDto> {
        const response = await this.purchaseInvoiceControllerCreateRaw({ createUpdatePurchaseInvoiceDto: createUpdatePurchaseInvoiceDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerDeactivateRaw(requestParameters: PurchaseInvoiceControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseInvoiceControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseInvoiceControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async purchaseInvoiceControllerFindByEstimateItemRaw(requestParameters: PurchaseInvoiceControllerFindByEstimateItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceForItem>>> {
        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling purchaseInvoiceControllerFindByEstimateItem.');
        }

        const queryParameters: any = {};

        if (requestParameters.itemId !== undefined) {
            queryParameters['itemId'] = requestParameters.itemId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/byEstimateItem`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceForItemFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerFindByEstimateItem(itemId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceForItem>> {
        const response = await this.purchaseInvoiceControllerFindByEstimateItemRaw({ itemId: itemId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerFindByProjectRaw(requestParameters: PurchaseInvoiceControllerFindByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceWithAttachmentIdDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling purchaseInvoiceControllerFindByProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = (requestParameters.month as any).toISOString();
        }

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/byProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceWithAttachmentIdDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerFindByProject(projectId: string, month?: Date, profileId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceWithAttachmentIdDto>> {
        const response = await this.purchaseInvoiceControllerFindByProjectRaw({ projectId: projectId, month: month, profileId: profileId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetRaw(requestParameters: PurchaseInvoiceControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaseInvoiceExtendedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaseInvoiceExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaseInvoiceExtendedDto> {
        const response = await this.purchaseInvoiceControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllByPackageFVZByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllByPackageFVZByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllByPackageFVZByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllByPackageFVZByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllByPackageFVZByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllByPackageFVZByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllByPackageFVZByPortion.');
        }

        if (requestParameters.packageFVZId === null || requestParameters.packageFVZId === undefined) {
            throw new runtime.RequiredError('packageFVZId','Required parameter requestParameters.packageFVZId was null or undefined when calling purchaseInvoiceControllerGetAllByPackageFVZByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.packageFVZId !== undefined) {
            queryParameters['packageFVZId'] = requestParameters.packageFVZId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/byPackageFVZ/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllByPackageFVZByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, packageFVZId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllByPackageFVZByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, packageFVZId: packageFVZId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllCountRaw(requestParameters: PurchaseInvoiceControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetAllCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllForMediaplanerByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllForMediaplanerByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllForMediaplanerByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllForMediaplanerByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllForMediaplanerByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllForMediaplanerByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllForMediaplanerByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/forMediaplaner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllForMediaplanerByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllForMediaplanerByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllForMediaplanerCountRaw(requestParameters: PurchaseInvoiceControllerGetAllForMediaplanerCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllForMediaplanerCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countForMediaplaner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetAllForMediaplanerCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetAllForMediaplanerCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllGeneratedByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllGeneratedByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/generatedByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllGeneratedByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllGeneratedByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllGeneratedSettledByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllGeneratedSettledByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedSettledByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedSettledByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedSettledByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedSettledByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllGeneratedSettledByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/generatedSettledByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllGeneratedSettledByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllGeneratedSettledByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllReadyToSentByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllReadyToSentByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllReadyToSentByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllReadyToSentByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllReadyToSentByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllReadyToSentByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllReadyToSentByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.isRepeat !== undefined) {
            queryParameters['isRepeat'] = requestParameters.isRepeat;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/readyToSentByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllReadyToSentByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, isRepeat?: boolean, type?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllReadyToSentByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, isRepeat: isRepeat, type: type }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllTvForMediaplanerByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllTvForMediaplanerByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllTvForMediaplanerByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllTvForMediaplanerByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllTvForMediaplanerByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllTvForMediaplanerByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllTvForMediaplanerByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/tvForMediaplaner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllTvForMediaplanerByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllTvForMediaplanerByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllTvForMediaplanerCountRaw(requestParameters: PurchaseInvoiceControllerGetAllTvForMediaplanerCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllTvForMediaplanerCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countTv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetAllTvForMediaplanerCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetAllTvForMediaplanerCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledTvByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllUnsettledTvByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledTvByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledTvByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledTvByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledTvByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledTvByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/unsettledTv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledTvByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllUnsettledTvByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledTvCountRaw(requestParameters: PurchaseInvoiceControllerGetAllUnsettledTvCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledTvCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countUnsettledTv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledTvCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetAllUnsettledTvCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllUnsettledWithProjectsByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/byPageUnsettledWithProjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllUnsettledWithProjectsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledWithProjectsCountRaw(requestParameters: PurchaseInvoiceControllerGetAllUnsettledWithProjectsCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllUnsettledWithProjectsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countUnsettledWithProjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetAllUnsettledWithProjectsCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetAllUnsettledWithProjectsCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllWithTeamsByPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllWithTeamsByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllWithTeamsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllWithTeamsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllWithTeamsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllWithTeamsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllWithTeamsByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/byPageWithTeams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllWithTeamsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllWithTeamsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllWithTeamsCountRaw(requestParameters: PurchaseInvoiceControllerGetAllWithTeamsCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllWithTeamsCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countWithTeams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetAllWithTeamsCount(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetAllWithTeamsCountRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortionRaw(requestParameters: PurchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/withoutTeamsAndProjects/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceExtendedDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceExtendedDto>> {
        const response = await this.purchaseInvoiceControllerGetAllWithoutTeamsAndProjectsPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetCountByPackageFVZRaw(requestParameters: PurchaseInvoiceControllerGetCountByPackageFVZRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetCountByPackageFVZ.');
        }

        if (requestParameters.packageFVZId === null || requestParameters.packageFVZId === undefined) {
            throw new runtime.RequiredError('packageFVZId','Required parameter requestParameters.packageFVZId was null or undefined when calling purchaseInvoiceControllerGetCountByPackageFVZ.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.packageFVZId !== undefined) {
            queryParameters['packageFVZId'] = requestParameters.packageFVZId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countByPackageFVZ`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetCountByPackageFVZ(filterText: string, packageFVZId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetCountByPackageFVZRaw({ filterText: filterText, packageFVZId: packageFVZId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetCountGeneratedRaw(requestParameters: PurchaseInvoiceControllerGetCountGeneratedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetCountGenerated.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countGenerated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetCountGenerated(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetCountGeneratedRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetCountGeneratedSettledRaw(requestParameters: PurchaseInvoiceControllerGetCountGeneratedSettledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetCountGeneratedSettled.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countGeneratedSettled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetCountGeneratedSettled(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetCountGeneratedSettledRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetCountReadyToSentRaw(requestParameters: PurchaseInvoiceControllerGetCountReadyToSentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetCountReadyToSent.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.isRepeat !== undefined) {
            queryParameters['isRepeat'] = requestParameters.isRepeat;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countReadyToSent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetCountReadyToSent(filterText: string, filterColumns?: object, isRepeat?: boolean, type?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetCountReadyToSentRaw({ filterText: filterText, filterColumns: filterColumns, isRepeat: isRepeat, type: type }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetCountWithoutTeamsAndProjectsRaw(requestParameters: PurchaseInvoiceControllerGetCountWithoutTeamsAndProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetCountWithoutTeamsAndProjects.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/countWithoutTeamsAndProjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaseInvoiceControllerGetCountWithoutTeamsAndProjects(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaseInvoiceControllerGetCountWithoutTeamsAndProjectsRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetEstimateItemsRaw(requestParameters: PurchaseInvoiceControllerGetEstimateItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaseInvoiceEstimateItemDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerGetEstimateItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}/estimateItems`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaseInvoiceEstimateItemDtoFromJSON));
    }

    /**
     */
    async purchaseInvoiceControllerGetEstimateItems(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaseInvoiceEstimateItemDto>> {
        const response = await this.purchaseInvoiceControllerGetEstimateItemsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetLastEditorRaw(requestParameters: PurchaseInvoiceControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.purchaseInvoiceControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetLastEstimateItemEditorRaw(requestParameters: PurchaseInvoiceControllerGetLastEstimateItemEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerGetLastEstimateItemEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/lastEstimateItemEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerGetLastEstimateItemEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.purchaseInvoiceControllerGetLastEstimateItemEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoicesRaw(requestParameters: PurchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaseInvoiceExtendedDto>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoices.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/sumAllUnsettledTvForPurchaseInvoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaseInvoiceExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoices(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaseInvoiceExtendedDto> {
        const response = await this.purchaseInvoiceControllerGetSumAllUnsettledTvForPurchaseInvoicesRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoicesRaw(requestParameters: PurchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaseInvoiceExtendedDto>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoices.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/sumAllUnsettledWithProjectsForPurchaseInvoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaseInvoiceExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoices(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaseInvoiceExtendedDto> {
        const response = await this.purchaseInvoiceControllerGetSumAllUnsettledWithProjectsForPurchaseInvoicesRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoicesRaw(requestParameters: PurchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaseInvoiceExtendedDto>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoices.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/search/sumAllWithTeamsForPurchaseInvoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaseInvoiceExtendedDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoices(filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaseInvoiceExtendedDto> {
        const response = await this.purchaseInvoiceControllerGetSumAllWithTeamsForPurchaseInvoicesRaw({ filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerGetZipByPurchaseIdsRaw(requestParameters: PurchaseInvoiceControllerGetZipByPurchaseIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectNumber === null || requestParameters.projectNumber === undefined) {
            throw new runtime.RequiredError('projectNumber','Required parameter requestParameters.projectNumber was null or undefined when calling purchaseInvoiceControllerGetZipByPurchaseIds.');
        }

        if (requestParameters.purchaseInvoiceIds === null || requestParameters.purchaseInvoiceIds === undefined) {
            throw new runtime.RequiredError('purchaseInvoiceIds','Required parameter requestParameters.purchaseInvoiceIds was null or undefined when calling purchaseInvoiceControllerGetZipByPurchaseIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectNumber !== undefined) {
            queryParameters['projectNumber'] = requestParameters.projectNumber;
        }

        if (requestParameters.purchaseInvoiceIds) {
            queryParameters['purchaseInvoiceIds'] = requestParameters.purchaseInvoiceIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/zip/byPurchaseIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async purchaseInvoiceControllerGetZipByPurchaseIds(projectNumber: string, purchaseInvoiceIds: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.purchaseInvoiceControllerGetZipByPurchaseIdsRaw({ projectNumber: projectNumber, purchaseInvoiceIds: purchaseInvoiceIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaseInvoiceControllerUnApproveRaw(requestParameters: PurchaseInvoiceControllerUnApproveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerUnApprove.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}/unapprove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseInvoiceControllerUnApprove(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseInvoiceControllerUnApproveRaw({ id: id }, initOverrides);
    }

    /**
     */
    async purchaseInvoiceControllerUpdateRaw(requestParameters: PurchaseInvoiceControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerUpdate.');
        }

        if (requestParameters.createUpdatePurchaseInvoiceDto === null || requestParameters.createUpdatePurchaseInvoiceDto === undefined) {
            throw new runtime.RequiredError('createUpdatePurchaseInvoiceDto','Required parameter requestParameters.createUpdatePurchaseInvoiceDto was null or undefined when calling purchaseInvoiceControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePurchaseInvoiceDtoToJSON(requestParameters.createUpdatePurchaseInvoiceDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseInvoiceControllerUpdate(id: string, createUpdatePurchaseInvoiceDto: CreateUpdatePurchaseInvoiceDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseInvoiceControllerUpdateRaw({ id: id, createUpdatePurchaseInvoiceDto: createUpdatePurchaseInvoiceDto }, initOverrides);
    }

    /**
     */
    async purchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItemsRaw(requestParameters: PurchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItems.');
        }

        if (requestParameters.createUpdatePurchaseInvoiceEstimateItemsDto === null || requestParameters.createUpdatePurchaseInvoiceEstimateItemsDto === undefined) {
            throw new runtime.RequiredError('createUpdatePurchaseInvoiceEstimateItemsDto','Required parameter requestParameters.createUpdatePurchaseInvoiceEstimateItemsDto was null or undefined when calling purchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}/estimateItems`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePurchaseInvoiceEstimateItemsDtoToJSON(requestParameters.createUpdatePurchaseInvoiceEstimateItemsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItems(id: string, createUpdatePurchaseInvoiceEstimateItemsDto: CreateUpdatePurchaseInvoiceEstimateItemsDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseInvoiceControllerUpdatePurchaseInvoiceEstimateItemsRaw({ id: id, createUpdatePurchaseInvoiceEstimateItemsDto: createUpdatePurchaseInvoiceEstimateItemsDto }, initOverrides);
    }

    /**
     */
    async purchaseInvoiceControllerUpdateTeamsAndProjectsRaw(requestParameters: PurchaseInvoiceControllerUpdateTeamsAndProjectsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaseInvoiceControllerUpdateTeamsAndProjects.');
        }

        if (requestParameters.createUpdatePurchaseInvoiceTeamsAndProjectsDto === null || requestParameters.createUpdatePurchaseInvoiceTeamsAndProjectsDto === undefined) {
            throw new runtime.RequiredError('createUpdatePurchaseInvoiceTeamsAndProjectsDto','Required parameter requestParameters.createUpdatePurchaseInvoiceTeamsAndProjectsDto was null or undefined when calling purchaseInvoiceControllerUpdateTeamsAndProjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaseInvoice/{id}/teamsAndProjects`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePurchaseInvoiceTeamsAndProjectsDtoToJSON(requestParameters.createUpdatePurchaseInvoiceTeamsAndProjectsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaseInvoiceControllerUpdateTeamsAndProjects(id: string, createUpdatePurchaseInvoiceTeamsAndProjectsDto: CreateUpdatePurchaseInvoiceTeamsAndProjectsDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseInvoiceControllerUpdateTeamsAndProjectsRaw({ id: id, createUpdatePurchaseInvoiceTeamsAndProjectsDto: createUpdatePurchaseInvoiceTeamsAndProjectsDto }, initOverrides);
    }

    /**
     */
    async purchaserControllerCreateRaw(requestParameters: PurchaserControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaserDto>> {
        if (requestParameters.createUpdatePurchaserDto === null || requestParameters.createUpdatePurchaserDto === undefined) {
            throw new runtime.RequiredError('createUpdatePurchaserDto','Required parameter requestParameters.createUpdatePurchaserDto was null or undefined when calling purchaserControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePurchaserDtoToJSON(requestParameters.createUpdatePurchaserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaserDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaserControllerCreate(createUpdatePurchaserDto: CreateUpdatePurchaserDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaserDto> {
        const response = await this.purchaserControllerCreateRaw({ createUpdatePurchaserDto: createUpdatePurchaserDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetRaw(requestParameters: PurchaserControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPurchaserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaserControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPurchaserDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaserControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPurchaserDto> {
        const response = await this.purchaserControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetAllByPortionRaw(requestParameters: PurchaserControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaserDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling purchaserControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling purchaserControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling purchaserControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling purchaserControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaserControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/searchForClient/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaserDtoFromJSON));
    }

    /**
     */
    async purchaserControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, clientId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaserDto>> {
        const response = await this.purchaserControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, clientId: clientId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetAllByTeamIdsRaw(requestParameters: PurchaserControllerGetAllByTeamIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaserDto>>> {
        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling purchaserControllerGetAllByTeamIds.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling purchaserControllerGetAllByTeamIds.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling purchaserControllerGetAllByTeamIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.parentProjectId !== undefined) {
            queryParameters['parentProjectId'] = requestParameters.parentProjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/search/byTeamIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaserDtoFromJSON));
    }

    /**
     */
    async purchaserControllerGetAllByTeamIds(teamIds: Array<string>, pattern: string, limit: number, parentProjectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaserDto>> {
        const response = await this.purchaserControllerGetAllByTeamIdsRaw({ teamIds: teamIds, pattern: pattern, limit: limit, parentProjectId: parentProjectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetAllByTeamIdsAndBudgetRaw(requestParameters: PurchaserControllerGetAllByTeamIdsAndBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaserDto>>> {
        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling purchaserControllerGetAllByTeamIdsAndBudget.');
        }

        if (requestParameters.budgetId === null || requestParameters.budgetId === undefined) {
            throw new runtime.RequiredError('budgetId','Required parameter requestParameters.budgetId was null or undefined when calling purchaserControllerGetAllByTeamIdsAndBudget.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling purchaserControllerGetAllByTeamIdsAndBudget.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling purchaserControllerGetAllByTeamIdsAndBudget.');
        }

        const queryParameters: any = {};

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.parentProjectId !== undefined) {
            queryParameters['parentProjectId'] = requestParameters.parentProjectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/search/byTeamIdsAndBudget`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaserDtoFromJSON));
    }

    /**
     */
    async purchaserControllerGetAllByTeamIdsAndBudget(teamIds: Array<string>, budgetId: string, pattern: string, limit: number, parentProjectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaserDto>> {
        const response = await this.purchaserControllerGetAllByTeamIdsAndBudgetRaw({ teamIds: teamIds, budgetId: budgetId, pattern: pattern, limit: limit, parentProjectId: parentProjectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetAllByTeamIdsForRoleWithoutTeamsRaw(requestParameters: PurchaserControllerGetAllByTeamIdsForRoleWithoutTeamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetPurchaserDto>>> {
        if (requestParameters.teamIds === null || requestParameters.teamIds === undefined) {
            throw new runtime.RequiredError('teamIds','Required parameter requestParameters.teamIds was null or undefined when calling purchaserControllerGetAllByTeamIdsForRoleWithoutTeams.');
        }

        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling purchaserControllerGetAllByTeamIdsForRoleWithoutTeams.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling purchaserControllerGetAllByTeamIdsForRoleWithoutTeams.');
        }

        const queryParameters: any = {};

        if (requestParameters.teamIds) {
            queryParameters['teamIds'] = requestParameters.teamIds;
        }

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/search/byTeamIdsForRoleWithoutTeams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPurchaserDtoFromJSON));
    }

    /**
     */
    async purchaserControllerGetAllByTeamIdsForRoleWithoutTeams(teamIds: Array<string>, pattern: string, limit: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetPurchaserDto>> {
        const response = await this.purchaserControllerGetAllByTeamIdsForRoleWithoutTeamsRaw({ teamIds: teamIds, pattern: pattern, limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetAllCountRaw(requestParameters: PurchaserControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling purchaserControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/searchForClient/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async purchaserControllerGetAllCount(filterText: string, filterColumns?: object, clientId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.purchaserControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns, clientId: clientId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerGetLastEditorRaw(requestParameters: PurchaserControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaserControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async purchaserControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.purchaserControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async purchaserControllerUpdateRaw(requestParameters: PurchaserControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling purchaserControllerUpdate.');
        }

        if (requestParameters.createUpdatePurchaserDto === null || requestParameters.createUpdatePurchaserDto === undefined) {
            throw new runtime.RequiredError('createUpdatePurchaserDto','Required parameter requestParameters.createUpdatePurchaserDto was null or undefined when calling purchaserControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchaser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePurchaserDtoToJSON(requestParameters.createUpdatePurchaserDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async purchaserControllerUpdate(id: string, createUpdatePurchaserDto: CreateUpdatePurchaserDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaserControllerUpdateRaw({ id: id, createUpdatePurchaserDto: createUpdatePurchaserDto }, initOverrides);
    }

    /**
     */
    async reportControllerReportEstimateItemsRawDataRaw(requestParameters: ReportControllerReportEstimateItemsRawDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportEstimateItemsRawData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/estimateItemsRawData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportEstimateItemsRawData(filterYearData: FilterYearData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportEstimateItemsRawDataRaw({ filterYearData: filterYearData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportControllerReportFVSByFilterRawDataRaw(requestParameters: ReportControllerReportFVSByFilterRawDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportFVSByFilterRawData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/FVSByFilterRawData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportFVSByFilterRawData(filterYearData: FilterYearData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportFVSByFilterRawDataRaw({ filterYearData: filterYearData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportControllerReportFVSControllingByFilterRawDataRaw(requestParameters: ReportControllerReportFVSControllingByFilterRawDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportFVSControllingByFilterRawData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/FVSControllingByFilterRawData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportFVSControllingByFilterRawData(filterYearData: FilterYearData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportFVSControllingByFilterRawDataRaw({ filterYearData: filterYearData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportControllerReportFVZByFilterRawDataRaw(requestParameters: ReportControllerReportFVZByFilterRawDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportFVZByFilterRawData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/FVZByFilterRawData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportFVZByFilterRawData(filterYearData: FilterYearData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportFVZByFilterRawDataRaw({ filterYearData: filterYearData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportControllerReportFVZControllingByFilterRawDataRaw(requestParameters: ReportControllerReportFVZControllingByFilterRawDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportFVZControllingByFilterRawData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/FVZControllingByFilterRawData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportFVZControllingByFilterRawData(filterYearData: FilterYearData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportFVZControllingByFilterRawDataRaw({ filterYearData: filterYearData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportControllerReportForDocumentControlRaw(requestParameters: ReportControllerReportForDocumentControlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        const queryParameters: any = {};

        if (requestParameters.budgetId !== undefined) {
            queryParameters['budgetId'] = requestParameters.budgetId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/DocumentControlByBudgetId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportForDocumentControl(budgetId?: string, projectId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportForDocumentControlRaw({ budgetId: budgetId, projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportControllerReportProjectByMonthRawDataRaw(requestParameters: ReportControllerReportProjectByMonthRawDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportType>> {
        if (requestParameters.filterYearData === null || requestParameters.filterYearData === undefined) {
            throw new runtime.RequiredError('filterYearData','Required parameter requestParameters.filterYearData was null or undefined when calling reportControllerReportProjectByMonthRawData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/projectByMonthRawData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterYearDataToJSON(requestParameters.filterYearData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTypeFromJSON(jsonValue));
    }

    /**
     */
    async reportControllerReportProjectByMonthRawData(filterYearData: FilterYearData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportType> {
        const response = await this.reportControllerReportProjectByMonthRawDataRaw({ filterYearData: filterYearData }, initOverrides);
        return await response.value();
    }

    /**
     */
    async roleControllerCreateRaw(requestParameters: RoleControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRoleDto>> {
        if (requestParameters.createUpdateRoleDto === null || requestParameters.createUpdateRoleDto === undefined) {
            throw new runtime.RequiredError('createUpdateRoleDto','Required parameter requestParameters.createUpdateRoleDto was null or undefined when calling roleControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/role`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateRoleDtoToJSON(requestParameters.createUpdateRoleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async roleControllerCreate(createUpdateRoleDto: CreateUpdateRoleDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRoleDto> {
        const response = await this.roleControllerCreateRaw({ createUpdateRoleDto: createUpdateRoleDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async roleControllerDeleteRaw(requestParameters: RoleControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling roleControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roleControllerDelete(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roleControllerDeleteRaw({ id: id }, initOverrides);
    }

    /**
     */
    async roleControllerGetRaw(requestParameters: RoleControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRoleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling roleControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleDtoFromJSON(jsonValue));
    }

    /**
     */
    async roleControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRoleDto> {
        const response = await this.roleControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async roleControllerGetLastEditorRaw(requestParameters: RoleControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling roleControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/role/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async roleControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.roleControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async roleControllerGetManyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetRoleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/role/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRoleDtoFromJSON));
    }

    /**
     */
    async roleControllerGetMany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetRoleDto>> {
        const response = await this.roleControllerGetManyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async roleControllerUpdateRaw(requestParameters: RoleControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling roleControllerUpdate.');
        }

        if (requestParameters.createUpdateRoleDto === null || requestParameters.createUpdateRoleDto === undefined) {
            throw new runtime.RequiredError('createUpdateRoleDto','Required parameter requestParameters.createUpdateRoleDto was null or undefined when calling roleControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateRoleDtoToJSON(requestParameters.createUpdateRoleDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roleControllerUpdate(id: string, createUpdateRoleDto: CreateUpdateRoleDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roleControllerUpdateRaw({ id: id, createUpdateRoleDto: createUpdateRoleDto }, initOverrides);
    }

    /**
     */
    async salesInvoiceControllerCreateRaw(requestParameters: SalesInvoiceControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSalesInvoiceDto>> {
        if (requestParameters.createUpdateSalesInvoiceDto === null || requestParameters.createUpdateSalesInvoiceDto === undefined) {
            throw new runtime.RequiredError('createUpdateSalesInvoiceDto','Required parameter requestParameters.createUpdateSalesInvoiceDto was null or undefined when calling salesInvoiceControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSalesInvoiceDtoToJSON(requestParameters.createUpdateSalesInvoiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalesInvoiceDtoFromJSON(jsonValue));
    }

    /**
     */
    async salesInvoiceControllerCreate(createUpdateSalesInvoiceDto: CreateUpdateSalesInvoiceDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSalesInvoiceDto> {
        const response = await this.salesInvoiceControllerCreateRaw({ createUpdateSalesInvoiceDto: createUpdateSalesInvoiceDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerCreateCorrectionRaw(requestParameters: SalesInvoiceControllerCreateCorrectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSalesInvoiceDto>> {
        if (requestParameters.createCorrectionSalesInvoiceDto === null || requestParameters.createCorrectionSalesInvoiceDto === undefined) {
            throw new runtime.RequiredError('createCorrectionSalesInvoiceDto','Required parameter requestParameters.createCorrectionSalesInvoiceDto was null or undefined when calling salesInvoiceControllerCreateCorrection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/correction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCorrectionSalesInvoiceDtoToJSON(requestParameters.createCorrectionSalesInvoiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalesInvoiceDtoFromJSON(jsonValue));
    }

    /**
     */
    async salesInvoiceControllerCreateCorrection(createCorrectionSalesInvoiceDto: CreateCorrectionSalesInvoiceDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSalesInvoiceDto> {
        const response = await this.salesInvoiceControllerCreateCorrectionRaw({ createCorrectionSalesInvoiceDto: createCorrectionSalesInvoiceDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerDeactivateRaw(requestParameters: SalesInvoiceControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling salesInvoiceControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async salesInvoiceControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.salesInvoiceControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async salesInvoiceControllerFindAvailableInvoiceNumbersRaw(requestParameters: SalesInvoiceControllerFindAvailableInvoiceNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetNumberingDto>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling salesInvoiceControllerFindAvailableInvoiceNumbers.');
        }

        if (requestParameters.isCorrection === null || requestParameters.isCorrection === undefined) {
            throw new runtime.RequiredError('isCorrection','Required parameter requestParameters.isCorrection was null or undefined when calling salesInvoiceControllerFindAvailableInvoiceNumbers.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling salesInvoiceControllerFindAvailableInvoiceNumbers.');
        }

        if (requestParameters.invoiceDate === null || requestParameters.invoiceDate === undefined) {
            throw new runtime.RequiredError('invoiceDate','Required parameter requestParameters.invoiceDate was null or undefined when calling salesInvoiceControllerFindAvailableInvoiceNumbers.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.isCorrection !== undefined) {
            queryParameters['isCorrection'] = requestParameters.isCorrection;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.invoiceDate !== undefined) {
            queryParameters['invoiceDate'] = (requestParameters.invoiceDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/searchAvailableNumbers/byInvoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNumberingDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerFindAvailableInvoiceNumbers(companyId: string, isCorrection: boolean, type: string, invoiceDate: Date, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetNumberingDto>> {
        const response = await this.salesInvoiceControllerFindAvailableInvoiceNumbersRaw({ companyId: companyId, isCorrection: isCorrection, type: type, invoiceDate: invoiceDate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerFindByEstimateItemRaw(requestParameters: SalesInvoiceControllerFindByEstimateItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceForItem>>> {
        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling salesInvoiceControllerFindByEstimateItem.');
        }

        const queryParameters: any = {};

        if (requestParameters.itemId !== undefined) {
            queryParameters['itemId'] = requestParameters.itemId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/byEstimateItem`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceForItemFromJSON));
    }

    /**
     */
    async salesInvoiceControllerFindByEstimateItem(itemId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceForItem>> {
        const response = await this.salesInvoiceControllerFindByEstimateItemRaw({ itemId: itemId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerFindByProjectRaw(requestParameters: SalesInvoiceControllerFindByProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceWithAmountDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling salesInvoiceControllerFindByProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/byProject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceWithAmountDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerFindByProject(projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceWithAmountDto>> {
        const response = await this.salesInvoiceControllerFindByProjectRaw({ projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetRaw(requestParameters: SalesInvoiceControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExtendedSalesInvoiceDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling salesInvoiceControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExtendedSalesInvoiceDtoFromJSON(jsonValue));
    }

    /**
     */
    async salesInvoiceControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExtendedSalesInvoiceDto> {
        const response = await this.salesInvoiceControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllByPackageXMLByPortionRaw(requestParameters: SalesInvoiceControllerGetAllByPackageXMLByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling salesInvoiceControllerGetAllByPackageXMLByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling salesInvoiceControllerGetAllByPackageXMLByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling salesInvoiceControllerGetAllByPackageXMLByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling salesInvoiceControllerGetAllByPackageXMLByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllByPackageXMLByPortion.');
        }

        if (requestParameters.packageXMLId === null || requestParameters.packageXMLId === undefined) {
            throw new runtime.RequiredError('packageXMLId','Required parameter requestParameters.packageXMLId was null or undefined when calling salesInvoiceControllerGetAllByPackageXMLByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.packageXMLId !== undefined) {
            queryParameters['packageXMLId'] = requestParameters.packageXMLId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/byPackageByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerGetAllByPackageXMLByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, packageXMLId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceDto>> {
        const response = await this.salesInvoiceControllerGetAllByPackageXMLByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, packageXMLId: packageXMLId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllByPortionRaw(requestParameters: SalesInvoiceControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling salesInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling salesInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling salesInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling salesInvoiceControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, status?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceDto>> {
        const response = await this.salesInvoiceControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, status: status }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllByPortionByNegativePositionsDiffRaw(requestParameters: SalesInvoiceControllerGetAllByPortionByNegativePositionsDiffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling salesInvoiceControllerGetAllByPortionByNegativePositionsDiff.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling salesInvoiceControllerGetAllByPortionByNegativePositionsDiff.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling salesInvoiceControllerGetAllByPortionByNegativePositionsDiff.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling salesInvoiceControllerGetAllByPortionByNegativePositionsDiff.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllByPortionByNegativePositionsDiff.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/byNegativePositionsDiffbyPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerGetAllByPortionByNegativePositionsDiff(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceDto>> {
        const response = await this.salesInvoiceControllerGetAllByPortionByNegativePositionsDiffRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllCountRaw(requestParameters: SalesInvoiceControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async salesInvoiceControllerGetAllCount(filterText: string, filterColumns?: object, status?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.salesInvoiceControllerGetAllCountRaw({ filterText: filterText, filterColumns: filterColumns, status: status }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllCountByNegativePositionsDiffRaw(requestParameters: SalesInvoiceControllerGetAllCountByNegativePositionsDiffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllCountByNegativePositionsDiff.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/countByNegativePositionsDiff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async salesInvoiceControllerGetAllCountByNegativePositionsDiff(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.salesInvoiceControllerGetAllCountByNegativePositionsDiffRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllForCompanyYearByPortionRaw(requestParameters: SalesInvoiceControllerGetAllForCompanyYearByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceDto>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/byCompanyYearByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerGetAllForCompanyYearByPortion(companyId: string, status: Array<string>, pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, year?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceDto>> {
        const response = await this.salesInvoiceControllerGetAllForCompanyYearByPortionRaw({ companyId: companyId, status: status, pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, year: year }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllForCompanyYearCountRaw(requestParameters: SalesInvoiceControllerGetAllForCompanyYearCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearCount.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearCount.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllForCompanyYearCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/countForCompanyYear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async salesInvoiceControllerGetAllForCompanyYearCount(companyId: string, status: Array<string>, filterText: string, year?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.salesInvoiceControllerGetAllForCompanyYearCountRaw({ companyId: companyId, status: status, filterText: filterText, year: year }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortionRaw(requestParameters: SalesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/readyToSentWithoutCorrectByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, type?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceDto>> {
        const response = await this.salesInvoiceControllerGetAllReadyToSentWithoutCorrectByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns, type: type }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllReadyToSentWithoutCorrectCountRaw(requestParameters: SalesInvoiceControllerGetAllReadyToSentWithoutCorrectCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllReadyToSentWithoutCorrectCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/countReadyToSentWithoutCorrect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async salesInvoiceControllerGetAllReadyToSentWithoutCorrectCount(filterText: string, filterColumns?: object, type?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.salesInvoiceControllerGetAllReadyToSentWithoutCorrectCountRaw({ filterText: filterText, filterColumns: filterColumns, type: type }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllSentToSymphonyByPortionRaw(requestParameters: SalesInvoiceControllerGetAllSentToSymphonyByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoiceDto>>> {
        if (requestParameters.sentToSymphonyHandel === null || requestParameters.sentToSymphonyHandel === undefined) {
            throw new runtime.RequiredError('sentToSymphonyHandel','Required parameter requestParameters.sentToSymphonyHandel was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyByPortion.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.sentToSymphonyHandel !== undefined) {
            queryParameters['sentToSymphonyHandel'] = requestParameters.sentToSymphonyHandel;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/sentToSymphonyByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoiceDtoFromJSON));
    }

    /**
     */
    async salesInvoiceControllerGetAllSentToSymphonyByPortion(sentToSymphonyHandel: boolean, pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoiceDto>> {
        const response = await this.salesInvoiceControllerGetAllSentToSymphonyByPortionRaw({ sentToSymphonyHandel: sentToSymphonyHandel, pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetAllSentToSymphonyCountRaw(requestParameters: SalesInvoiceControllerGetAllSentToSymphonyCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.sentToSymphonyHandel === null || requestParameters.sentToSymphonyHandel === undefined) {
            throw new runtime.RequiredError('sentToSymphonyHandel','Required parameter requestParameters.sentToSymphonyHandel was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyCount.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetAllSentToSymphonyCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.sentToSymphonyHandel !== undefined) {
            queryParameters['sentToSymphonyHandel'] = requestParameters.sentToSymphonyHandel;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.filterColumns !== undefined) {
            queryParameters['filterColumns'] = requestParameters.filterColumns;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/countSentToSymphony`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async salesInvoiceControllerGetAllSentToSymphonyCount(sentToSymphonyHandel: boolean, filterText: string, filterColumns?: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.salesInvoiceControllerGetAllSentToSymphonyCountRaw({ sentToSymphonyHandel: sentToSymphonyHandel, filterText: filterText, filterColumns: filterColumns }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetCountByPackageXMLRaw(requestParameters: SalesInvoiceControllerGetCountByPackageXMLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling salesInvoiceControllerGetCountByPackageXML.');
        }

        if (requestParameters.packageXMLId === null || requestParameters.packageXMLId === undefined) {
            throw new runtime.RequiredError('packageXMLId','Required parameter requestParameters.packageXMLId was null or undefined when calling salesInvoiceControllerGetCountByPackageXML.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.packageXMLId !== undefined) {
            queryParameters['packageXMLId'] = requestParameters.packageXMLId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/search/countByPackage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async salesInvoiceControllerGetCountByPackageXML(filterText: string, packageXMLId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.salesInvoiceControllerGetCountByPackageXMLRaw({ filterText: filterText, packageXMLId: packageXMLId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerGetLastEditorRaw(requestParameters: SalesInvoiceControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling salesInvoiceControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async salesInvoiceControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.salesInvoiceControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoiceControllerUpdateRaw(requestParameters: SalesInvoiceControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling salesInvoiceControllerUpdate.');
        }

        if (requestParameters.createUpdateSalesInvoiceDto === null || requestParameters.createUpdateSalesInvoiceDto === undefined) {
            throw new runtime.RequiredError('createUpdateSalesInvoiceDto','Required parameter requestParameters.createUpdateSalesInvoiceDto was null or undefined when calling salesInvoiceControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSalesInvoiceDtoToJSON(requestParameters.createUpdateSalesInvoiceDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async salesInvoiceControllerUpdate(id: string, createUpdateSalesInvoiceDto: CreateUpdateSalesInvoiceDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.salesInvoiceControllerUpdateRaw({ id: id, createUpdateSalesInvoiceDto: createUpdateSalesInvoiceDto }, initOverrides);
    }

    /**
     */
    async salesInvoiceControllerUpdateSummaryRaw(requestParameters: SalesInvoiceControllerUpdateSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSalesInvoiceDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling salesInvoiceControllerUpdateSummary.');
        }

        if (requestParameters.createUpdateSalesInvoiceDto === null || requestParameters.createUpdateSalesInvoiceDto === undefined) {
            throw new runtime.RequiredError('createUpdateSalesInvoiceDto','Required parameter requestParameters.createUpdateSalesInvoiceDto was null or undefined when calling salesInvoiceControllerUpdateSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoice/summary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSalesInvoiceDtoToJSON(requestParameters.createUpdateSalesInvoiceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalesInvoiceDtoFromJSON(jsonValue));
    }

    /**
     */
    async salesInvoiceControllerUpdateSummary(id: string, createUpdateSalesInvoiceDto: CreateUpdateSalesInvoiceDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSalesInvoiceDto> {
        const response = await this.salesInvoiceControllerUpdateSummaryRaw({ id: id, createUpdateSalesInvoiceDto: createUpdateSalesInvoiceDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async salesInvoicePositionControllerCreateManyRaw(requestParameters: SalesInvoicePositionControllerCreateManyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSalesInvoicePositionDto>>> {
        if (requestParameters.createUpdateSalesInvoicePositionsDto === null || requestParameters.createUpdateSalesInvoicePositionsDto === undefined) {
            throw new runtime.RequiredError('createUpdateSalesInvoicePositionsDto','Required parameter requestParameters.createUpdateSalesInvoicePositionsDto was null or undefined when calling salesInvoicePositionControllerCreateMany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/salesInvoicePosition/createPositions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSalesInvoicePositionsDtoToJSON(requestParameters.createUpdateSalesInvoicePositionsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSalesInvoicePositionDtoFromJSON));
    }

    /**
     */
    async salesInvoicePositionControllerCreateMany(createUpdateSalesInvoicePositionsDto: CreateUpdateSalesInvoicePositionsDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSalesInvoicePositionDto>> {
        const response = await this.salesInvoicePositionControllerCreateManyRaw({ createUpdateSalesInvoicePositionsDto: createUpdateSalesInvoicePositionsDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async savedPdfControllerHasInvoiceSavedPdfRaw(requestParameters: SavedPdfControllerHasInvoiceSavedPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling savedPdfControllerHasInvoiceSavedPdf.');
        }

        const queryParameters: any = {};

        if (requestParameters.invoiceId !== undefined) {
            queryParameters['invoiceId'] = requestParameters.invoiceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/savedPdf/hasInvoiceSavedPdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async savedPdfControllerHasInvoiceSavedPdf(invoiceId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.savedPdfControllerHasInvoiceSavedPdfRaw({ invoiceId: invoiceId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialInvoicingControllerCreateRaw(requestParameters: SpecialInvoicingControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSpecialInvoicingDto>> {
        if (requestParameters.createUpdateSpecialInvoicingDto === null || requestParameters.createUpdateSpecialInvoicingDto === undefined) {
            throw new runtime.RequiredError('createUpdateSpecialInvoicingDto','Required parameter requestParameters.createUpdateSpecialInvoicingDto was null or undefined when calling specialInvoicingControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSpecialInvoicingDtoToJSON(requestParameters.createUpdateSpecialInvoicingDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSpecialInvoicingDtoFromJSON(jsonValue));
    }

    /**
     */
    async specialInvoicingControllerCreate(createUpdateSpecialInvoicingDto: CreateUpdateSpecialInvoicingDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSpecialInvoicingDto> {
        const response = await this.specialInvoicingControllerCreateRaw({ createUpdateSpecialInvoicingDto: createUpdateSpecialInvoicingDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialInvoicingControllerDeactivateRaw(requestParameters: SpecialInvoicingControllerDeactivateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialInvoicingControllerDeactivate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/deactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async specialInvoicingControllerDeactivate(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.specialInvoicingControllerDeactivateRaw({ id: id }, initOverrides);
    }

    /**
     */
    async specialInvoicingControllerGetRaw(requestParameters: SpecialInvoicingControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSpecialInvoicingDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialInvoicingControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSpecialInvoicingDtoFromJSON(jsonValue));
    }

    /**
     */
    async specialInvoicingControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSpecialInvoicingDto> {
        const response = await this.specialInvoicingControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialInvoicingControllerGetAllByPortionRaw(requestParameters: SpecialInvoicingControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSpecialInvoicingDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling specialInvoicingControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling specialInvoicingControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling specialInvoicingControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling specialInvoicingControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling specialInvoicingControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSpecialInvoicingDtoFromJSON));
    }

    /**
     */
    async specialInvoicingControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, statuses?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSpecialInvoicingDto>> {
        const response = await this.specialInvoicingControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, statuses: statuses }, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialInvoicingControllerGetAllCountRaw(requestParameters: SpecialInvoicingControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling specialInvoicingControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async specialInvoicingControllerGetAllCount(filterText: string, statuses?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.specialInvoicingControllerGetAllCountRaw({ filterText: filterText, statuses: statuses }, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialInvoicingControllerGetLastEditorRaw(requestParameters: SpecialInvoicingControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialInvoicingControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async specialInvoicingControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.specialInvoicingControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async specialInvoicingControllerUpdateRaw(requestParameters: SpecialInvoicingControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialInvoicingControllerUpdate.');
        }

        if (requestParameters.createUpdateSpecialInvoicingDto === null || requestParameters.createUpdateSpecialInvoicingDto === undefined) {
            throw new runtime.RequiredError('createUpdateSpecialInvoicingDto','Required parameter requestParameters.createUpdateSpecialInvoicingDto was null or undefined when calling specialInvoicingControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSpecialInvoicingDtoToJSON(requestParameters.createUpdateSpecialInvoicingDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async specialInvoicingControllerUpdate(id: string, createUpdateSpecialInvoicingDto: CreateUpdateSpecialInvoicingDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.specialInvoicingControllerUpdateRaw({ id: id, createUpdateSpecialInvoicingDto: createUpdateSpecialInvoicingDto }, initOverrides);
    }

    /**
     */
    async specialInvoicingControllerUpdateDecisionRaw(requestParameters: SpecialInvoicingControllerUpdateDecisionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling specialInvoicingControllerUpdateDecision.');
        }

        if (requestParameters.decisionSpecialInvoicingDto === null || requestParameters.decisionSpecialInvoicingDto === undefined) {
            throw new runtime.RequiredError('decisionSpecialInvoicingDto','Required parameter requestParameters.decisionSpecialInvoicingDto was null or undefined when calling specialInvoicingControllerUpdateDecision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/specialInvoicing/decision/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DecisionSpecialInvoicingDtoToJSON(requestParameters.decisionSpecialInvoicingDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async specialInvoicingControllerUpdateDecision(id: string, decisionSpecialInvoicingDto: DecisionSpecialInvoicingDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.specialInvoicingControllerUpdateDecisionRaw({ id: id, decisionSpecialInvoicingDto: decisionSpecialInvoicingDto }, initOverrides);
    }

    /**
     */
    async symphonyClientControllerCreateRaw(requestParameters: SymphonyClientControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSymphonyClientDto>> {
        if (requestParameters.createUpdateSymphonyClientDto === null || requestParameters.createUpdateSymphonyClientDto === undefined) {
            throw new runtime.RequiredError('createUpdateSymphonyClientDto','Required parameter requestParameters.createUpdateSymphonyClientDto was null or undefined when calling symphonyClientControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSymphonyClientDtoToJSON(requestParameters.createUpdateSymphonyClientDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSymphonyClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async symphonyClientControllerCreate(createUpdateSymphonyClientDto: CreateUpdateSymphonyClientDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSymphonyClientDto> {
        const response = await this.symphonyClientControllerCreateRaw({ createUpdateSymphonyClientDto: createUpdateSymphonyClientDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async symphonyClientControllerGetRaw(requestParameters: SymphonyClientControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSymphonyClientDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling symphonyClientControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSymphonyClientDtoFromJSON(jsonValue));
    }

    /**
     */
    async symphonyClientControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSymphonyClientDto> {
        const response = await this.symphonyClientControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async symphonyClientControllerGetAllByPortionForClientRaw(requestParameters: SymphonyClientControllerGetAllByPortionForClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSymphonyClientDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling symphonyClientControllerGetAllByPortionForClient.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling symphonyClientControllerGetAllByPortionForClient.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling symphonyClientControllerGetAllByPortionForClient.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling symphonyClientControllerGetAllByPortionForClient.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling symphonyClientControllerGetAllByPortionForClient.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.filterToCurrentCompany !== undefined) {
            queryParameters['filterToCurrentCompany'] = requestParameters.filterToCurrentCompany;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient/searchForClient/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSymphonyClientDtoFromJSON));
    }

    /**
     */
    async symphonyClientControllerGetAllByPortionForClient(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, clientId?: string, filterToCurrentCompany?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSymphonyClientDto>> {
        const response = await this.symphonyClientControllerGetAllByPortionForClientRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, clientId: clientId, filterToCurrentCompany: filterToCurrentCompany }, initOverrides);
        return await response.value();
    }

    /**
     */
    async symphonyClientControllerGetAllCountForClientRaw(requestParameters: SymphonyClientControllerGetAllCountForClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling symphonyClientControllerGetAllCountForClient.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.filterToCurrentCompany !== undefined) {
            queryParameters['filterToCurrentCompany'] = requestParameters.filterToCurrentCompany;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient/searchForClient/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async symphonyClientControllerGetAllCountForClient(filterText: string, clientId?: string, filterToCurrentCompany?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.symphonyClientControllerGetAllCountForClientRaw({ filterText: filterText, clientId: clientId, filterToCurrentCompany: filterToCurrentCompany }, initOverrides);
        return await response.value();
    }

    /**
     */
    async symphonyClientControllerGetByClientCompanyRaw(requestParameters: SymphonyClientControllerGetByClientCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSymphonyClientDto>>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling symphonyClientControllerGetByClientCompany.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling symphonyClientControllerGetByClientCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient/getByClientCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSymphonyClientDtoFromJSON));
    }

    /**
     */
    async symphonyClientControllerGetByClientCompany(clientId: string, companyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSymphonyClientDto>> {
        const response = await this.symphonyClientControllerGetByClientCompanyRaw({ clientId: clientId, companyId: companyId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async symphonyClientControllerGetLastEditorRaw(requestParameters: SymphonyClientControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling symphonyClientControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async symphonyClientControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.symphonyClientControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async symphonyClientControllerUpdateRaw(requestParameters: SymphonyClientControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling symphonyClientControllerUpdate.');
        }

        if (requestParameters.createUpdateSymphonyClientDto === null || requestParameters.createUpdateSymphonyClientDto === undefined) {
            throw new runtime.RequiredError('createUpdateSymphonyClientDto','Required parameter requestParameters.createUpdateSymphonyClientDto was null or undefined when calling symphonyClientControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/symphonyClient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSymphonyClientDtoToJSON(requestParameters.createUpdateSymphonyClientDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async symphonyClientControllerUpdate(id: string, createUpdateSymphonyClientDto: CreateUpdateSymphonyClientDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.symphonyClientControllerUpdateRaw({ id: id, createUpdateSymphonyClientDto: createUpdateSymphonyClientDto }, initOverrides);
    }

    /**
     */
    async tbInvoiceControllerExportFSVRaw(requestParameters: TbInvoiceControllerExportFSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createExportToSymphonyDataDto === null || requestParameters.createExportToSymphonyDataDto === undefined) {
            throw new runtime.RequiredError('createExportToSymphonyDataDto','Required parameter requestParameters.createExportToSymphonyDataDto was null or undefined when calling tbInvoiceControllerExportFSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tbInvoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExportToSymphonyDataDtoToJSON(requestParameters.createExportToSymphonyDataDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tbInvoiceControllerExportFSV(createExportToSymphonyDataDto: CreateExportToSymphonyDataDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tbInvoiceControllerExportFSVRaw({ createExportToSymphonyDataDto: createExportToSymphonyDataDto }, initOverrides);
    }

    /**
     */
    async teamControllerCreateRaw(requestParameters: TeamControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTeamDto>> {
        if (requestParameters.createUpdateTeamDto === null || requestParameters.createUpdateTeamDto === undefined) {
            throw new runtime.RequiredError('createUpdateTeamDto','Required parameter requestParameters.createUpdateTeamDto was null or undefined when calling teamControllerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTeamDtoToJSON(requestParameters.createUpdateTeamDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async teamControllerCreate(createUpdateTeamDto: CreateUpdateTeamDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTeamDto> {
        const response = await this.teamControllerCreateRaw({ createUpdateTeamDto: createUpdateTeamDto }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetRaw(requestParameters: TeamControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTeamDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async teamControllerGet(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTeamDto> {
        const response = await this.teamControllerGetRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllActiveRaw(requestParameters: TeamControllerGetAllActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTeamDto>>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling teamControllerGetAllActive.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTeamDtoFromJSON));
    }

    /**
     */
    async teamControllerGetAllActive(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTeamDto>> {
        const response = await this.teamControllerGetAllActiveRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllActiveByPortionRaw(requestParameters: TeamControllerGetAllActiveByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTeamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling teamControllerGetAllActiveByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling teamControllerGetAllActiveByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling teamControllerGetAllActiveByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling teamControllerGetAllActiveByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling teamControllerGetAllActiveByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTeamDtoFromJSON));
    }

    /**
     */
    async teamControllerGetAllActiveByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTeamDto>> {
        const response = await this.teamControllerGetAllActiveByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllActiveCountRaw(requestParameters: TeamControllerGetAllActiveCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling teamControllerGetAllActiveCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/activeCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async teamControllerGetAllActiveCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.teamControllerGetAllActiveCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllByCompanyIdsRaw(requestParameters: TeamControllerGetAllByCompanyIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTeamDto>>> {
        if (requestParameters.companyIds === null || requestParameters.companyIds === undefined) {
            throw new runtime.RequiredError('companyIds','Required parameter requestParameters.companyIds was null or undefined when calling teamControllerGetAllByCompanyIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/byCompanyIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTeamDtoFromJSON));
    }

    /**
     */
    async teamControllerGetAllByCompanyIds(companyIds: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTeamDto>> {
        const response = await this.teamControllerGetAllByCompanyIdsRaw({ companyIds: companyIds }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllByPortionRaw(requestParameters: TeamControllerGetAllByPortionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTeamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling teamControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling teamControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling teamControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling teamControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling teamControllerGetAllByPortion.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/byCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTeamDtoFromJSON));
    }

    /**
     */
    async teamControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTeamDto>> {
        const response = await this.teamControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllByProjectIdRaw(requestParameters: TeamControllerGetAllByProjectIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTeamDto>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling teamControllerGetAllByProjectId.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/byProjectId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTeamDtoFromJSON));
    }

    /**
     */
    async teamControllerGetAllByProjectId(projectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTeamDto>> {
        const response = await this.teamControllerGetAllByProjectIdRaw({ projectId: projectId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetAllCountRaw(requestParameters: TeamControllerGetAllCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling teamControllerGetAllCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/byCompanyCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async teamControllerGetAllCount(filterText: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.teamControllerGetAllCountRaw({ filterText: filterText }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetLastEditorRaw(requestParameters: TeamControllerGetLastEditorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLastEditorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamControllerGetLastEditor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/lastEditorInfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLastEditorDtoFromJSON(jsonValue));
    }

    /**
     */
    async teamControllerGetLastEditor(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLastEditorDto> {
        const response = await this.teamControllerGetLastEditorRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerGetMyForPermissionByCompanyIdsRaw(requestParameters: TeamControllerGetMyForPermissionByCompanyIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTeamDto>>> {
        if (requestParameters.companyIds === null || requestParameters.companyIds === undefined) {
            throw new runtime.RequiredError('companyIds','Required parameter requestParameters.companyIds was null or undefined when calling teamControllerGetMyForPermissionByCompanyIds.');
        }

        if (requestParameters.permission === null || requestParameters.permission === undefined) {
            throw new runtime.RequiredError('permission','Required parameter requestParameters.permission was null or undefined when calling teamControllerGetMyForPermissionByCompanyIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyIds) {
            queryParameters['companyIds'] = requestParameters.companyIds;
        }

        if (requestParameters.permission !== undefined) {
            queryParameters['permission'] = requestParameters.permission;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/search/forPermissionByCompanyIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTeamDtoFromJSON));
    }

    /**
     */
    async teamControllerGetMyForPermissionByCompanyIds(companyIds: Array<string>, permission: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTeamDto>> {
        const response = await this.teamControllerGetMyForPermissionByCompanyIdsRaw({ companyIds: companyIds, permission: permission }, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerPaeTeamRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/check/paeTeam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async teamControllerPaeTeam(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.teamControllerPaeTeamRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async teamControllerUpdateRaw(requestParameters: TeamControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamControllerUpdate.');
        }

        if (requestParameters.createUpdateTeamDto === null || requestParameters.createUpdateTeamDto === undefined) {
            throw new runtime.RequiredError('createUpdateTeamDto','Required parameter requestParameters.createUpdateTeamDto was null or undefined when calling teamControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/team/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTeamDtoToJSON(requestParameters.createUpdateTeamDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamControllerUpdate(id: string, createUpdateTeamDto: CreateUpdateTeamDto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamControllerUpdateRaw({ id: id, createUpdateTeamDto: createUpdateTeamDto }, initOverrides);
    }

}

/**
 * @export
 */
export const MemoControllerGetSubjectThreadStatsSubjectTypeEnum = {
    Project: 'Project',
    PurchaseInvoice: 'PurchaseInvoice',
    SaleInvoice: 'SaleInvoice',
    SpecialInvoicing: 'SpecialInvoicing'
} as const;
export type MemoControllerGetSubjectThreadStatsSubjectTypeEnum = typeof MemoControllerGetSubjectThreadStatsSubjectTypeEnum[keyof typeof MemoControllerGetSubjectThreadStatsSubjectTypeEnum];
