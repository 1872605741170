/*eslint complexity: ["error", 12]*/
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import {
  CreateUpdateEstimateItemDto,
  GetEstimateByMonthDto,
  GetEstimateItemDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  convert,
  validate,
} from '../../../common/pokCore/contexts/EstimateItemContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import numberFormatter from '../../../common/numberFormatter';
import mathUtils from '../../../utils/mathUtils';

import { EstimateItemTabs } from './EstimateItemTabs';

interface EstimateItemAccordionProps {
  estimateItem: GetEstimateItemDto;
  estimateByMonth?: GetEstimateByMonthDto;
  project?: GetProjectDto;
  idx: string;
  activeItemIdx?: string;
  refresh(): void;
  handleOnClick: () => void;
  onDelete: (id?: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  cloneItem: (id: string, withAmounts: boolean) => () => void;
  closeAccordion: () => void;
  salesBlocked: boolean;
  purchaseBlocked: boolean;
}

export const EstimateItemAccordion: React.FC<
  EstimateItemAccordionProps
> = props => {
  const showMargin = !!props.project?.purchaser?.company?.showMargin;

  const [estimateItem, setEstimateItem] = useState<GetEstimateItemDto>(
    props.estimateItem,
  );
  const pok = usePokCore();
  const notifications = useNotifications();

  const createAddEstimateItem = async (
    item: CreateUpdateEstimateItemDto,
    id?: string,
  ) => {
    const status = await validate(
      item,
      props.estimateByMonth?.profile,
      props.salesBlocked,
      props.purchaseBlocked,
    );
    if (status.valid) {
      try {
        if (id) {
          const updatedItem = await pok.estimateItems.update(id, item);
          setEstimateItem(updatedItem);
        } else {
          const newItem = await pok.estimateItems.create(item);
          setEstimateItem(newItem);
        }
        notifications.saveCompleted();
        props.refresh();
        return {
          saved: true,
          errors: [],
          amountFieldsValid: true,
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
          amountFieldsValid: false,
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
        amountFieldsValid: status.amountFieldsValid,
      };
    }
  };

  const generateTitle = () => {
    return (
      <section className="layout-accordion-header">
        <div className="grow1">
          <b>{estimateItem?.position?.name} </b>
          {estimateItem?.position.client.shortname
            ? `(${estimateItem?.position.client.shortname}) `
            : ''}
          {estimateItem?.service ? `${estimateItem?.service}` : ''}
          {estimateItem?.orderNumber
            ? `Nr zlecenia: ${estimateItem?.orderNumber}`
            : ''}
        </div>
        <div className="marginLeft">
          Sprzedaż:{' '}
          <b>{numberFormatter(estimateItem?.salesNetTotal || '0', true)}</b>{' '}
        </div>
        <div className="marginLeft">
          Zakup:{' '}
          <b>{numberFormatter(estimateItem?.purchaseNetTotal || '0', true)}</b>{' '}
        </div>
        <div className="marginLeft">
          FVZ:{' '}
          <b>
            {numberFormatter(estimateItem?.purchaseInvoicesSum || '0', true)}
          </b>{' '}
        </div>
        <div className="marginLeft">
          Różnica FVZ:{' '}
          <b>
            {numberFormatter(
              mathUtils.subtract(
                estimateItem?.purchaseNetTotal || 0,
                estimateItem?.purchaseInvoicesSum || 0,
              ),
              true,
            )}
          </b>{' '}
        </div>
        {showMargin && (
          <div className="marginLeft">
            Marża:{' '}
            <b>
              {numberFormatter(
                mathUtils.subtract(
                  estimateItem?.salesNetTotal || 0,
                  estimateItem?.purchaseNetTotal || 0,
                ),
                true,
              )}
            </b>{' '}
          </div>
        )}
      </section>
    );
  };

  return (
    <Accordion.Item
      eventKey={props.idx}
      className={`flex-fill ${
        props.activeItemIdx === props.idx ? 'active-item-accordion' : ''
      }`}
    >
      <Accordion.Header
        onClick={props.handleOnClick}
        className={`${estimateItem?.id ? '' : 'new-item-accordion'}`}
      >
        {estimateItem?.id ? generateTitle() : 'Nowa pozycja (NIEZAPISANA)'}
      </Accordion.Header>
      <Accordion.Body>
        {props.activeItemIdx === props.idx && (
          <EstimateItemTabs
            id={estimateItem?.id}
            positionName={estimateItem?.position?.name}
            estimateItem={convert(
              props.estimateItem,
              props.estimateByMonth,
              props.project,
            )}
            isSalesBlocked={props.salesBlocked}
            isPurchaseBlocked={props.purchaseBlocked}
            profile={props.estimateByMonth?.profile}
            project={props.project}
            onCompleted={createAddEstimateItem}
            onDelete={props.onDelete}
            cloneItem={props.cloneItem}
            closeAccordion={props.closeAccordion}
          />
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};
