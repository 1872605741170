/*eslint complexity: ["error", 14]*/
import React from 'react';
import { uniq, sortBy } from 'lodash';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetProjectDto } from '../../../common/pokCore/autogenerated/pokApiClient';
import momencik from '../../../common/momencik';
import { useTranslation } from '../../../common/hooks/useTranslation';
import numberFormatter from '../../../common/numberFormatter';
import {
  ProjectApplicationTypeEnum,
  ProjectEstimatesStatusEnum,
} from '../../../common/pokCore/validation/schemas';
import { useAuth } from '../../../common/hooks/useAuth';
import { PermissionsEnum } from '../../../common/pokCore/authorization/permissions';
import { NewWindowButton } from '../PurchaseInvoices/NewWindowButton';

import { CloneIcon } from './CloneIcon';
import { DelayIcon } from './DelayIcon';
import { CancelIcon } from './CancelIcon';

interface ProjectSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (
    key: string,
    newWindow?: boolean,
  ) => Promise<void> | void | undefined;
  refresh?: boolean;
  isDelayed?: boolean;
  showTotalDifferenceColumn?: boolean;
  totalDifferenceLabel?: string;
  disableGridHistory?: boolean;
  showActionColumn?: boolean;
  withFilterInColumns?: boolean;
  enableMultiselect?: boolean;
  selectedKeys?: string[];
  applicationType?: ProjectApplicationTypeEnum;
  showNewWindowButton?: boolean;
}

const getStatusName = (
  project: GetProjectDto,
  applicationType?: ProjectApplicationTypeEnum,
) => {
  let status = '';
  switch (applicationType) {
    case ProjectApplicationTypeEnum.Delayed:
      status = project.active
        ? project.delayVerification
          ? 'Oczekujący'
          : 'Zatwierdzony'
        : 'Odrzucony';
      break;
    case ProjectApplicationTypeEnum.ToCancel:
      status = project.active
        ? project.cancelVerification
          ? 'Oczekujący'
          : 'Odrzucony'
        : 'Zatwierdzony';
      break;
  }
  return status;
};

export const projectApplicationsForGrid = (
  project: GetProjectDto,
  applicationType: ProjectApplicationTypeEnum,
) => {
  return projectForGrid(
    project,
    undefined,
    undefined,
    undefined,
    applicationType,
  );
};

export const projectForGrid = (
  project: GetProjectDto,
  blockedClick?: boolean,
  enableClone?: boolean,
  hidePurchaserClient?: boolean,
  applicationType?: ProjectApplicationTypeEnum,
) => {
  const shortName = project.purchaser?.client?.shortname || '';
  const purchaserLabel = `${project.purchaser?.name}${
    hidePurchaserClient
      ? ''
      : ` (${shortName.length ? shortName : project.purchaser?.client?.name})`
  }`;

  const readonly =
    (project.delayVerification ||
      (!project.active && project.cancelMessage !== '')) &&
    blockedClick;

  return {
    key: project.id,
    values: {
      'project.name': project.name,
      'project.systemNumber': project.systemNumber,
      'project.status': getStatusName(project, applicationType),
      'project.number': project.number,
      project_number_active: project.systemNumber,
      'project.startDate': momencik(project.startDate),
      'project.endDate': momencik(project.endDate),
      'parent.number': project.parent?.number || '',
      'brand.name': project.brand?.name,
      'team.name': project.projectTeams
        ?.map(({ team }) => team?.name + ' (' + team?.company?.shortname + ')')
        ?.join(', '),
      purchaser_label: purchaserLabel,
      'profile.name': sortBy(
        uniq(
          project.estimatesByMonths
            ?.filter(
              ebm =>
                ebm?.status !== ProjectEstimatesStatusEnum.Cancelled &&
                ebm?.active !== false,
            )
            .map(ebm => ebm?.profile?.name),
        ),
      )?.join(', '),
      'project.totalDifference': numberFormatter(
        project.totalDifference || '0',
        true,
      ),
      'project.icons': (
        <>
          <DelayIcon
            delayVerification={project.delayVerification}
            active={project.active}
          />
          <CancelIcon
            cancelVerification={project.cancelVerification}
            active={project.active}
          />
        </>
      ),
      'project.clone': (
        <CloneIcon
          active={project.active}
          projectId={project.id}
          projectName={`${project.name} (${project.number})`}
          projectStartDate={project.startDate}
          enableClone={enableClone}
        />
      ),
      'responsibleOwner.name': project.responsibleOwner?.name || '',
      'countryDictionary.value': project.countryDictionary?.value || '',
      newWindowButton: !readonly && <NewWindowButton projectId={project.id} />,
    },
    isInactive: !project.active,
    readOnly: readonly,
  };
};

const showProjectNumber = (
  isDelayed?: boolean,
  applicationType?: ProjectApplicationTypeEnum,
) => {
  return (
    isDelayed ||
    isDelayed === undefined ||
    applicationType === ProjectApplicationTypeEnum.ToCancel
  );
};

export const ProjectSearch: React.FC<ProjectSearchProps> = props => {
  const { t, tk } = useTranslation('menu');
  const auth = useAuth();
  const isAdmin = auth.check(PermissionsEnum.Administration);
  const columns: GridColumn[] = [
    ...(props.showNewWindowButton
      ? [
          {
            header: '',
            property: 'newWindowButton',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    ...(showProjectNumber(props.isDelayed, props.applicationType)
      ? [{ header: 'Numer', property: 'project.number' }]
      : []),
    ...(props.isDelayed
      ? [
          {
            header: 'Status',
            property: 'project.status',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    { header: 'Nazwa', property: 'project.name' },
    { header: 'Zespół', property: 'team.name' },
    { header: 'Zamawiający', property: 'purchaser_label' },
    ...(props.showNewWindowButton
      ? [{ header: 'Kraj/rynek', property: 'countryDictionary.value' }]
      : []),
    { header: 'Brand', property: 'brand.name' },
    { header: 'Nr zlecającego', property: 'parent.number' },
    { header: 'Aktywne profile', property: 'profile.name' },
    { header: 'Data rozpoczęcia', property: 'project.startDate' },
    { header: 'Data zakończenia', property: 'project.endDate' },
    ...(props.showTotalDifferenceColumn
      ? [
          {
            header: props.totalDifferenceLabel || '',
            property: 'project.totalDifference',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    { header: 'Osoba odpowiedzialna', property: 'responsibleOwner.name' },
    { header: 'Nr sys.', property: 'project_number_active' },
    ...(props.showActionColumn
      ? [
          {
            header: '',
            property: 'project.icons',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
    ...(isAdmin && props.showActionColumn
      ? [
          {
            header: '',
            property: 'project.clone',
            noSorting: true,
            noFilter: true,
          },
        ]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      refresh={props.refresh}
      initialOrderColumn={
        props.isDelayed || props.isDelayed === undefined
          ? 'project_number_active'
          : 'project.startDate'
      }
      initialOrderDirection={
        props.isDelayed || props.isDelayed === undefined ? 'DESC' : 'ASC'
      }
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage={t(tk.menu.emptyProjectsList)}
      onRowClick={props.onRowClick}
      withHistory={!props.disableGridHistory}
      withFilterInColumns={props.withFilterInColumns}
      enableMultiSelect={props.enableMultiselect}
      selectedKeys={props.selectedKeys}
      withOverflowXAuto
    />
  );
};
