/*eslint max-lines-per-function: ["error", 300]*/
import React from 'react';
import { Mutex } from 'async-mutex';
import jwt from 'jwt-decode';
import Cookies from 'js-cookie';

import { PokCoreContextType } from './pokCore';
import {
  CurrencyInTokenDto,
  GetEmployeeDto,
  RoleInCompanyInTokenDto,
  TeamInCompanyInTokenDto,
} from './pokCore/autogenerated/pokApiClient';
import { PermissionsEnum } from './pokCore/authorization/permissions';

interface User {
  name: string;
  email: string;
  sub: string;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export interface AuthProviderState {
  currentUser: User | undefined;
  rolesInCompanies: RoleInCompanyInTokenDto[];
  mutex: Mutex | undefined;
  currency: CurrencyInTokenDto | undefined;
  check: (role: PermissionsEnum, companyId?: string) => boolean;
  checkTeam: (teamName: string, companyId?: string) => boolean;
  ensureTokenIsValid: (pok: PokCoreContextType) => Promise<void>;
  login: (pok: PokCoreContextType, login: string, password: string) => void;
  loginWithGoogle: (pok: PokCoreContextType, googleToken: string) => void;
  logout: () => void;
  setCurrentUser: (
    user: GetEmployeeDto,
    rolesInCompanies: RoleInCompanyInTokenDto[],
    teamsInCompanies: TeamInCompanyInTokenDto[],
  ) => void;
  tokenRefresh: (pok: PokCoreContextType) => Promise<void>;
}

const emptyState = {
  currentUser: undefined,
  rolesInCompanies: [],
  mutex: undefined,
  currency: undefined,
  check: () => false,
  checkTeam: () => false,
  ensureTokenIsValid: async () => {
    false;
  },
  login: () => false,
  loginWithGoogle: () => false,
  logout: () => {
    localStorage.clear();
  },
  setCurrentUser: () => false,
  tokenRefresh: async () => {
    false;
  },
};

export const AuthContext = React.createContext<AuthProviderState>(emptyState);

export class AuthProvider extends React.Component<
  AuthProviderProps,
  AuthProviderState
> {
  constructor(props: AuthProviderProps) {
    super(props);
    this.state = emptyState;
  }

  componentDidMount() {
    const state: AuthProviderState = emptyState;

    state.mutex = new Mutex();

    state.setCurrentUser = async (
      user: GetEmployeeDto,
      rolesInCompanies: RoleInCompanyInTokenDto[],
      teamsInCompanies: TeamInCompanyInTokenDto[],
    ) => {
      state.currentUser = {
        name: user.name,
        email: user.email,
        sub: user.id,
      };
      state.rolesInCompanies = rolesInCompanies;

      state.check = (role: PermissionsEnum, companyId?: string) => {
        if (!companyId) {
          return rolesInCompanies
            .map(o => o.roles)
            .flat()
            .includes(role);
        }
        return rolesInCompanies
          .filter(o => o.companyId === companyId || !o.companyId)
          .map(o => o.roles)
          .flat()
          .includes(role);
      };

      localStorage.setItem('user', user.id);

      state.checkTeam = (teamName: string, companyId?: string) => {
        if (!companyId) {
          return (
            teamsInCompanies
              .map(o => o.teamNames)
              .flat()
              .includes(teamName) ||
            teamsInCompanies
              .map(o => o.teamNames)
              .flat()
              .includes('')
          );
        }
        return (
          teamsInCompanies
            .filter(o => o.companyId === companyId || !o.companyId)
            .map(o => o.teamNames)
            .flat()
            .includes(teamName) ||
          teamsInCompanies
            .filter(o => o.companyId === companyId || !o.companyId)
            .map(o => o.teamNames)
            .flat()
            .includes('')
        );
      };

      state.ensureTokenIsValid = async (pok: PokCoreContextType) => {
        const token = localStorage.getItem('pok-jwt-token');
        if (!token) {
          throw new Error('No token');
        }

        const tokenData: { exp: number } = jwt(token);
        if (tokenData.exp < Date.now() / 1000) {
          await this.state.tokenRefresh(pok);
        }
      };

      state.tokenRefresh = async (pok: PokCoreContextType) => {
        const refreshToken = localStorage.getItem('pok-jwt-refresh-token');
        if (!refreshToken) {
          return;
        }
        const tokens = await pok.logins
          .refreshToken(refreshToken)
          .catch(err => {
            const errorResponse = err.response;

            if (errorResponse.status !== 401) {
              throw err;
            }
            this.state.logout();
            throw err;
          });

        if (tokens) {
          localStorage.setItem('pok-jwt-refresh-token', tokens.refreshToken);
          localStorage.setItem('pok-jwt-token', tokens.accessToken);
          state.rolesInCompanies = tokens.rolesInCompanies;
          state.check = (role: PermissionsEnum, companyId?: string) => {
            if (!companyId) {
              return tokens.rolesInCompanies
                .map(o => o.roles)
                .flat()
                .includes(role);
            }
            return tokens.rolesInCompanies
              .filter(o => o.companyId === companyId || !o.companyId)
              .map(o => o.roles)
              .flat()
              .includes(role);
          };

          state.checkTeam = (teamName: string, companyId?: string) => {
            if (!companyId) {
              return (
                teamsInCompanies
                  .map(o => o.teamNames)
                  .flat()
                  .includes(teamName) ||
                teamsInCompanies
                  .map(o => o.teamNames)
                  .flat()
                  .includes('')
              );
            }
            return (
              teamsInCompanies
                .filter(o => o.companyId === companyId || !o.companyId)
                .map(o => o.teamNames)
                .flat()
                .includes(teamName) ||
              teamsInCompanies
                .filter(o => o.companyId === companyId || !o.companyId)
                .map(o => o.teamNames)
                .flat()
                .includes('')
            );
          };
        }
      };

      state.logout = () => {
        const tempState: AuthProviderState = emptyState;
        tempState.currentUser = undefined;
        this.setState(tempState);
        localStorage.clear();
        //TODO: Wywołac deleteLogin z backendu (zeby usunąć tokeny z bazy)
      };

      this.setState(state);
    };

    state.loginWithGoogle = async (
      pok: PokCoreContextType,
      googleToken: string,
    ) => {
      this.state.logout();
      const tokens = await pok.logins.loginWithGoogle(googleToken);
      if (tokens) {
        localStorage.setItem('pok-jwt-refresh-token', tokens.refreshToken);
        localStorage.setItem('pok-jwt-token', tokens.accessToken);
        const user = await pok.employees.getCurrent();

        const companies = await pok.companies.getManyWithoutLogo();
        const companyObject = Cookies.get('company-object');

        const company = companyObject ? JSON.parse(companyObject) : undefined;

        if (
          company &&
          company?.id &&
          companies?.map(o => o.id)?.includes(company.id)
        ) {
          sessionStorage.setItem('pok-company', JSON.stringify(company));
        } else {
          Cookies.remove('company-object');
          Cookies.set('company-object', JSON.stringify(companies[0]));
          companies &&
            companies.length &&
            sessionStorage.setItem('pok-company', JSON.stringify(companies[0]));
        }

        this.state.setCurrentUser(
          user,
          tokens.rolesInCompanies,
          tokens.teamsInCompanies,
        );
        state.currency = tokens.currency;
      }
    };

    state.login = async (
      pok: PokCoreContextType,
      login: string,
      password: string,
    ) => {
      this.state.logout();
      const tokens = await pok.logins.login(login, password);
      if (tokens) {
        localStorage.setItem('pok-jwt-refresh-token', tokens.refreshToken);
        localStorage.setItem('pok-jwt-token', tokens.accessToken);
        const user = await pok.employees.getCurrent();

        const companies = await pok.companies.getManyWithoutLogo();
        const companyObject = Cookies.get('company-object');

        const company = companyObject ? JSON.parse(companyObject) : undefined;

        if (
          company &&
          company?.id &&
          companies?.map(o => o.id)?.includes(company.id)
        ) {
          sessionStorage.setItem('pok-company', JSON.stringify(company));
        } else {
          Cookies.remove('company-object');
          Cookies.set('company-object', JSON.stringify(companies[0]));
          companies &&
            companies.length &&
            sessionStorage.setItem('pok-company', JSON.stringify(companies[0]));
        }

        this.state.setCurrentUser(
          user,
          tokens.rolesInCompanies,
          tokens.teamsInCompanies,
        );
        state.currency = tokens.currency;
      }
    };
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
