import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEmployeeWithRolesDto } from '../../../common/pokCore/autogenerated/pokApiClient';

import { DeleteButton } from './DeleteButton';

interface EmployeeSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  withMerging?: boolean;
  refresh?: boolean;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  selectedKeys?: string[];
  withHistory?: boolean;
}

export const employeeForGrid = (employee: GetEmployeeWithRolesDto) => {
  return {
    key: employee.id,
    values: {
      'employee.oneToolId': employee.oneToolId,
      'employee.name': employee.name,
      'employee.login': employee.login,
      'company.shortname': employee.company?.shortname,
      'employee.email': employee.email,
      'employee.teamName': employee.teamName,
      'employee.supervisor': employee.supervisor,
      'employee.manager': employee.manager,
      'employee.position': employee.position,
      'employee.technical': employee.technical ? 'TAK' : '',
      deleteButton: employee.roles && <DeleteButton employeeId={employee.id} />,
    },
    isInactive: !employee.active,
  };
};

export const EmployeeSearch: React.FC<EmployeeSearchProps> = props => {
  const columns: GridColumn[] = [
    { header: 'One Tool ID', property: 'employee.oneToolId' },
    { header: 'Imię i nazwisko', property: 'employee.name' },
    { header: 'Login', property: 'employee.login' },
    { header: 'E-mail', property: 'employee.email' },
    { header: 'Spółka', property: 'company.shortname' },
    { header: 'Zespół', property: 'employee.teamName' },
    { header: 'Stanowisko', property: 'employee.position' },
    { header: 'Przełożony', property: 'employee.supervisor' },
    { header: 'Szef', property: 'employee.manager' },
    { header: 'Techniczny', property: 'employee.technical' },
    { header: '', property: 'deleteButton', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="employee.name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak pracowników w systemie"
      refresh={props.refresh}
      onRowClick={props.onRowClick}
      enableMultiSelect={props.selectedKeys ? true : false}
      selectedKeys={props.selectedKeys}
      withHistory={props.withHistory}
    />
  );
};
