import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { usePokCore } from '../../../common/hooks/usePokCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface DeleteButtonProps {
  employeeId?: string;
}

export const DeleteButton: React.FC<DeleteButtonProps> = props => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const handleClick = async () => {
    if (props.employeeId) {
      await notifications.onPromise(
        pok.employeesRoles.delete(props.employeeId),
      );
    }
  };

  return (
    <ConfirmationButton
      className="px-2"
      confirmation="Czy na pewno usunąć wszystkie uprawnienia przypisane do tego użytkownika?"
      variant="btn-info"
      onOK={handleClick}
      tooltip="Usuń uprawnienia użytkownika"
    >
      <Icon.Trash className="icon-blue" size={16} />
    </ConfirmationButton>
  );
};
