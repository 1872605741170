/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as rstm from 'react-simple-tree-menu';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateDictionaryDto,
  GetDictionaryTypeDto,
} from '../../../common/pokCore/autogenerated/pokApiClient/models';
import {
  newDictionary,
  convert,
} from '../../../common/pokCore/contexts/DictionaryContext';
import * as DictionaryContext from '../../../common/pokCore/contexts/DictionaryContext';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { usePokCore } from '../../../common/hooks/usePokCore';
import FormRow from '../../../app/components/FormRow';
import LastEditorInfo from '../../../app/components/LastEditorInfo';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';
import Button from '../../../common/components/Button/Button';

interface DictionaryModalProps {
  variant: 'edit' | 'add';
  item: rstm.TreeMenuItem;
  onAction: (id?: string) => void;
  show?: boolean;
  onClose?: () => void;
}

export const DictionaryModal: React.FC<DictionaryModalProps> = ({
  variant,
  item,
  onAction,
  show,
  onClose,
}) => {
  const pok = usePokCore();
  const notifications = useNotifications();

  const [dictionary, setDictionary] = useState(newDictionary());
  const [systemNumber, setSystemNumber] = useState<number | null>(null);
  const [dictionaryType, setDictionaryType] = useState<GetDictionaryTypeDto>();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (variant !== 'add') {
      pok.dictionaries
        .getById(item.id)
        .then(dict => {
          setDictionary(convert(dict));
          setSystemNumber(dict.systemNumber);
          setDictionaryType(dict.dictionaryType);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    } else {
      setDictionary(newDictionary(item.id, item.dictionaryTypeId));
      setSystemNumber(null);
      pok.dictionaryTypes
        .getById(item.dictionaryTypeId)
        .then(dicT => {
          setDictionaryType(dicT);
        })
        .catch(errorResponse => {
          notifications.caughtError(errorResponse);
        });
    }
  }, [item.dictionaryTypeId, item.id, pok, variant, notifications]);

  const propertyChange = (obj: Partial<CreateUpdateDictionaryDto>) => {
    setDictionary({ ...dictionary, ...obj });
  };

  const handleSave = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    return DictionaryContext.validate(dictionary).then(async status => {
      setValidationErrors(status.errors);
      if (status.valid) {
        setShowValidationErrors(false);
        setIsLoading(true);
        try {
          if (variant === 'edit') {
            await notifications.onPromise(
              pok.dictionaries.update(item.id, dictionary),
              onAction,
            );
          } else {
            const newDictionary = await pok.dictionaries.create(dictionary);
            notifications.saveCompleted();
            onAction(newDictionary.id);
          }
        } catch (response) {
          setValidationErrors(await responseErrors(response as ResponseError));
          setShowValidationErrors(true);
        } finally {
          setIsLoading(false);
        }
      } else {
        setShowValidationErrors(true);
      }
    });
  };

  const title =
    variant === 'add' ? 'Dodawanie wartości do słownika ' : 'Edycja słownika ';
  const actionButtonLabel = variant === 'add' ? 'Dodaj' : 'Zapisz';

  return (
    <Modal
      onHide={onClose}
      centered={true}
      show={show}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title} <b>{dictionaryType?.name}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          {systemNumber && (
            <FormRow controlId="value" label="Numer systemowy">
              <Form.Control
                type="text"
                value={systemNumber}
                readOnly={true}
                disabled
              />
            </FormRow>
          )}
          <FormRow controlId="value" label="Wartość">
            <Form.Control
              type="text"
              value={dictionary?.value || ''}
              onChange={e =>
                propertyChange({
                  value: e.target.value === '' ? null : e.target.value,
                })
              }
            />
          </FormRow>
          <FormRow controlId="valueEn" label="Wartość w j. angielskim">
            <Form.Control
              type="text"
              value={dictionary?.valueEn || ''}
              onChange={e =>
                propertyChange({
                  valueEn: e.target.value === '' ? null : e.target.value,
                })
              }
            />
          </FormRow>
          <FormRow controlId="shortName" label="Symbol">
            <Form.Control
              type="text"
              value={dictionary?.shortname || ''}
              onChange={e =>
                propertyChange({
                  shortname: e.target.value === '' ? null : e.target.value,
                })
              }
              placeholder=""
            />
          </FormRow>
        </Form>
      </Modal.Body>
      <ValidationAlert
        show={showValidationErrors}
        errors={validationErrors}
        className="m-3"
      />
      <Modal.Footer>
        {item.id && variant !== 'add' && (
          <LastEditorInfo
            id={item.id}
            method={pok.dictionaries.getLastEditor}
          />
        )}
        <Button
          variant="outline-secondary"
          onClick={onClose}
          disabled={isLoading}
        >
          Zamknij
        </Button>
        <Button variant="primary" onClick={handleSave} isLoading={isLoading}>
          {actionButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
