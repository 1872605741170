/*eslint max-lines-per-function: ["error", 180]*/
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import {
  GetEstimateByMonthDto,
  GetEstimateItemDto,
  GetProjectDto,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { usePokCore } from '../../../common/hooks/usePokCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { newGetEstimateItem } from '../../../common/pokCore/contexts/EstimateItemContext';
import AlertModal from '../../../app/components/AlertModal';
import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { ResponseError } from '../../../common/pokCore/autogenerated/pokApiClient';

import { EstimateItemAccordion } from './EstimateItemAccordion';
import { PaeImport } from './PaeImport';

interface EstimateItemsTabProps {
  estimateByMonth?: GetEstimateByMonthDto;
  salesBlockedMonth: boolean;
  purchaseBlockedMonth: boolean;
  salesBlocked: boolean;
  purchaseBlocked: boolean;
  project?: GetProjectDto;
  paeTeam: boolean;
  onRefresh: () => void;
}

export const EstimateItemsTab: React.FC<EstimateItemsTabProps> = props => {
  const [estimateItems, setEstimateItems] = useState<GetEstimateItemDto[]>([]);
  const [activeItem, setActiveItem] = useState<string>();
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();

  useEffect(() => {
    setIsLoading(true);
    pok.estimateItems
      .findByEstimateMonth(props.estimateByMonth?.id || '')
      .then(items => {
        setEstimateItems(items);
      })
      .catch(async errorResponse => {
        notifications.caughtError(errorResponse);
      })
      .finally(() => setIsLoading(false));
  }, [notifications, pok.estimateItems, props.estimateByMonth?.id, refresh]);

  const handleOnClick = (idx: string) => {
    setActiveItem(idx === activeItem ? undefined : idx);
  };

  const deleteEstimateItem = async (itemId?: string) => {
    if (itemId) {
      try {
        await pok.estimateItems.delete(itemId);
      } catch (error) {
        return {
          saved: false,
          errors: await responseErrors(error as ResponseError),
        };
      }
    }
    setEstimateItems(estimateItems.filter(i => i.id !== itemId));
    onRefresh();
    return {
      saved: true,
      errors: [],
    };
  };

  const handleAdd = () => {
    const alreadyAdded = estimateItems.some(item => item.id === undefined);
    if (alreadyAdded) {
      setAlreadyAdded(true);
      return;
    }
    setActiveItem('0');
    setEstimateItems([newGetEstimateItem(), ...estimateItems]);
  };

  const onRefresh = () => {
    setRefresh(!refresh);
    props.onRefresh();
  };

  const closeAccordion = () => {
    setActiveItem('-1');
  };

  const cloneItem = (id: string, withAmounts: boolean) => () => {
    const alreadyAdded = estimateItems.some(item => item.id === undefined);
    if (alreadyAdded) {
      setAlreadyAdded(true);
      return;
    }
    const item = estimateItems.findLast(i => i.id === id);
    const amounts = withAmounts
      ? {}
      : {
          salesInvoicesSum: undefined,
          purchaseInvoicesSum: undefined,
          rcSalesTotal: undefined,
          salesSurchargePercent: undefined,
          salesDiscountPercent: undefined,
          salesNet: undefined,
          salesCommissionPercent: undefined,
          salesCommissionAmount: undefined,
          salesNetTechnicalCost: undefined,
          salesNetTotal: undefined,
          rcPurchase: undefined,
          purchaseTechnicalCost: undefined,
          purchaseDiscountPercent: undefined,
          purchaseNetTotal: undefined,
          purchaseNet: undefined,
          purchaseSurchargePercent: undefined,
          serviceExternalWorkPercent: undefined,
          serviceCreationPercent: undefined,
          serviceChangeProductionPercent: undefined,
          serviceStrategyPercent: undefined,
          serviceProductionBroadcastPercent: undefined,
          serviceClientServicePercent: undefined,
        };
    if (item) {
      const newItem = {
        ...item,
        ...amounts,
        id: undefined,
        clone: true,
      } as unknown as GetEstimateItemDto;
      setActiveItem('0');
      setEstimateItems([newItem, ...estimateItems]);
    }
  };

  return (
    <>
      {props.salesBlockedMonth && (
        <Alert variant="warning">
          {`Aktywna globalna blokada sprzedaży w miesiącu`}
        </Alert>
      )}
      {props.purchaseBlockedMonth && (
        <Alert variant="warning">
          {`Aktywna globalna blokada zakupu w miesiącu`}
        </Alert>
      )}
      {(!props.salesBlocked || !props.purchaseBlocked) && (
        <>
          <Button variant="outline-primary" onClick={handleAdd}>
            Dodaj pozycję
          </Button>
          {props.paeTeam &&
            !props.purchaseBlocked &&
            process.env.REACT_APP_PAE_ENABLED?.toLocaleLowerCase() === 'on' && (
              <PaeImport
                estimateByMonth={props.estimateByMonth}
                project={props.project}
                refresh={onRefresh}
                purchaseOnly={props.salesBlocked}
              />
            )}
        </>
      )}
      <Accordion
        className="mb-3 mt-5 item-accordion"
        activeKey={activeItem}
        defaultActiveKey={activeItem}
      >
        {isLoading && <Skeleton count={3} />}
        {estimateItems.map((item, idx) => (
          <div key={item.id ? item.id : idx} className="d-flex mb-1">
            <EstimateItemAccordion
              estimateItem={item}
              estimateByMonth={props.estimateByMonth}
              project={props.project}
              idx={item.id ? item.id : idx.toString()}
              activeItemIdx={activeItem}
              onDelete={deleteEstimateItem}
              refresh={onRefresh}
              handleOnClick={() =>
                handleOnClick(item.id ? item.id : idx.toString())
              }
              cloneItem={cloneItem}
              closeAccordion={closeAccordion}
              salesBlocked={props.salesBlocked}
              purchaseBlocked={props.purchaseBlocked}
            />
          </div>
        ))}
      </Accordion>
      <AlertModal
        variant="danger"
        show={alreadyAdded}
        buttonVariant="online-secondary"
        onHide={() => {
          setAlreadyAdded(false);
        }}
      >
        Proszę zapisać dodaną wcześniej pozycję.
      </AlertModal>
    </>
  );
};
