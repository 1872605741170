/*eslint max-lines-per-function: ["error", 202]*/
import React, { useMemo, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';

import { responseErrors } from '../../../common/pokCore/validation/responseErrors';
import { usePokCore } from '../../../common/hooks/usePokCore';
import {
  newEstimateByMonth,
  validate,
} from '../../../common/pokCore/contexts/EstimateByMonthContext';
import {
  CreateUpdateEstimateByMonthDto,
  GetEstimateByMonthDto,
  GetProjectDto,
  ResponseError,
} from '../../../common/pokCore/autogenerated/pokApiClient';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { ProjectEstimatesStatusEnum } from '../../../common/pokCore/validation/schemas';
import { useTranslation } from '../../../common/hooks/useTranslation';
import FormSkeleton from '../../../app/components/FormSkeleton';

import { EstimateProfileAccordion } from './EstimateProfileAccordion';
import { AddProfileForm } from './AddProfileForm';
import { CloneEstimateItemsForm } from './CloneEstimateItemsForm';

interface EstimatesTabProps {
  project: GetProjectDto;
  paeTeam: boolean;
  refresh(): void;
}

export const EstimatesTab: React.FC<EstimatesTabProps> = props => {
  const [show, setShow] = useState(false);
  const [showClone, setShowClone] = useState(false);
  const [profileList, setProfileList] = useState<GetEstimateByMonthDto[]>([]);
  const [activeItem, setActiveItem] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const pok = usePokCore();
  const notifications = useNotifications();
  const { t, tk } = useTranslation('menu');

  useMemo(() => {
    if (!show && !showClone) {
      setIsLoading(true);
      pok.estimatesByMonth
        .findByProjectId(props.project.id)
        .then(estimateByMonth => {
          setProfileList(estimateByMonth);
        })
        .catch(async errorResponse => {
          notifications.caughtError(errorResponse);
        })
        .finally(() => setIsLoading(false));
    }
  }, [notifications, pok.estimatesByMonth, props.project.id, show, showClone]);

  const addEstimateByMonth = async (
    estimateByMonthCU: CreateUpdateEstimateByMonthDto,
  ) => {
    const status = await validate(estimateByMonthCU);
    if (status.valid) {
      try {
        estimateByMonthCU.status = ProjectEstimatesStatusEnum.New;
        const obj = await pok.estimatesByMonth.create(estimateByMonthCU);
        setShow(false);
        props.refresh();
        setActiveItem(obj.id.toString());
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as ResponseError),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const deleteEstimateByMonth = async (id?: string) => {
    if (id) {
      try {
        await pok.estimatesByMonth.delete(id);
      } catch (error) {
        return {
          saved: false,
          errors: await responseErrors(error as ResponseError),
        };
      }
      setProfileList(profileList.filter(i => i.id !== id));
    }
    return {
      saved: true,
      errors: [],
    };
  };

  const cloneEstimateByMonth = async (
    id: string,
    date: Date,
    withAmounts: boolean,
  ) => {
    try {
      const obj = await pok.estimatesByMonth.clone(id, date, withAmounts);
      setProfileList([...profileList, obj]);
      setActiveItem(obj.id.toString());
      props.refresh();
    } catch (error) {
      return {
        saved: false,
        errors: await responseErrors(error as ResponseError),
      };
    }
    notifications.saveCompleted();
    return {
      saved: true,
      errors: [],
    };
  };

  const cloneEstimateItems = async (
    items: string[],
    targetMonth: Date | undefined,
    witHAmounts: boolean,
  ) => {
    if (!targetMonth) {
      return {
        saved: false,
        errors: [
          'Proszę wskazać miesiąc docelowy (↑do wskazania na górze okienka↑).',
        ],
      };
    }
    try {
      await pok.estimateItems.clone(
        items,
        targetMonth,
        witHAmounts,
        props.project.id,
      );
      setShowClone(false);
      props.refresh();
      setActiveItem(undefined);
      return {
        saved: true,
        errors: [],
      };
    } catch (error) {
      return {
        saved: false,
        errors: await responseErrors(error as ResponseError),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleAdd = async () => {
    setShow(true);
  };

  const handleClone = async () => {
    setShowClone(true);
  };

  const handleCloseClone = () => {
    setShowClone(false);
  };

  const handleOnClick = (idx: string) => {
    setActiveItem(idx === activeItem ? undefined : idx);
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleAdd}>
        {`Dodaj miesiąc ${t(tk.menu.estimate2)}`}
      </Button>
      <Button variant="outline-primary" className="mx-2" onClick={handleClone}>
        {`Importuj pozycje z ${t(tk.menu.genitiveProject)}`}
      </Button>
      <Accordion
        className="mb-5 mt-5"
        activeKey={activeItem}
        defaultActiveKey={activeItem}
      >
        {isLoading && <FormSkeleton />}
        {profileList.map(prof => (
          <div key={prof.id} className="d-flex mb-1">
            <EstimateProfileAccordion
              estimateByMonth={prof}
              activeItemIdx={activeItem}
              project={props.project}
              paeTeam={props.paeTeam}
              handleOnClick={() => handleOnClick(prof.id.toString())}
              onDelete={deleteEstimateByMonth}
              clone={cloneEstimateByMonth}
              refresh={showClone}
            />
          </div>
        ))}
      </Accordion>
      {show && (
        <AddProfileForm
          teamIds={props.project.projectTeams?.map(pt => pt.teamId)}
          budgetId={props.project.purchaser.budget.id}
          onCompleted={addEstimateByMonth}
          show={show}
          estimateByMonth={newEstimateByMonth(props.project.id)}
          handleClose={handleClose}
        />
      )}
      {showClone && (
        <CloneEstimateItemsForm
          teamIds={props.project.projectTeams?.map(pt => pt.teamId)}
          budgetId={props.project.purchaser.budget.id}
          onCompleted={cloneEstimateItems}
          show={showClone}
          handleClose={handleCloseClone}
        />
      )}
    </>
  );
};
